//交友列表 
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router"; 
import {discover} from "../../../../request/api.js";
import FindBanner from "../../../../components/find/banner.js";
import FindCountry from "../../../../components/find/country.js";
import FindNewList from "../../../../components/find/list.js";
import mlimg from "../../../../img/ml.png";
import icon_sex from "../../../../img/online/icon_sex.png";
import Stealth from "../../../../img/online/Stealth@2x.png";
import icon_boy from "../../../../img/home/icon_boy.png";
import icon_girl from "../../../../img/home/icon_girl.png";
import defaultImg from "../../../../img/index/default.png";
let dataArr = [];
let listShow = true;
const FindList: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	let page = 1;
	const [rankList,setrankList] = useState([]); 
	const [countryList,setcountryList] = useState([]); 
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 46-46;
		setwHeight(Height);
		discoverAjax();
	},[]);
	const discoverAjax=()=>{
		discover({}).then((res)=>{
			if(res.errno == 0) {
				console.log('发现', res.data); 
				setrankList(res.data.rankList);
				setcountryList(res.data.countryList);
			}
		})
	}
	
	return (
		<div className="content-list friendPage" style={{ height: wHeight + "px" }}>
			<div className="find_banner">
				<FindBanner list={rankList}></FindBanner>
				<FindCountry list={countryList}></FindCountry>
				<FindNewList></FindNewList>
			</div>
			
		</div>
	);
};

export default FindList; 