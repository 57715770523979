//交友
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";

import FriendsList from "./list/friendlist.js";
import DynamicList from "./list/dynamiclist.js";
import indexcs from "../../../img/cs.png";
import icon02 from "../../../img/online/icon_02.png"
import Swiper from 'swiper'; 
import "swiper/css";
import "../../../css/swiper.css";
var swiperFriend;
const FriendsPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [activedIndex,setactivedIndex] = useState(0);
	const [currentId,setcurrentId] = useState(0);
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 46 - 46;
		setwHeight(Height);
		  swiperFriend = new Swiper(".swiper-friend", { 
			  slidesPerView: 'auto', //同时显示的个数
			  //width: 80,
			  spaceBetween: 15, //两个slide之间的距离
			  freeMode: true,
			  autoHeight: true,
			  observer: true,
			  observeParents: true,
		      onTransitionEnd: function(swiper) { 
		  		
		  		setcurrentId(swiper.activeIndex + 1)
		      } 
		  });
	},[]);
	const tapTabItem=(t)=>{
		swiperFriend.slideTo(t);
		setcurrentId(t)
	}
	const searchOpen=()=>{
		
	}
	const tapRelease=()=>{
		
	}
	return (
		<div className="cont-discovery">
			<div className="content-title">
				<div className="dis-div" onClick={tapTabItem.bind(this,0)}>
					<div className={"title-font " + (currentId == 0 ? "online_current": "") }>{t("makeFriends")}</div>
					{
						currentId == 0 ? <div className="title-border"><b className="main-inline"></b></div> : ""
					}
					
				</div>
				<div className="dis-div"  onClick={tapTabItem.bind(this,1)}>
					<div className={"title-font " + (currentId == 1 ? "online_current": "") }>{t("dynam")}</div>
					{
						currentId == 1 ? <div className="title-border"><b className="main-inline"></b></div> : ""
					}
					
				</div>
				{
					currentId == 0 ? <div className="title-right" onClick={searchOpen}><img src={indexcs} className="title-search"/></div> : <div className="title-right" onClick={tapRelease}><img src={icon02} className="title-search"/></div>
				}
				
				
			</div>
			<div className="main-box Xmas-swiper-content" style={{ height: wHeight + "px" }}>
				
				<div className="swiper-container swiper-friend swiper-width"  style={{ height: wHeight + "px" }}>
					<div className="swiper-wrapper"  style={{ height: wHeight + "px" }}>
						<div className='swiper-slide'>
							<FriendsList></FriendsList>
						</div>
						<div className='swiper-slide'>
							<DynamicList></DynamicList>
						</div>
					</div>
				</div> 
			</div>
			
		</div>
		
	);
	
};

export default FriendsPage; 