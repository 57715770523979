// router index.js 
import React, { Component } from 'react';
// 导入所需组件
//登陆注册
import Login from "../pages/Login";
import Pass from "../pages/Pass";
import Register from "../pages/Register";
import Service from "../pages/pact/service";
import Privacy from "../pages/pact/privacy";
import Setpass from "../pages/Setpass";
import Editinfo from "../pages/Editinfo";
//内部页面
import IndexPage from "../pages/page/index";
import VideoPage from "../pages/page/video/index";
import LivePage from "../pages/page/live/index";
import FriendsPage from "../pages/page/friends/index";
import ChatPage from "../pages/page/chat/index";
import CentrePage from "../pages/page/centre/index";
import HomePage from "../pages/page/home/index";
import GiftPage from "../pages/page/home/subpage/gift";
import BillPage from "../pages/page/home/subpage/bill";
import FollowOther from "../pages/page/home/subpage/follow";
//个人中心
import FansPage from "../pages/page/centre/fans";
import FollowPage from "../pages/page/centre/follow";
import VisitPage from "../pages/page/centre/visit";
import SeenPage from "../pages/page/centre/seen";
import MyLivePage from "../pages/page/centre/mylive";
import GuardPage from "../pages/page/centre/guard";
import BlackList from "../pages/page/centre/blacklist";
//个人信息修改
import EditPage from "../pages/page/home/edit/index";
import NickEdit from "../pages/page/home/edit/nick";
import FeelEdit from "../pages/page/home/edit/feel";
import WorkEdit from "../pages/page/home/edit/work";
import SignEdit from "../pages/page/home/edit/sign";
import LanguageEdit from "../pages/page/home/edit/language";

//充值
import AccountPage from "../pages/page/recharge/account";
import VipPage from "../pages/page/recharge/vip";
//设置
import SettingPage from "../pages/page/setting/index";
import AboutPage from "../pages/page/setting/about";
import LanguagePage from "../pages/page/setting/language";

import MlPage from "../pages/page/ml/index";

import LiveRoom from "../pages/page/liveroom/live";
import RankPage from "../pages/page/rank/list";
import AreaPage from "../pages/page/live/find/area";
import DetailPage from "../pages/page/live/find/detail";

import GuardRule from "../pages/page/rule/guardRule";
// 导入路由依赖
import {   Route, Routes   } from 'react-router-dom';
 

export default class index extends Component {
  render() {
    return ( 
      <Routes> 
	  <Route path='/' element={<Login/>} />
        <Route path='/:id' element={<Login/>} />
		<Route path='/pass' element={<Pass/>} />
		<Route path='/register' element={<Register/>} /> 
		<Route path='/pact/service' element={<Service/>} /> 
		<Route path='/pact/privacy' element={<Privacy/>} /> 
		<Route path='/setpass' element={<Setpass/>} /> 
		<Route path='/Editinfo' element={<Editinfo/>} /> 
		
		<Route path='/page/index' element={<IndexPage/>} /> 
		<Route path='/page/video/index' element={<VideoPage/>} /> 
		<Route path='/page/live/index' element={<LivePage/>} /> 
		<Route path='/page/friends/index' element={<FriendsPage/>} /> 
		<Route path='/page/chat/index' element={<ChatPage/>} /> 
		<Route path='/page/centre/index' element={<CentrePage/>} /> 
		<Route path='/page/home/index' element={<HomePage/>} /> 
		<Route path='/page/home/subpage/gift' element={<GiftPage/>} /> 
		<Route path='/page/home/subpage/bill' element={<BillPage/>} /> 
		<Route path='/page/home/subpage/follow' element={<FollowOther/>} /> 
		
		<Route path='/page/centre/fans' element={<FansPage/>} /> 
		<Route path='/page/centre/follow' element={<FollowPage/>} /> 
		<Route path='/page/centre/visit' element={<VisitPage/>} /> 
		<Route path='/page/centre/seen' element={<SeenPage/>} /> 
		<Route path='/page/centre/mylive' element={<MyLivePage/>} /> 
		<Route path='/page/centre/guard' element={<GuardPage/>} />  
		<Route path='/page/centre/blacklist' element={<BlackList/>} /> 
		
		<Route path='/page/home/edit/index' element={<EditPage/>} /> 
		<Route path='/page/home/edit/nick' element={<NickEdit/>} /> 
		<Route path='/page/home/edit/feel' element={<FeelEdit/>} /> 
		<Route path='/page/home/edit/work' element={<WorkEdit/>} /> 
		<Route path='/page/home/edit/sign' element={<SignEdit/>} /> 
		<Route path='/page/home/edit/language' element={<LanguageEdit/>} /> 
		
		<Route path='/page/recharge/account' element={<AccountPage/>} /> 
		<Route path='/page/recharge/vip' element={<VipPage/>} /> 
		
		
		<Route path='/page/setting/index' element={<SettingPage/>} /> 
		<Route path='/page/setting/about' element={<AboutPage/>} /> 
		<Route path='/page/setting/language' element={<LanguagePage/>} /> 
		
		<Route path='/page/ml/index' element={<MlPage/>} /> 
		
		<Route path='/page/liveroom/live' element={<LiveRoom/>} /> 
		<Route path='/page/rank/list' element={<RankPage/>} />
		<Route path='/page/live/find/area' element={<AreaPage/>} /> 
		<Route path='/page/live/find/detail' element={<DetailPage/>} />
		
		<Route path='/page/rule/guardRule' element={<GuardRule/>} /> 
      </Routes>
    )
  }
}