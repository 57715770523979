// 赠送礼物
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {balance,giftList,giftsend } from "../../../../request/api.js";
import Toast from "../../../../components/toast/toast.js"; 
import giftlist from "../../../../json/giftlist.json";
import icon_back from "../../../../img/icon_back.png";
import g_bg from "../../../../img/chat/g_bg.png";
import defaultImg from "../../../../img/public/default.png";
import icon_02 from "../../../../img/chat/icon_02.png";
import g_01 from "../../../../img/chat/g_01.png"
import icon_arrow from '../../../../img/chat/icon_arrow.png';
import icon_jian from '../../../../img/chat/icon_jian.png';
import icon_jia from "../../../../img/chat/icon_jia.png";
import Swiper from 'swiper'; 
import "swiper/css";
import "../../../../css/swiper.css";
let swipergift;
const GiftPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const location = useLocation(); 
	const userId = location.state.uid;
	const userName = location.state.name; 
	const userimg = location.state.imgurl;  
	const [money,setmoney] = useState(0);
	const [giveNum,setgiveNum] = useState(1);
	const [gLists,setgLists] = useState([]);
	const [currentId,setcurrentId] = useState(0);
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => { 
		let Height = document.documentElement.clientHeight - 300;
		setwHeight(Height);
		balanceAjax(); 
		giftListAjax();
		setTimeout(function(){
			swipergift = new Swiper(".swiper-gift", {
						  pagination: {
							   el: '.swiper-pagination',
							   clickable: true,
							  },
						  slidesPerView: 1, //同时显示的个数
						  spaceBetween: 10, //两个slide之间的距离
						  loop: false, //是否循环
			    onTransitionEnd: function(swiper) {  
					//setcurrentId(swiper.activeIndex + 1)
			    } 
			});
		},1000)
	},[]);
	
	const giftListAjax=()=>{
		let garr = {
			position: "home",
		}
		giftList(garr).then((res)=>{
			if(res.errno == 0) {
				var lists = [];
				var gifts = res.data.items;
				let gList = giftlist.data;
				for(var i = 0; i < gifts.length; i++){
					for(var j = 0; j < gList.length; j++){
							if(gifts[i].id == gList[j].id){
								lists.push(gList[j]);
							}
						}
				}
				let garr = [];
				for(var a = 0; a < lists.length; a += 6){
					garr.push(lists.slice(a,a+6));
					setgLists(garr);
				}
			}
		})
	}
	const balanceAjax=()=>{
		balance({}).then((res)=>{
			if(res.errno == 0) {
				setmoney(res.data); 
			}else{
				Toast.fail(res.errmsg);
				 
			}
		})
	}
	const tapChooseGift=(id)=>{
		setcurrentId(id)
	}
	const rechargeOpen=()=>{
		
	}
	const tapSendGift=()=>{
		if(currentId == 0){
			Toast.fail(t("selectGift")); 
		}else{
			var darr = {
				position: "home",
				giftid: currentId,
				giftnum: giveNum,
				tid: userId,
			}
			giftsend(darr).then((res)=>{
				if(res.errno == 0) {
					Toast.fail(t("GiftSuccess"));  
					setmoney(res.data.giftRet.balance);
				}else if(res.errno == 801){
					Toast.fail(res.errmsg+","+t("Pleaseloginagain")); 
					setTimeout(function(){
						 navigate("/");
					},1000)
				}else{
					Toast.fail(res.errmsg); 
				}
			})
		}
	}
	const tapGiftMinus=()=>{
		if(giveNum == 1){
			Toast.fail(t("Atleastone"));
		}else{
			let n = giveNum-1;
			setgiveNum(n); 
		}
	}
	const tapGiftAdd=()=>{
		if(currentId == 0){
			Toast.fail(t("selectGift")); 
		}else{
			let n = giveNum+1;
			setgiveNum(n);  
		}
	}
	return (
		<div className="main-box">
			<header className="header-gift" >
				<div className="main-box main-flex main-aic head head-transparent">
					<div className="main-box head_lf">
						<div onClick={() => navigate(-1)}>
							<img src={icon_back} className="head_back" />
						</div>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("setGift")}</div>
					<div className="main-box head_rt" ></div>
				</div>
			</header>
			<div className="main-box">
				<div className="main-relative give_bg">
					<img className="main-w10 main-vat" src={g_bg} />
					<div className="main-w10 main-hg main-flex main-aic main-jcc give_head">
						<div className="give_pt">
							<div className="main-radius main-flex main-aic main-jcc give_img">
								<img className="main-radius" src={userimg!="" ? userimg : defaultImg }  onError={(e: any) => { e.target.src = defaultImg }}  />
							</div>
							<p className="give_name">{ t("send") } {userName} { t("gifts") }</p>
						</div>
					</div>
				</div>
				<div className="swiper-container swiper-gift" style={{ height: wHeight + "px" }}>
					<div className="swiper-wrapper">
					{
						gLists.map((item,index)=>{
							return (<div className="swiper-slide" key={index}>
							<div className="main-over index_pl index_pr">
							{
								item.map((items,indexs)=>{
									return (<div className="main-lf main-tac give_li" key={indexs}>
									<div className="main-relative main-w9 main-inline index_pb" onClick={tapChooseGift.bind(this,items.id)}>
										<img className="give_gift" src={items.icon} />
										<div className="main-flex main-aic main-jcc">
											<img className="give_icon" src={icon_02}/>
											<span className="give_price">{ items.price }</span>
										</div>
										{
											currentId == items.id ? <img className="give_active" src={g_01} /> : ""
										}
										
									</div>
								</div>)
								})
							}
								
							</div>
						</div>)
						})
					}
						
					</div> 
					<div className="swiper-pagination main-flex main-aic main-jcc"></div>
				</div>
				<div className="give_bottom give_btns  index_bt">
					<div className="main-w10 main-bg index_pl index_pr "> 
						<div className="main-flex main-aic" onClick={rechargeOpen}>
							<img className="main-va chat_tab_icon" src={icon_02}  />
							<span className="main-va chat_tab_price">{ money }</span>
							<a className="chat_tab_href" href="">
								<span className="main-va main-inline chat_tab_t2">{ t("rechorge") }</span>
								<img className="main-va chat_tab_arrow" src={icon_arrow} />
							</a>
						</div>
						 
						<div className="main-flex main-aic index_pt chat_give">
							<div className="main-f1 main-flex main-aic">
								<p className="chat_give_t1">{t("giveNum")}</p>
								<div className="main-flex main-aic chat_give_num">
									<img className="main-vat chat_give_icon" onClick={tapGiftMinus} src={icon_jian} />
									<input value={giveNum} className="main-hg main-tac chat_give_input" type="text" onChange={(e) => { setgiveNum(e.target.value); }} />
									<img className="main-vat chat_give_icon" onClick={tapGiftAdd}  src={icon_jia} />
								</div>
							</div>
							<p className="main-tac chat_give_send" onClick={tapSendGift}>{t("give")}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GiftPage; 