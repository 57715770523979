import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {balance} from "../../../request/api.js";
import VipCon from "../../../components/recharge/vip.js";
import SvipCon from "../../../components/recharge/svip.js";
import icon_back from "../../../img/icon_back.png";
import v_tab1 from "../../../img/member/v_tab1.png";
import v_tab2 from "../../../img/member/v_tab2.png";
import icon_btn from "../../../img/member/icon_btn.png"

import Swiper from 'swiper'; 
import "swiper/css";
import "../../../css/swiper.css";
import "../../../css/dp.css";
var swiperVip;
const VipPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const location = useLocation();  
	const [currentId,setcurrentId] = useState(0);
	const [wHeight,setwHeight] = useState(0);
	const [uinfo,setuinfo] = useState({});
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 170;
		setwHeight(Height);
		  swiperVip = new Swiper(".swiper-vip", { 
			  slidesPerView: 'auto', //同时显示的个数
			  //width: 80,
			  spaceBetween: 15, //两个slide之间的距离
			  freeMode: true,
			  autoHeight: true,
			  observer: true,
			  observeParents: true,
		      onTransitionEnd: function(swiper) { 
		  		
		  		setcurrentId(swiper.activeIndex + 1)
		      } 
		  });
	},[]);

	const tapTab=(t)=>{
		swiperVip.slideTo(t);
		setcurrentId(t)
	}
	const tapOpenMember=()=>{
		
	}
	return (
		<div>
			<header id="header"> 
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={() => navigate(-1)}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("viptitle")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div>
				<div className="main-relative vip-margin-top">
					<img className="main-w10 main-vat" src={currentId == 0 ? v_tab1 : v_tab2} alt="TabOne" />
					<div className="main-w10 main-hg main-flex main-aic vip_tabs">
						<div className={currentId ==1 ? "main-f1 main-tac vip_tabs_rt" : "main-f1 main-tac "} onClick={tapTab.bind(this,0)}>
							<p className={currentId == 0 ? "vip_tabs_type vip_tabs_active1" : "vip_tabs_type "}>VIP</p>
						</div>
						<div className={currentId ==0 ? "main-f1 main-tac vip_tabs_rt" : "main-f1 main-tac "} onClick={tapTab.bind(this,1)}>
							<p className={currentId == 1 ? "vip_tabs_type vip_tabs_active1" : "vip_tabs_type "}>SVIP</p>
						</div>
					</div>
				</div>
				<div className="main-box Xmas-swiper-content" style={{ height: wHeight + "px" }}>
					
					<div className="swiper-container swiper-vip swiper-width  content-list"  style={{ height: wHeight + "px" }}>
						<div className="swiper-wrapper">
							<div className='swiper-slide'>
								<VipCon></VipCon>
							</div>
							<div className='swiper-slide'>
								<SvipCon></SvipCon>
							</div>
						</div>
					</div> 
				</div>
				
			</div>
			<div className="main-relative main-tac vip_btns_up" onClick={tapOpenMember}>
				<img className="main-vat" height="58" src={icon_btn} alt="Btn" />
				<p className="main-w10 main-hg main-flex main-aic main-jcc vip_btns_go">{t("openMember")}</p>
			</div>
		</div>
	);
};

export default VipPage; 