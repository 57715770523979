// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {discovercountrylist} from "../../../../request/api.js";
import { Scroll } from "../../../../components/scroll/scroll.tsx";
import icon_back from "../../../../img/icon_back.png";
import live_gif from "../../../../img/home/live.gif";
import defaultImg from "../../../../img/public/default.png";
import livemy from "../../../../img/index/livemy.png";
let dataArr = [];
let listShow = true;
const DetailPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	let page = 1;
	const ctcode = location.state.code;
	const [wHeight,setwHeight] = useState(0);
	const [list,setlist] = useState([]);
	useEffect(() => {
		let clientW = document.documentElement.clientWidth;
		let h = (clientW - 30) / 3
		setwHeight(h); 
		dataArr = []; 
		countryAjax()
		
	},[]);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  countryAjax();
		  }
	    
	  }
	};
	const countryAjax=()=>{
		var arr = {
			ctcode: ctcode,	//页面传递的参数
			pageno: page,
			pagenum: 30,
		}
		discovercountrylist(arr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					} 
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				} 
							
			}
		})
	}
	
	const navBack=()=>{
		navigate(-1)
	}
	const tapchatlive=(id)=>{
		
	}
	return (
		<div>
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("countrytxt")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="index_pl index_pr">
				{
					list.length>0 ? <ul className="main-over main-font">
					<Scroll
					  height="100%"
					  load={handleScroll}
					  isFirstLoad={false}
					>
					{
						list.map((item,index)=>{
							return (<li className="main-inline main-w5 index_pt live_li" key={index}>
						<a className="main-block main-over main-relative live_content" onClick={tapchatlive.bind(this,item.uid)} style={{ height: wHeight + 'px' }}>
							<img className="main-w10 main-vat" src={item.uinfo.avatar} onError={(e: any) => { e.target.src = defaultImg }} />
							 
							<div className="main-flex main-aic main-jcc live_direct">
								<img src={live_gif} />
								<p>Live</p>
							</div> 
							<div className="main-w10 index_pl index_pr live_info">
								<p className="main-ellipsis live_name">{ item.uinfo.nick }</p>
								<div className="main-flex main-aic">
									<p className="main-f1 live_address">{ item.country }，{ item.place }</p>
									<span className="main-b live_num">{ item.personnum }</span>
								</div>
							</div>
						</a>
					</li>)
						})
					}
					</Scroll>
				</ul> : <div className="main-box-img">
					<img src={livemy} />
					<p>{ t("noData") }</p>
				</div>
				}
				
			</div>
		</div>
	);
};

export default DetailPage; 