// 注册
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router";
import {decode as base64_decode, encode as base64_encode} from 'base-64'; 
import CodeInput from "../components/codeInput/CodeInput.js"; // 导入验证码输入框
import { getcaptcha,sendsms,checkregsms } from "../request/api.js";
import Toast from "../components/toast/toast.js";

import icon_back from "../img/icon_back.png";
import closeimg from "../img/close.png";
import Safetysuaxin from "../img/Safetysuaxin.png";
import loadNo from "../img/load-no.png";

const Register: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	const [mobfont1, setmobfont1] = useState(t("dpMobfont1"));
	const [mobfont2, setmobfont2] = useState(t("dpMobfont2"));
	const [countryCode, setcountryCode] = useState('86');
	const [countryCodeEn, setcountryCodeEn] = useState('');
	const [mobile, setmobile] = useState('');
	const [password, setpassword] = useState('');
	const [inputMob, setinputMob] = useState(100);
	const [sendNumber, setsendNumber] = useState(60);
	const [isClick,setisClick] = useState(false); 
	
	const [vertifyCode, setVertifyCode] = useState(""); // 保存当前输入的验证码
  
	
	const [safetyShow, setsafetyShow] = useState(false);
	const [SafetyTXT, setSafetyTXT] = useState('');
	const [SafetyImg, setSafetyImg] = useState(loadNo);
	useEffect(() => {
		var loginCon = JSON.parse(window.localStorage.getItem('loginCon'));	
		//setmobile(loginCon.mobile);
		//setcountryCode(loginCon.code);
		sendTime();
	},[]);
	
	// 验证码改变事件
	const handleChange = (vertifyCode) => {
		console.log("当前输入了：", vertifyCode);
		setVertifyCode(vertifyCode); // 更新验证码
	};
	// 验证码输入完成后进行校验
	const handleComplete = (vertifyCode) => {
		// 验证码输入后验证逻辑
		console.log("验证码输入完毕", vertifyCode);
		let datarr = {
			regionid: countryCode,
			phone: mobile,
			smscode: vertifyCode
		}
		checkregsms(datarr).then((res)=>{
			if(res.errno==0){
				var loginCon = JSON.parse(window.localStorage.getItem('loginCon'));	
				loginCon.codes = vertifyCode; 
				window.localStorage.setItem('loginCon', JSON.stringify(loginCon));
				navigate("/setpass");
				//location.replace('setpassword.html');
			}else{
				Toast.fail(res.errmsg);
			}
		})
	};
	const jumpLoginTo = () => {
		navigate(-1);
	}
 
	const tapSafety=()=>{
		setsafetyShow(true);
		tapSafetyAjax();
	};
	const SafetyClose=()=>{
		setsafetyShow(false);
	};
	const tapSafetyAjax=()=>{
		getcaptcha({}).then((res)=>{
			let domain = "https://"+res.data.domain+""+res.data.url;
			setSafetyImg(domain);
		})
	}

	const sendCode=()=>{
		if(SafetyTXT!==""){
			let datarr = {
			    regionid: countryCode,
			    phone: mobile,
				captcha: 1,
				cpt_code: SafetyTXT,
			    type: 'reg'
			}
			sendsms(datarr).then((res)=>{
				if(res.errno==0){
					
					setsafetyShow(false); 
					Toast.fail(t("dpSendMobile"))
					sendTime();
					
				}else if(res.errno==2000010){
					Toast.fail(res.errmsg)
				    setSafetyTXT(""); 
					tapSafetyAjax();
					
				}else{
					SafetyClose();
					Toast.fail(res.errmsg);
				}
			})
		}
		
	}
	const sendTime =()=>{ 
		setisClick(true);
		let times = 60;
		let auth_timer = setInterval(()=>{
			times = times-1;
			console.log("times:",times)
			setsendNumber(times);
			console.log("sendNumber:",sendNumber)
			if(times<=0){
				console.log("sendNumber1:",sendNumber)
				setisClick(false);
				clearInterval(auth_timer);
				setsendNumber(60);
				times = 60
			}
		},1000);
	}
	return (
		<div className="main-box body-bg">
			<header id="header" >
				<div className="main-box main-flex main-aic head head-border" >
					<div className="main-box head_lf" onClick={jumpLoginTo}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("n_register_phone")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="login-content">
				<div className="main-mob edit_li">
					<div className="login-title-text">{ t("n_ver_code") }</div>
					<div className="login-text">{ t("index_pass_send") }<span>+{ countryCode } { mobile }</span></div>
				</div>
				<div className="main-flex main-aic edit_li">	
					<CodeInput vertifyCode={vertifyCode} onChange={handleChange} onComplete={handleComplete}></CodeInput>
				</div>
				<div className="login-b">
					<div className="login-ver">
					{
						sendNumber == 60 ? <div className="login-send register-bg" onClick={tapSafety}>
							{t("dpLoginSend")}
						</div> : <div className="login-send">
							{t("dpLoginSend")} ({sendNumber}s)
						</div>
					}
						
						
					</div>
					
				</div>
			</div>
			<div className="footer-bei">Corpration © 2016-2023 Anycupid.All Rights Reserved</div>
			{
				 safetyShow ? <div><div  onClick={SafetyClose} className="dialogBg"></div>
					<div className="animated">
						<div className="dialog-bg">
							<img onClick={SafetyClose} src={closeimg} className="dialog-back" />
							<div className="dialog-title">
								{ t("Safety_title") }
							</div>
							
							<div className="dialog-txt">{t("Safety_txt")}</div>
							<div className="dialog-input">
								<div className="Safety-enter">
									<input className="Safety-input" type="text" value={SafetyTXT} onChange={(e) => { setSafetyTXT(e.target.value); }}  autofocus="autofocus" />
									<div className="Safety-img"><img src={SafetyImg} className="Safety-Code-map"  onClick={tapSafetyAjax} /></div>
									<img src={Safetysuaxin} className="Safety-break" onClick={tapSafetyAjax} />
								</div>
							</div>
							<div onClick={sendCode} className="dialog-botton">{ t("Safety_confirm") }</div>
						</div>
					</div>
				 </div> : ""
			}
		</div>
	);
};

export default Register;  
 