// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import {countrygroups} from "../../../../request/api.js";
import icon_back from "../../../../img/icon_back.png";
import defaultImg from "../../../../img/public/default.png"
const AreaPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [wHeight,setwHeight] = useState(0);
	const [areaList,setareaList] = useState([]);
	useEffect(() => {
		let clientW = document.documentElement.clientWidth;
		let h = (clientW - 20 - (((clientW - 20) * 0.014) * 2)) / 3
		setwHeight(h); 
		countrygroupsAjax()
	},[]);
	const countrygroupsAjax=()=>{
		var arr = {
			num: 5,
		}
		countrygroups(arr).then((res)=>{
			if(res.errno == 0) {
				setareaList(res.data.items);
			} 
		})
	}
	
	const navBack=()=>{
		navigate(-1)
	}
	const tapchatlive=(id)=>{
		
	}
	const detailOpen=(c)=>{
		//navigate("/page/live/find/detail",{state:{code:c}});
	}
	return (
		<div>
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("countrytxt")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="index_pl index_pr area_ul">
			{
				areaList.map((item,index)=>{
					return (<div>
					{
						item.length != 0 ? <div>
						<div className="main-box">
							<img className="main-va" width="25" src={item.img}/>
							<span className="main-inline main-va find_t2">{ item.country }</span>
						</div>
						{
							item.items.length == 0 ? <p className="main-tac area_empty">{ t("notyetn") }</p> : ""
						} 
						<ul className="main-font find_list">
						{
							item.items.map((ites,indexs)=>{
								return (<li className="main-inline main-vat find_li" key={indexs}>
								<div className="main-block main-over main-relative find_a" style={{ height: wHeight + 'px' }} onClick={tapchatlive.bind(this,ites.uinfo.uid)}>
									<img className="main-w10 main-va" src={ites.photo} onError={(e: any) => { e.target.src = defaultImg }} />
									<p className="main-w10 main-hg find_mask"></p>
									<div className="main-w10 main-flex main-aic find_down">
										<p className="main-f1 main-ellipsis find_name">{ ites.uinfo.nick }</p>
									</div>
								</div>
							</li>)
							})
						}
							
						</ul>
					</div> : ""
					}
					
				</div>)
				})
			}
				
			</div>
		</div>
	);
};

export default AreaPage; 