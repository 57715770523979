//创建用户信息
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router";
import DatePicker from 'react-mobile-datepicker';
import {getaeskey,loginAjax,tokenAjax} from "../request/api.js";  

import icon_back from "../img/icon_back.png";
import morenImg from "../img/moren@2x.png";
import editclose from "../img/editclose.png";
import girlYes from "../img/girlYes.png";
import girlNo from "../img/girlNo.png";
import boyYes from "../img/boyYes.png";
import boyNo from "../img/boyNo.png";
import loginBotton from "../img/loginBotton@2x.png";

import "../css/Main.css";
import "../css/login.css";

const Editinfo: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const [nameUser,setnameUser] = useState('');
	const [codeShow,setcodeShow] = useState(false);
	const [invitecode,setinvitecode] = useState('');
	const [gender,setgender] = useState(0);
	const [birthday,setbirthday] = useState('');
	const [times,settimes] = useState(new Date());
	const [getModalStatus,setgetModalStatus] = useState(false);
	const [birthdayShow,setbirthdayShow] = useState(false); 
	const navBack=()=>{
		navigate(-1)
	}
	const genderSend=(t)=>{
		setgender(t)
	}
	const checkPass=()=>{
		
	}
	const birthdayOpen=()=>{
		setbirthdayShow(true)
	}
	const birthclose=()=>{
		setbirthdayShow(false)
	}
	const handleSelect=(time)=>{
		console.log(time)
		settimes(time);
		let dtame = new Date(time);
		var dateTime = dtame.getFullYear()+'-'+checkTime(dtame.getMonth()+1)+'-'+checkTime(dtame.getDate()); 
		setbirthday(dateTime);
		setbirthdayShow(false)
	}
	const checkTime=(i)=>{
		 if(i<10){ 
		i = '0'+i 
		} 
		return i
	}
 
	return (
		<div className="main-box body-bg">
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("index_loginFonts")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="edit-bg">
				<div className="main-flex main-aic main-mob edit-p">
					<div className="editinfo-img edit_photo">
						<input type='file' id='PhotoF'/>
						<img id="phone_ok" src={morenImg} className="edit-moren"/>
					</div>
				</div>
				<div className=" main-border edit-info-auto">
					<div className=" main-mob edit-content">
						<div className="edit-left-font">{ t("nick") }</div>
						<div className="edit-right-input">
							<input className="login_mobiles login-inputs" maxlength="12" type="text" value={nameUser} onChange={(e) => { setnameUser(e.target.value); }} autofocus="autofocus" placeholder={t("dpAdd3")} />
							{
								nameUser!='' ? <img src={editclose} click="nickClose" className="edit-close" /> : ""
							}
							
						</div>
					</div>
					<div className=" main-mob edit-content">
						<div className="edit-left-font" >{t("birth")}</div>
						<div onClick={birthdayOpen} className="edit-right-input">
							<input className="login_mobiles login-inputs" maxlength="12" type="text" value={birthday} autofocus="autofocus" placeholder={t("birthday")} disabled="disabled" />
						</div>
					</div>
					{
						codeShow ? <div className="main-mob edit-content">
						<div className="edit-left-font">{ t("index_txtinvitecode") }</div>
						<div className="edit-right-input">
							<input className="login_mobiles login-inputs edit-input-border" maxlength="12" type="text" value={invitecode} onChange={(e) => { setinvitecode(e.target.value); }} autofocus="autofocus" placeholder={t("txtinvitecode")} />
						</div>
					</div> : ""
					}
					<div className=" main-mob edit-content">
						<div className="edit-left">
							<div className="edit-gender-font">{t("girl")}</div>
							{
								gender==2 ? <img src={girlYes} /> : <img src={girlNo} onClick={genderSend.bind(this,2)} />
							}
						</div>
						<div className="edit-left">
							<div className="edit-gender-font">{t("boy")}</div>
							{
								gender==1 ? <img src={boyYes} /> : <img src={boyNo} onClick={genderSend.bind(this,1)} />
							}
						</div>
					</div>
					<div className=" main-mob edit-content">
						<div className="edit-content-font">{t("index_gender_font")}</div>
					</div>
					<div className="login-bottom" onClick={checkPass}>
						<img src={loginBotton} className="editloginBotton" />
						
					</div>
				</div>
			</div>
			<DatePicker
			theme='android-dark'
			value={times}
			max={new Date(2005, 11, 31)}
			isOpen={birthdayShow}  
			confirmText={t("confirm")}
			cancelText={t("cancel")}
			onSelect={handleSelect}
			onCancel={birthclose} />
			
			
		</div>
	);
};

export default Editinfo; 