import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import Swiper from 'swiper'; 
import "swiper/css";

import defaultImg from "../../img/index/default.png";
import icon_f1 from "../../img/home/icon_f1.png";
import icon_f2 from "../../img/home/icon_f2.png";
let swiperAvatar;
const AvatarList: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const type = props.type;  
	const [list,setlist] = useState([]); 
	const [page,setpage] = useState(1); 
	const [isFollow,setisFollow] = useState(false); 
	useEffect(() => {
		setlist(props.list);
		setTimeout(function(){
			swiperAvatar = new Swiper(".swiper-Avatar", {
						  slidesPerView: 'auto', //同时显示的个数
						  //width: 80,
						  spaceBetween: 15, //两个slide之间的距离
						  freeMode: true,
						  autoHeight: true,
						  observer: true,
						  observeParents: true,
			    onTransitionEnd: function(swiper) { 
					//console.log("Swiper",swiper.activeIndex)
					setpage(swiper.activeIndex + 1)
			    } 
			});
		},1000)
		  
	},[]);
	const tapFollow=()=>{
		
	}
	const tapHideFollow=()=>{
		
	}
	return (
		<div className="swiper-container swiper-Avatar swiper-width main-box">
		{
			list.length > 0 ? <div className="swiper-wrapper">
			{
				list.map((item,index)=>{
					<div key={index} className='swiper-slide main-relative main-over main-flex main-aic main-jcc'>
						<img className="main-vat home_cover" src={item.cover!="" ? item.cover : defaultImg} onError={(e: any) => { e.target.src = defaultImg }}  />
					</div>
				})
			}
				
			</div> : <div className="swiper-wrapper">
				<div className='swiper-slide'>
					<img className="main-vat home_cover" src={defaultImg}  />
				</div>
			</div>
		}
		<div class="swiper-pagination"></div>
		{
			list.length > 0 ? <div className="home_page">{ page }/{ list.length }</div> : <div className="home_page">1/1</div>
		}
		{
			type == 2 ? <div className="main-box-follow"> 
			{
				isFollow == 0 ? <div className="main-flex main-aic main-jcc home_info_nofollow" onClick={tapFollow}>
				<img src={icon_f1} />
				<span>{ t("follow") }</span>
			</div> : <div className="main-flex main-aic main-jcc home_info_follow" onClick={tapHideFollow}>
				<img src={icon_f2} />
				<span>{t("beFollow")}</span>
			</div>
			}
			
			
		</div> : ""
		}
		
		
			
		</div> 
	);
};
export default AvatarList; 