import React, { useEffect, useState }  from "react"; 
 
import img_leve0 from "../../img/home/live0.png";
import img_leve1 from "../../img/home/live1.png";
import img_leve2 from "../../img/home/live2.png";
import img_leve3 from "../../img/home/live3.png";
import img_leve4 from "../../img/home/live4.png";
import img_leve5 from "../../img/home/live5.png";
import img_leve6 from "../../img/home/live6.png";
const GradeLevel: React.FunctionComponent = (props) => {  
	const livel = props.grade;  
 
	return (
		<div className="info-level-con">
			{
				livel==0 ? <img src={img_leve0} className="img_leve" />: ""
			}
			{
				16 > livel > 0 ? <img src={img_leve1} className="img_leve" /> : ""
			}
			{
				31 > livel > 15 ? <img src={img_leve2} className="img_leve" /> : ""
			}
			{
				46 > livel > 30 ? <img src={img_leve3} className="img_leve" /> : ""
			}
			{
				61 > livel > 45 ? <img src={img_leve4} className="img_leve" /> : ""
			}
			{
				76 > livel > 60 ? <img src={img_leve5} className="img_leve" />  : ""
			}
			{
				livel > 75 ? <img src={img_leve6} className="img_leve" />  : ""
			}	
			<span className="main-inline info-level-font">{ livel }</span>
		</div>
	);
};
export default GradeLevel; 