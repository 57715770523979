// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import {guardbuyitems,guardbuy } from "../../request/api.js";
import Toast from "../toast/toast.js";
import TipsPop from "./pop.js";
import popback from "../../img/guard/back.png";
import problem from "../../img/guard/problem.png";
import guardgold from "../../img/guard/gold.png";
import Supreme_Guardian_pendant from "../../img/guard/Supreme-Guardian-pendant.png";
import selectImg from "../../img/guard/select.png";
import Guardianlogo from "../../img/guard/Guardianlogo.png";
const GuardPop: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userId = props.uid;
	const type= props.type;
	const ucenterinfo = props.info;
	const [guardmyinfo,setguardmyinfo] = useState({});
	const [guardBuyitems,setguardBuyitems] = useState([]);
	const [guardSelect,setguardSelect] = useState(0);
	const [timenum,settimenum] = useState(0);
	const [timetype,settimetype] = useState(0);
	
	const [popShow,setpopShow] = useState(false);
	
	useEffect(() => { 
		guardAjax();
		
	},[]);
	const guardAjax=()=>{
		let dat={
			hostid: userId
		}
		guardbuyitems(dat).then((res)=>{
			if(res.errno == 0) {
				setguardBuyitems(res.data.items);
				settimenum(res.data.items[0].timenum);
				settimetype(res.data.items[0].timetype);
				setguardmyinfo(res.data.myinfo) 
			}
		})
	}
	const guardRuleOpen=()=>{
		navigate("/page/rule/guardRule");
	}
	const guardSelectOpen=(k,num,type)=>{
		setguardSelect(k);
		settimenum(num);
		settimetype(type);
	}
	const guardBuy=()=>{
		var dat={
			hostid : userId,
			roomid : userId,
			timenum : timenum,
			timetype : timetype
		}
		guardbuy(dat).then((res)=>{
			if(res.errno == 0) {
				Toast.fail(t("guardsuccess")) 
				//guardCloseAjax()
				tappopBtn(1)
			}else if(res.errno == 106002){
				setpopShow(true);
			}
		})
	}

	const tappopBtn = (type) => {
	  props.tapBtn(type);
	};
	const Tagsend=(type)=>{
		if (type == 2) {
		  //充值
		  //navigate("/page/rule/guardRule");
		  setpopShow(false);
		} else {
		  setpopShow(false);
		}
	}
	return (
		<div className="pop-content">
			<div className="guard-pop-bg" onClick={() => tappopBtn(1)}></div>
			<div className="guard-pop">
				<div className="main-box main-flex main-aic guard-pop-top">
					<img src={popback} onClick={() => tappopBtn(1)} className="guard-back-img" />
					{
						guardmyinfo.leftday==0 ? <div className="main-box main-f1 main-tac main-ellipsis head_md guard-top-title">{ t("guardTi") }</div> : <div className="main-box main-f1 main-tac main-ellipsis head_md guard-top-title pop-title-font">{t("HaveAlreadyBeen")} { ucenterinfo.nick } {t("guardTi1")}</div>
					} 
					<div onClick={guardRuleOpen}><img src={problem} className="main-box head_rt guard-problem-img" /></div>
				</div>
			</div>
			<div className="guard-pop-content">
				<div className="guard-pop-list">
				{
					guardBuyitems.map((item,index)=>{
						return (<div key={index} onClick={guardSelectOpen.bind(this,index,item.timenum,item.timetype)} className="guard-pop-li">
						<div className={guardSelect==index ? 'guard-pop-border guard-border-select' : 'guard-pop-border'}>
						{
							item.eprc==1 ? <div className=""></div> : ''
						}
						{
							item.reduce>0 ? <div className="guard-pop-li-reduce">
								{item.reduce }
							</div> : ""
						}
							<div className="guard-pop-li-current">
								<img src={guardgold} className="guard-gold" />
								<span className="guard-current">{item.current }</span><span className="guard-num-time">/{item.timenum}{item.timetype}</span>
							</div>
							{
								item.gdtype=='year' ? <div className="guard-pop-li-Supreme">
								<img src={Supreme_Guardian_pendant} className="guard-Guardian-pendant" />
							</div> : ""
							}
							{
								guardSelect==index ? <div className="guard-pop-li-show">
								<img src={selectImg} className="guard-pop-select" />
							</div> : ""
							}
							
						</div>
					</div>)
					})
				}
					
				</div>
				<div className="guard-pop-con">
					<div className="guard-pop-con-title">{t("guardTitle")}</div>
					<div className="guard-pop-con-icon">
						<div className="guard-icon-ul">
							<div className="guard-icon-li">
								<img src={Guardianlogo} className="guard-icon-li-img" />
								<p className="guard-icon-li-font">{t("guard1")}</p>
							</div>
							<div className="guard-icon-li">
								<img src={Guardianlogo} className="guard-icon-li-img" />
								<p className="guard-icon-li-font">{t("guard2")}</p>
							</div>
							<div className="guard-icon-li">
								<img src={Guardianlogo} className="guard-icon-li-img" />
								<p className="guard-icon-li-font">{t("ruleTable7")}</p>
							</div>
							<div className="guard-icon-li">
								<img src={Guardianlogo} className="guard-icon-li-img" />
								<p className="guard-icon-li-font">{t("ruleTable9")}</p>
							</div>
							<div className="guard-icon-li">
								<img src={Guardianlogo} className="guard-icon-li-img" />
								<p className="guard-icon-li-font">{t("ruleTable8")}</p>
							</div>
							<div className="guard-icon-li">
								<img src={Guardianlogo} className="guard-icon-li-img" />
								<p className="guard-icon-li-font">{t("ruleTable10")}</p>
							</div>
							<div className="guard-icon-li">
								<img src={Guardianlogo} className="guard-icon-li-img" />
								<p className="guard-icon-li-font">{t("ruleTable13")}</p>
							</div>
						</div>
					</div>
				</div>
				{
					guardmyinfo.leftday==0 ? <div onClick={guardBuy} className="guard-pop-bottom">
					{t("dredge")}
				</div> : <div  className="guard-pop-xufei">
							<div className="guard-pop-xufei-left">{t("indexSurplus")}：<span>{guardmyinfo.leftday} {t("garageDay")}</span></div>
							<div onClick={guardBuy} className="guard-pop-xufei-right">{t("garageRenew")}</div>
						</div>
				} 
			</div>
			{
				popShow ? <TipsPop tips={t("mlPay")} tapBtn={Tagsend.bind(this)} delete={t("rechorge")}></TipsPop> : ""
			}
			
		</div>
	);
};

export default GuardPop; 