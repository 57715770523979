// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router";
import {getaeskey,loginAjax,tokenAjax} from "../request/api.js";
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import icon_back from "../img/icon_back.png";
import no_see from "../img/no_see.png";
import see_img from "../img/see.png";
import "../css/Main.css";
import "../css/login.css";
const Pass: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const [mobfont1, setmobfont1] = useState(t("mobfont1")); 
	const [countryCode, setcountryCode] = useState(86);
	const [mobile, setmobile] = useState('');
	const [password, setpassword] = useState('');
	const [inputMob, setinputMob] = useState(100); 
	const [loginVerification, setloginVerification] = useState(t("loginVerification")); 
	const [aeskey, setaeskey] = useState("");
	const [ScreenH, setScreenH] = useState(0);
	const [numshow, setnumshow] = useState(false);
	useEffect(() => { 
		var loginCon = JSON.parse(window.localStorage.getItem('loginCon'));
		setmobile(loginCon.mobile);
		setcountryCode(loginCon.code); 
		aeskeyAjax();
	}, []);
	const aeskeyAjax=()=>{
		getaeskey().then((res)=>{
			if(res.errno == 0){ 
				setaeskey(res.data)
			}else{ 
			}
		})
	}
	
	const navBack=()=>{
		navigate(-1)
	}
	const checkPass=()=>{
		let pwd = "";
		var c = ''; 
		for(var i=0;i<password.length;i++){
			var b = String.fromCharCode(password.charCodeAt(i)^aeskey.charCodeAt(i));
			c =c + b;
		} 
		pwd = base64_encode(c);
		let datarr = {
			regionid: countryCode,
			phone: mobile,
			pwd: pwd,
			aeskey: aeskey
		}
		loginAjax(datarr).then((res)=>{
			if(res.errno == 0){
				localStorage.setItem('userArr', JSON.stringify(res.data));
				if(res.data.gender == 0){
					navigate("/Editinfo");
					//location.replace('editinformation.html');
				}else{
					tokenLogin(res)
				}
			}else{
				
			}
		})
	}
	const tokenLogin=(info)=>{
		tokenAjax({}).then((res)=>{
			if(res.errno == 0){
				var tokens = 'bl_time='+ res.data.bl_time +'&bl_token='+ res.data.bl_token+'&uid='+ res.data.uid; 
				localStorage.setItem('token', tokens);
				localStorage.setItem('rYToken', res.data.bl_token);
				let clickLogin = {
					countryCode: countryCode,
					mobile: mobile,
					password: password,
					nick: info.data.nick,
					avatar: info.data.avatar
				};
				localStorage.setItem('clickLogin', JSON.stringify(clickLogin));
				//location.replace('home.html');
				navigate("/page/index")
				
			}else{
				 
				
			}
		})
	}
	const setshow=()=>{
		if(numshow){
			setnumshow(false);
		}else{
			setnumshow(true);
		} 
	}
	return (
		<div className="main-box body-bg" >
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("phoneloginFont")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="login-content">
				
				<div className="main-mob">
					<div className="login-title-text">{t("index_pass")}</div>
					<div className="login-text">+{ countryCode } { mobile }  {t("mobfont2")}</div>
				</div>
				
				<div className="password-part">
				    <input className="input-background-color" type={numshow ? 'text' : 'password'} placeholder={t("loginpassword")} value={password} onChange={(e) => { setpassword(e.target.value); }} />
				    {
						!numshow ? <img src={no_see} onClick={setshow}  alt=""/> :  <img src={see_img} onClick={setshow} alt=""/>
					}
					
				   
				</div>
				<div className="main-aic login-vf"> 
					<div className="login-right login-vf-size">{t("loginforget")}</div>
				</div>
				<div className="login-bottom">
				{
					password.length ? <div className="login-yes-font" onClick={checkPass}>{t("loginBotton")}</div> : <div className="login-font">{t("loginBotton")}</div>
				}
				
				</div>
				
			</div>
			<div className="footer-bei">Corpration © 2016-2023 Anycupid.All Rights Reserved</div>
		</div>
	);
};

export default Pass; 