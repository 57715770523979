// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router"; 
import {guardList} from "../../../request/api.js";
import {timestampToTime} from "../../../utils/utils.js";
import { Scroll } from "../../scroll/scroll.tsx";
import GradeLevel from "../gradelevel.js";
import mlimg from "../../../img/ml.png";
import Guardian1 from "../../../img/home/Guardian1.png";
import defaultsimg from "../../../img/guard/defaults.png";
import Number2 from "../../../img/guard/Number2.png";
import Number3 from "../../../img/guard/Number3.png";
import guardVip from "../../../img/guard/vip.png";
import guardSVip from "../../../img/guard/svip.png";

let dataArr = [];
let listShow = true;
const HomeGuard: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userId = props.uid;
	const type= props.type;
	const ucenterinfo = props.info;
	let page = 1;
	const [list,setlist] = useState([]); 
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 46-46;
		setwHeight(Height);
		dataArr = [];
		guardAjax();
		
	},[]);
	const handleScroll  = () => {
	  console.log("resourcePage");
	  if (dataArr.length > 9) {
		  if(listShow){
			  page = page + 1;
			  guardAjax();
		  }
	    
	  }
	};
	const guardAjax=()=>{ 
		var darr = {
			hostid: userId,
			pageno: page,
			pagenum: 20
		}
		guardList(darr).then((res)=>{ 
			if(res.errno == 0) {
				var dylist = res.data.items;
				if(dylist.length > 0){
					listShow = true;
					if(dylist.length<10){
						listShow = false;
					}
					dataArr = dataArr.concat(dylist);
					console.log("dynamiclist",dataArr);
					setlist(dataArr);
				}else{
					listShow = false;
				}
				
				
				
			}
		})
	}
	const tapHome=(uid)=>{
		
	}
	return (
		<div className="guard-list  friendPage"  style={{ height: wHeight + "px" }}>
		{
			list.length == 0 ? <div v-if="list.length == 0" className="main-tac index_empty" >
			    <img className="main-vat" width="128" src={Guardian1} alt="Empty" />
			    <p>{ t('guardTitxt1') }</p>
			</div> : <ul className="index_you_list">
			<Scroll
			  height="100%"
			  load={handleScroll}
			  isFirstLoad={false}
			>
			{
				list.map((item,index)=>{
					return (<li className="main-relative index_you_li" key={index} onClick={tapHome.bind(this,item.uid)}>
				<div className="main-flex main-aic">
					<div className="main-relative index_you_head">
						<img className="main-radius main-vat" width="48" height="48" src={item.uinfo.avatar == '' ? defaultsimg : item.uinfo.avatar}  onError={(e: any) => { e.target.src = defaultsimg }}  alt="Head"/>
						<img className="index_you_top" src={item.gdtype == 'normal' ? Number3 : Number2} alt="Num1" />
					</div>
					<div className="main-f1 index_me_rt">
						<div className="main-flex main-aic">
							<p className="index_me_name">{ item.uinfo.nick }</p> 
							<img className="index_you_icon" width="15" src={item.gdtype == 'normal' ? guardVip : guardSVip} alt="Icon" />
							<div className="main-f1 main-flex main-aic">
								<GradeLevel  grade={item.uinfo.level}></GradeLevel>
							</div>  
						</div>
						<div className="main-flex main-aic index_me_types">
							<span className="index_you_c1">{t("indexSurplus")}{ item.leftday }{t("indexDay")}</span>
						</div>
					</div>
				</div>
				<div className="main-w10 index_you_border"><p></p></div>
			</li>)
				})
			}
			</Scroll>
		</ul>
		}
			
		</div>
	);
};

export default HomeGuard; 