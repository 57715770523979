// 登陆 
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {getCheckphone,getaeskey,getcaptcha,sendsms} from "../request/api.js";
import Toast from "../components/toast/toast.js";
import allCountry from "../json/alibaba_country.json";
import blBG from "../img/blBG.png";
import logo from "../img/logo.png";
import facebook from "../img/fb@2x.png";
import phoneImg from "../img/p@2x.png";
import google from "../img/google@2x.png";
import zalo from "../img/zalo@2x.png";
import vkimg from "../img/vk@2x.png";
import wxImg from "../img/w@2x.png";
import duojiao from "../img/duojiao@2x.png";
import closeimg from "../img/close.png";
import csImg from "../img/cs.png";
import selectImg from "../img/select@2x.png"; 
import noselectImg from "../img/noselect@2x.png"; 
import Safetysuaxin from "../img/Safetysuaxin.png";
import loadNo from "../img/load-no.png";
import pwa_android from "../img/pwa_android.png";
import "../css/Main.css";
import "../css/login.css";
const Login= (props) => {  
	const { t } = useTranslation();
	const navigate = useNavigate();
	console.log("Loginprops:",props)
	const [ScreenH, setScreenH] = useState('');
	const [ScreenW, setScreenW] = useState('');
	const [loginShow, setloginShow] = useState(true);
	const [pactImg, setpactImg] = useState(selectImg); 
	const [pactSelect, setpactSelect] = useState(1);
	const [phoneShow, setphoneShow] = useState(false);
	const [countryShow, setcountryShow] = useState(false);
	const [CName, setCName] = useState('United States');
	const [Cbrief, setCbrief] = useState('US');
	const countryArr = allCountry.countryList;
	const [countrySearch, setcountrySearch] = useState('');
	const [Ccode, setCcode] = useState(1);
	const [countryList, setcountryList] = useState([]);
	const [mobile, setmobile] = useState('');
	
	const [safetyShow, setsafetyShow] = useState(false);
	const [SafetyTXT, setSafetyTXT] = useState('');
	const [SafetyImg, setSafetyImg] = useState(loadNo);
	const [clickLoginShow, setclickLoginShow] = useState(false);
	const [clickLoginArr, setclickLoginArr] = useState({});
	const [pwaImg, setpwaImg] = useState(pwa_android);
	const [InstallShow, setInstallShow] = useState(false); 
	
	useEffect(() => {});
	const OnekeyLogin=()=>{
		let dataArr = {
			regionid: clickLoginArr.countryCode,
			phone: clickLoginArr.mobile
		}
		getCheckphone(dataArr).then((res) => {
			if(res.errno==0){
				let loginCon = {
					code: Ccode,
					mobile: mobile
				}
				console.log("文件：",loginCon);
				window.localStorage.setItem('loginCon', JSON.stringify(loginCon));
				if(res.data == 1){		
					aeskeyAjax();
					//window.location.href = 'passlogin.html';
				}
			}
		});
	}
	const aeskeyAjax=()=>{
		
	}
	const openWX=()=>{
		
	}
	const openVK=()=>{
		
	}
	const openZALO=()=>{
		
	}
	const openGoogle=()=>{
		
	}
	const openFB=()=>{
		//navigate("/Pass"); 
	}
	const phoneOpen=()=>{
		setphoneShow(true)
	}
	const phoneClose=()=>{
		setphoneShow(false)
	}
	const countryOpen=()=>{
		setcountryShow(true)
	}
	const countryClose=()=>{
		setcountryShow(false)
	}
	const checkMobile=()=>{
		let dataArr = {
			regionid: Ccode,
			phone: mobile
		}
		getCheckphone(dataArr).then((res) => {
			if(res.errno==0){
				let loginCon = {
					code: Ccode,
					mobile: mobile
				}
				console.log("文件：",loginCon);
				window.localStorage.setItem('loginCon', JSON.stringify(loginCon));
				if(res.data == 1){		
					navigate("/pass"); 
				}else{
					tapSafety();
				}
			}
		});
	}
	const tapSearch=(e)=>{
		var temps = [];
		setcountryList([]);
		setcountrySearch(e);
		//获取数据
		if(countrySearch.length > 0){
			var cl = countryArr;
			var reg = new RegExp(countrySearch);
			for(var i = 0; i < cl.length; i++){
				var name = cl[i].country;
				if(name.match(reg)){
					temps.push(cl[i]);
				}
			}
			setcountryList(temps); 
		}else{
			setcountryList(countryArr); 
		}
	}
	const tapSafety=()=>{
		setsafetyShow(true);
		tapSafetyAjax();
	};
	const SafetyClose=()=>{
		setsafetyShow(false);
	};
	const tapSafetyAjax=()=>{
		getcaptcha({}).then((res)=>{
			let domain = "https://"+res.data.domain+""+res.data.url;
			setSafetyImg(domain);
		})
	}
	const sendCode=()=>{
		if(SafetyTXT!==""){
			let datarr = {
			    regionid: Ccode,
			    phone: mobile,
				captcha: 1,
				cpt_code: SafetyTXT,
			    type: 'reg'
			}
			sendsms(datarr).then((res)=>{
				if(res.errno==0){
					SafetyClose();
					Toast.fail(t('n_vercode_send'));
					navigate("/register"); 
				}else if(res.errno==2000010){
					Toast.fail(res.errmsg)
				    setSafetyTXT(""); 
					tapSafetyAjax();
					
				}else{
					SafetyClose();
					Toast.fail(res.errmsg);
				}
			})
		}
		
	}
	const pactOpen=()=>{
		if(pactSelect==1){ 
			setpactSelect(2);
			setpactImg(noselectImg);
		}else{
			setpactSelect(1);
			setpactImg(selectImg);
		}
	}
	const countrySelected=(name,brief,code)=>{ 
		setCName(name);
		setCbrief(brief); 
		setCcode(code); 
		countryClose()
	}
 
	return (
		<div > 
			<div className="index-bg">
				<img src={blBG} className="bl-bg-img"  alt="es-lint want to get" />
			</div>
			
			<div className="index-content">
				<div className="index-content-top"  >
					<div className="index-content-login">
						
						<img src={logo} className="index-content-login-img"  alt="es-lint want to get"/> 
						{
							clickLoginShow ? <div className=" content-one" onClick={OnekeyLogin}>
							<img src={clickLoginArr.avatar}  className="Onekey-img"  alt="es-lint want to get" />
							<div className="onekey-font">{t("clickLogin")}</div>
						</div> : ""
						}
						
					</div>
				</div>
				{
					phoneShow ? <div onClick={phoneClose} className="index-phone-bg"></div> : ""
				}
				
				<div className="index-botton">
				{
					!phoneShow ? <div className="index-content-botton">
					
					
					<div className="index-phone-font">{ t("index_mode") }</div>
					<div  className="index-content-b index-content-w" onClick={openWX}>
						<img src={wxImg}  alt="es-lint want to get" />
						<span className="botton-font">{t("useweixinlogin")}</span>
					</div>
					<div  className="index-content-b index-content-v" onClick={openVK}>
						<img src={vkimg}  alt="es-lint want to get" />
						<span className="botton-font">{t("VKlogin")}</span>
					</div>
					<div  className="index-content-b index-content-z" onClick={openZALO}>
						<img src={zalo}  alt="es-lint want to get" />
						<span className="botton-font">{t("zalologin")}</span>
					</div>
					<div  id="customBtn" className="index-content-b index-content-g" onClick={openGoogle}>
						<img src={google}  alt="es-lint want to get" />
						<span className="botton-font">{t("Googlelogin")}</span>
					</div>
					<div  className="index-content-b index-content-f" onClick={openFB}>
						<img src={facebook}  alt="es-lint want to get" />
						<span className="botton-font">{t("facebooklogin")}</span>
					</div>
					<div  onClick={phoneOpen} className="index-content-b index-content-p" >
						<img src= {phoneImg}  alt="es-lint want to get" />
						<span className="botton-font">{t("usephonelogin")}</span>
					</div>
				</div> : <div className="index-content-botton">
					
						<div className="index-phone-font">{t("phoneloginFont")}</div>
						<div className="index-content-b index-content-input" >
							<a  onClick={countryOpen} className="bl-country-a">+{Ccode}<img src={duojiao} className="country-bg"  alt="" />|</a>
							<input className="bl-phone-input" value={mobile} style= {{backgroundColor:'transparent'}} type="text" placeholder={t('writephone')} onChange={(e) => { setmobile(e.target.value); }} />
							
						</div>
						<div  onClick={checkMobile} className="index-content-b index-content-next" >
							<span className="botton-font">{t("nextstep")}</span>
						</div>
					</div>
				}
				</div>
				<div className="index-content-footer"  >
					<div className="footer-rule">
						<img onClick={pactOpen} src={pactImg}  alt="" />
						<span>{t("youagree")}</span>
						<Link to="/pact/service" className="pact-color">《{t('Agreement')}》</Link>
						<span>{t("and")}</span>
						<Link to="/pact/privacy" className="pact-color">《{t('Policy')}》</Link>
					</div>
					
				</div>
				<div className="footer-bei">Corpration © 2016-2023 Anycupid.All Rights Reserved</div>
				{
					countryShow ? <div>
						<div  onClick={countryClose} className="dialogBg"></div>
						<div className="animated">
							<div className="dialog-bg">
								<img onClick={countryClose} src={closeimg} className="dialog-back"  alt="" />
								<div className="dialog-title" >
									{ t("countryarea") }
								</div>
								<div className="country-search">
									<img src={csImg} className="search-img"  alt="" />
									<input className="B-content-input-class country-input-search" value={countrySearch} type="text" onChange={(e) => { setSafetyTXT(e.target.value); }}  placeholder={t("countryarea")} />
									
								</div>
								<div className="dialog-ul">
								{
									allCountry.countryList.map((item,index)=>{
										return (
										<div key={index} className="dialog-li">
											<div className={'country-img '+(item.show==1 ? 'country_show' : '')}  onClick={countrySelected.bind(this, item.country,item.country_code,item.code)}>
												<img src={"https://img.anycupid.com/countryImg/" + item.country_code+".png"} className="country-Img "  alt="" />
												<span className="country-name">{ item.country }</span><span> (+{item.code})</span>
											</div>
										</div>
										)
									})
								}
									
								</div>
							</div>
						</div>
					</div> : ''
				}
				 {
					 safetyShow ? <div><div  onClick={SafetyClose} className="dialogBg"></div>
						<div className="animated">
							<div className="dialog-bg">
								<img onClick={SafetyClose} src={closeimg} className="dialog-back" />
								<div className="dialog-title">
									{ t("Safety_title") }
								</div>
								
								<div className="dialog-txt">{t("Safety_txt")}</div>
								<div className="dialog-input">
									<div className="Safety-enter">
										<input className="Safety-input" type="text" value={SafetyTXT} onChange={(e) => { setSafetyTXT(e.target.value); }}  autofocus="autofocus" />
										<div className="Safety-img"><img src={SafetyImg} className="Safety-Code-map"  onClick={tapSafetyAjax} /></div>
										<img src={Safetysuaxin} className="Safety-break" onClick={tapSafetyAjax} />
									</div>
								</div>
								<div onClick={sendCode} className="dialog-botton">{ t("Safety_confirm") }</div>
							</div>
						</div>
					 </div> : ""
				 }
				
				
			</div>
		</div>
	);
};

export default Login;