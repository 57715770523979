//排行榜
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import RankList from "./ranklist.js";
import icon_back from "../../../img/icon_back.png";
import img_bg from "../../../img/live/rank/img_bg.png"
import Swiper from 'swiper'; 
import "swiper/css";
import "../../../css/swiper.css";
var swiperRank;
const RankPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation(); 
	const [activedIndex,setactivedIndex] = useState(0);
	const [catId,setcatId] = useState(0);
	const [cateType,setcateType] = useState("host");
	const [rankId,setrankId] = useState(0);
	const [rankCate,setrankCate] = useState("date");
	const [imgH,setimgH] = useState(0);
	const [swiperH,setswiperH] = useState(0);
	useEffect(() => {
		setrankId(location.state.rankid);
		if(location.state.rankid==0){
			setcateType("host");
		}else if(location.state.rankid==1){
			setcateType("user");
		}else if(location.state.rankid==2){
			setcateType("fans");
		}
		let w = document.documentElement.clientWidth/75*37;
		setimgH(w);
		let h = document.documentElement.clientHeight-w;
		setswiperH(h)
		  swiperRank = new Swiper(".swiper-rank", { 
			  slidesPerView: 'auto', //同时显示的个数
			  //width: 80,
			  spaceBetween: 15, //两个slide之间的距离
			  freeMode: true,
			  autoHeight: true,
			  observer: true,
			  observeParents: true,
		      onTransitionEnd: function(swiper) { 
		  		
		  		setrankId(swiper.activeIndex + 1);
				if((swiper.activeIndex + 1) == 0){
					setrankCate("date");
				}else if((swiper.activeIndex + 1) == 1){
					setrankCate("week");
				}else if((swiper.activeIndex + 1) == 2){
					setrankCate("month");
				}else if((swiper.activeIndex + 1) == 3){
					setrankCate("total");
				}
		      } 
		  });
	},[]);
	const tapTabs=(id,t)=>{
		setcatId(id)
		setcateType(t);
		setrankId(0);
		swiperRank.slideTo(0);
	}
	const tapSlideItem=(id,t)=>{
		swiperRank.slideTo(id);
		setrankId(id);
		setrankCate(t);
	}
	const tapRelease=()=>{
		
	}
	return (
		<div className="main-box">
			<header id="header">
				<div className="main-box main-flex main-aic head head-transparent"  >
					<div className="main-box head_lf" >
						<a  onClick={() => navigate(-1)}><img src={icon_back} className="head_back" /></a>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-rank">{ t("AnchorlistTitle") }</div>
					<div className="main-box head_rt"  ></div>
				</div>
			</header>
			<div className="rank">
				<div style={{height: imgH + 'px'}}>
					<div className="main-w10 main-relative rank_tab">
						<img className="main-w10 main-vat rank_bg" src={img_bg} />
						<div className="main-w10 main-hg rank_top"> 
							<div className="main-flex rank_category">
								<p className={catId==0 ? "main-f1 main-flex main-aic main-jcc rank_grade rank_item" : "main-f1 main-flex main-aic main-jcc rank_grade" } onClick={tapTabs.bind(this,0,"host")}>{t("AnchorlistTitle")}</p>
								<p className={catId==1 ? "main-f1 main-flex main-aic main-jcc rank_grade rank_item" : "main-f1 main-flex main-aic main-jcc rank_grade" } onClick={tapTabs.bind(this,1,"user")}>{t("RichListTitle")}</p>
								<p className={catId==2 ? "main-f1 main-flex main-aic main-jcc rank_grade rank_item" : "main-f1 main-flex main-aic main-jcc rank_grade" } onClick={tapTabs.bind(this,2,"fans")}>{t("FanlistTitle")}</p>
							</div>
							 
							<div className="main-flex main-aic rank_list">
								<div className="main-f1 main-tac" onClick={tapSlideItem.bind(this,0,"date")}>
									<div className="main-inline main-relative">
										<span className={rankId == 0 ? "rank_t1 rank_active" : "rank_t1"}>{t("DayList")}</span>
										{
											rankId == 0 ? <div className="main-w10 main-tac main-font rank_xian">
											<p className="main-inline main-hg"></p>
										</div> : ""
										}
									</div>
								</div>
								<div className="main-f1 main-tac" onClick={tapSlideItem.bind(this,1,"week")}>
									<div className="main-inline main-relative">
										<span className={rankId == 1 ? "rank_t1 rank_active" : "rank_t1"}>{t("WeekList")}</span>
										{
											rankId == 1 ? <div className="main-w10 main-tac main-font rank_xian">
											<p className="main-inline main-hg"></p>
										</div> : ""
										}
									</div>
								</div>
								<div className="main-f1 main-tac" onClick={tapSlideItem.bind(this,2,"month")}>
									<div className="main-inline main-relative">
										<span className={rankId == 2 ? "rank_t1 rank_active" : "rank_t1"}>{t("MonthList")}</span>
										{
											rankId == 2 ? <div className="main-w10 main-tac main-font rank_xian">
											<p className="main-inline main-hg"></p>
										</div> : ""
										}
									</div>
								</div>
								<div className="main-f1 main-tac" onClick={tapSlideItem.bind(this,3,"total")}>
									<div className="main-inline main-relative">
										<span className={rankId == 3 ? "rank_t1 rank_active" : "rank_t1"}>{t("OverallList")}</span>
										{
											rankId == 3 ? <div className="main-w10 main-tac main-font rank_xian">
											<p className="main-inline main-hg"></p>
										</div> : ""
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="swiper-container swiper-rank"  style={{ height: swiperH + 'px;' }}>
					<div class="swiper-wrapper">
						<div className='swiper-slide rank_ul'>
							<RankList cat={cateType} rankid="date"></RankList>
						</div>
						<div className='swiper-slide rank_ul'>
							<RankList cat={cateType} rankid="week"></RankList>
						</div>
						<div className='swiper-slide rank_ul'>
							<RankList cat={cateType} rankid="month"></RankList>
						</div>
						<div className='swiper-slide rank_ul'>
							<RankList cat={cateType} rankid="total"></RankList>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	);
	
};

export default RankPage; 