//密聊
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {usermyinfo,hoemInfo} from "../../../request/api.js";
import mtclose from "../../../img/mtclose.png";
import defaultImg from "../../../img/index/default.png";
import videoguadaun from "../../../img/videoguadaun.png";
import noticeVoice from "../../../img/noticeVoice.png";
import noticeVideo from "../../../img/noticeVideo.png";
import timeicon from "../../../img/timeicon.png";
import viseoicon from "../../../img/viseoicon.png";
import yuyinjing from "../../../img/yuyinjing.png";
import yuyinkai from "../../../img/yuyinkai.png";
const MlPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	let tuid = location.state.uid; 
	const [userid,setuserid] = useState('');
	const [wHeight,setwHeight] = useState(0);
	const [wWidth,setwWidth] = useState(0);
	const [mltype,setmltype] = useState(0);//1语言/2视频
	const [mlState,setmlState] = useState(0);
	const [hostinfo,sethostinfo] = useState({});
	const [userMinfo,setuserMinfo] = useState({});
	const [errnoTxt,seterrnoTxt] = useState('');
	const [status,setstatus] = useState(0);
	const [typemt,settypemt] = useState(0);//类型 //1.拨打语言，2拨打视频，3，接听语音，4，接听视频
	const [timeTimer,settimeTimer] = useState('00:00');
	const [chatinfoTime,setchatinfoTime] = useState('00:00');
	const [isFollow,setisFollow] = useState(0);
	const [userIdentity,setuserIdentity] = useState(0);
	const [MuteSelect,setMuteSelect] = useState(false);
	useEffect(() => { 
		setmltype(location.state.ctype);
		myinfoAjax();
		hoemInfoAjax();
		let Height = document.documentElement.clientHeight;
		setwHeight(Height);
		let width = document.documentElement.clientWidth;
		setwWidth(width);
		 
		return () => { 
		}
		 
	},[]);
	const myinfoAjax=()=>{
		usermyinfo({}).then((res)=>{
			if(res.errno == 0) {
				setuserIdentity(res.data.minfo.ml);
				setuserid(res.data.uinfo.uid);
			}
		})
	}
	const hoemInfoAjax=()=>{
		var darr = {
			uid: tuid,
		}
		hoemInfo(darr).then((res)=>{
			if(res.errno == 0) { 
				sethostinfo(res.data.uinfo)
				setuserMinfo(res.data.minfo) 
			}
		})
	}
	const secretClose=()=>{
		navigate(-1);
	}
	const michatCancel=(uid,t)=>{
		
	}
	const secretResponse=(uid,s,t)=>{
		
	}
	const AudioMute=(t)=>{
		
	}
	const returnPage=(p)=>{
		
	}
	return (
		<div className="video-left" style={{ height: wHeight  + "px", width: wWidth  + "px" }}>
			<div className="liveinfo-info-left content-position " style={{ height: wHeight  + "px", width: wWidth  + "px" }}>
				<div className="video-bg-img" style={{ height: wHeight  + "px", width: wWidth  + "px" }}></div>
				<img src={mtclose} className="wap-lgclose " onClick={secretClose} />
				<div className="video-front" style={{ height: wHeight  + "px", width: wWidth  + "px" }}>
					{
						mlState==1 ? <div className="mlfalse">
							<div className="video-user-avatar liveinfo-avatar">
								<img src={hostinfo.avatar} onError={(e: any) => { e.target.src = defaultImg }} className="video-avatar-img"  />
							</div>
							<div className="video-user-nick">
								<p>{ hostinfo.nick }</p>
								<p className="ml-user-price">{ userMinfo.videoprice }G/min</p>
							</div>
							<div className="video-bottom ">
								<div >
								{
									status==0 ? <p>{ t("mlConnecting") }</p> : ""
								}
								{
									status==2 ? <p className="ml-color-FFB32B">{ t("mlSorry") }</p> : ""
								}
								{
									status==3 ? <p className="ml-color-FFB32B">{ t("mlPay") }</p> : ""
								}	
								{
									status==1 ? <p className="ml-color-FFB32B">{ errnoTxt }</p> : ""
								}	
								</div> 
								{
									//拨打语言
									typemt==1 ? <div className="video-img voice-con">
									<img onClick={michatCancel.bind(this,hostinfo.uid,1)} src={videoguadaun} />
									<p className="ml-typemt-cancel">{t("cancel")}</p>
								</div> : ""
								}
								{
									//拨打视频
									typemt==2 ? <div className="video-img voice-con">
									<img onClick={michatCancel.bind(this,hostinfo.uid,2)} src= {videoguadaun} />
									<p className="ml-typemt-cancel">{t("cancel")}</p>
								</div> : ""
								}
								{
									//接听语音
									typemt==3 ? <div className="video-img voice-con">
									<div className="video-pa" onClick={secretResponse.bind(this,hostinfo.uid,0,1)}>
										<img src={videoguadaun} />
										<p className="ml-typemt-cancel">{ t("mlRefuse") }</p>
									</div>
									<div className="video-pa" onClick={secretResponse.bind(this,hostinfo.uid,1,1)}>
										<img src={noticeVoice} />
										<p className="ml-typemt-cancel">{ t("mlAnswer") }</p>
									</div>
								</div> : ""
								}
								
								{
									//接听视频
									typemt==4 ? <div className="video-img voice-con">
									<div className="video-pa" onClick={secretResponse.bind(this,hostinfo.uid,0,2)}>
										<img src={videoguadaun} />
										<p className="ml-typemt-cancel">{t("mlRefuse")}</p>
									</div>
									<div className="video-pa" onClick={secretResponse.bind(this,hostinfo.uid,1,2)}>
										<img src={noticeVideo} />
										<p className="ml-typemt-cancel">{t("mlAnswer")}</p>
									</div>
								</div> : ""
								}
								{
									typemt==0 ? <div className="video-img voice-con">
									<img onClick={michatCancel.bind(this,hostinfo.uid,1)} src={videoguadaun} />
									<p className="ml-typemt-cancel">{t("cancel")}</p>
								</div> : ""
								}
								
							</div>
						</div> : ""
					}
					{
						mlState==2 ? <div className="mltrue">
						{
							mltype==2 ? <div className="video-during">
							<div className="" style={{ height: wHeight  + "px", width: wWidth  + "px" }}>
								<div className="video-left-bot video-user-bg ml-video-style">
									<img src={hostinfo.avatar}  onError={(e: any) => { e.target.src = defaultImg }} className="v-avatar-img ml-avatar-style"/>
									<span className="ml-name-font">{ hostinfo.nick } </span>
								</div>
								<div className="video-left-bot video-time" style="top: 60px;">
									<img src={timeicon} />
									<span>{ timeTimer } </span>
								</div>
								<div className="video-left-bot video-icon" style="top: 95px;">
									<img src={viseoicon} />
									<span>{ userMinfo.videoprice }G/min</span>
								</div>
								<div className="video-tA">
									<div className="col">
										<div id="tA-player" style={{ height: wHeight  + "px", width: wWidth  + "px" }}></div>
									</div>
								</div>
								<div className="video-my">
									<div className="col">
										<div id="local-player" className="player ml-payer-top" ></div>
									</div>
								</div>
							</div>
						</div> : <div className="video-front" style={{ height: wHeight  + "px", width: wWidth  + "px" }}>
							  <div className="video-user-avatar liveinfo-avatar">
								<img src={hostinfo.avatar}  onError={(e: any) => { e.target.src = defaultImg }} className="video-avatar-img" />
							  </div>
							  <div className="video-user-nick">
									<p>{ hostinfo.nick }</p>
									<p className="ml-user-price">{ userMinfo.voiceprice }G/min</p>
							  </div>
							  <div className="video-bottom ml-video-bottom">								  
								  <p className="ml-color-FFB32B"> { timeTimer }</p>
								  <div className="voice-con">
									  <div className="voice-left">
									  {
										  MuteSelect ? <div onClick={AudioMute.bind(this,0)} className="voice-Mute">
											  <img  src={yuyinjing} />
											  <p className="ml-typemt-cancel">{ t("mlMute") }</p>
										  </div> : <div className="voice-Mute"  onClick={AudioMute.bind(this,1)} >
											  <img  src={yuyinkai} />
											  <p className="ml-typemt-cancel">{ t("mlwheat") }</p>
										  </div>
									  }  
									  </div>
									  <div className="voice-left">
										 <img onClick={secretClose} src={videoguadaun} />
										 <p className="ml-typemt-cancel">{ t("mlhangUp") }</p>
									  </div>
								  </div>
							  </div>
						</div>	
						}
						</div> : ""
					}
					{
						mlState==0 ? <div className="mlfalse">
							<div className="Call-ended">{t("mlend")}</div>
							<div className="video-user-avatar liveinfo-avatar">
								<img src={hostinfo.avatar}  onError={(e: any) => { e.target.src = defaultImg }}  className="video-avatar-img"  />
							</div>
							<div className="video-user-nick">
								<p>{ hostinfo.nick }</p>
							</div>
							<div className="duration-font">
								 <p>{ t("mlTime") }</p>
								 <p className="ml-color-FF7735">{ chatinfoTime }</p>
							</div>
							{
								isFollow == 0 ? <div onClick={returnPage.bind(this,1)} className="call-bottom cursor">{t("mlFollowReturn")}</div> : <div onClick={returnPage.bind(this,0)} className="call-bottom cursor">{t("mlReturn")}</div>
							} 
						</div> : ""
					}
				</div>
			</div>
		</div>
	);
};

export default MlPage; 