//交友列表 
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"; 
import { Scroll } from "../../../../components/scroll/scroll.tsx";
import {recommendlist,michatList} from "../../../../request/api.js";
import zhubo from "../../../../img/giftd/zhubo.png";
import liveImg from "../../../../img/home/live.gif";
import defaultImg from "../../../../img/index/default.png";
import mlImg from "../../../../img/ml.png"
let dataArr = [];
let mlArr=[];
let listShow,mlShow = true;
const RecommendList: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	let page = 1;
	let mlpage = 1;
	const [list,setlist] = useState([]); 
	const [wHeight,setwHeight] = useState(0);
	const [liHeight,setliHeight] = useState(0);
	const [mllist,setmllist] = useState([]); 
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 46-46;
		setwHeight(Height);
		let width = document.documentElement.clientWidth;
		let w= (width-30)/2;
		setliHeight(w);
		dataArr = [];
		mlArr = [];
		recommendAjax();
		mllistAjax();
		
	},[]);
	const handleScroll  = () => { 
	  if(listShow){
		  page = page + 1;
		  recommendAjax();
	  }else{
		  if(mlShow){
			  mlpage = mlpage+1;
			  mllistAjax()
		  }
	  }
	};
	const recommendAjax=()=>{
		var darr = {
			pageno: page,
			pagenum: 20
		}
		recommendlist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					listShow = true;
					let lists = res.data.items;
					if(lists.length<10){
						listShow = false;
					}
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				}
			}
		})
	}
	const mllistAjax=()=>{
		var darr = {
			pageno: mlpage,
			pagenum: 20
		}
		michatList(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					mlShow = true;
					let lists = res.data.items;
					if(lists.length<10){
						mlShow = false;
					}
					mlArr = mlArr.concat(lists); 
					setmllist(mlArr);
				}else{
					mlShow = false;
				}
			}
		})
	}
	const liveOpen=(id)=>{
		navigate("/page/liveroom/live", { state: { channel: id } })
	}
	const tapHome=(uid)=>{
		navigate("/page/home/index", { state: { uid: uid,type: 2 } });
	}
	return (
		<div className="content-list livePage" style={{ height: wHeight + "px" }}>
		<div className="main-flex main-aic main-jcc">
			<ul className="main-over main-font live-margin">
			<Scroll
			  height="100%"
			  load={handleScroll}
			  isFirstLoad={false}
			>
			{
				list.map((item,index)=>{
					return (<li className="main-inline main-w5 index_pt live_li" key={index}>
					<div className="main-block main-over main-relative live_content" onClick={liveOpen.bind(this,item.uid)} style={{ height: liHeight + 'px' }}  >
						<img className="main-w10 main-vat" src={item.photo=='' ? defaultImg : item.photo}  onError={(e: any) => { e.target.src = defaultImg }}  />
						{
							item.streamflag == 1 ? <div className="main-flex main-aic main-jcc live_direct">
							<img src={liveImg} className="streamflag_bg" />
							<p>Live</p>
						</div> : ""
						}
						
			
						
						<div className="main-w10 index_pl index_pr live_info">
							<p className="main-ellipsis live_name">{ item.uinfo.nick }</p>
							<div className="main-flex main-aic">
							{
								item.country=='' ? <p className="main-f1 main-ellipsis live_address">{ t("address") }</p> : <p className="main-f1 main-ellipsis live_address">{ item.country }.{ item.place }</p>
							}
								
								
								<span className="main-b live_num">{ item.personnum }</span>
							</div>
						</div>
					</div>
				</li>)
				})
			}
			{
				mllist.map((item,index)=>{
					return (<li className="main-inline main-w5 index_pt live_li" key={index}>
					<div className="main-block main-over main-relative live_content" onClick={tapHome.bind(this,item.uid)} style={{ height: liHeight + 'px' }}  >
						<img className="main-w10 main-vat" src={item.cover=='' ? defaultImg : item.cover}  onError={(e: any) => { e.target.src = defaultImg }}  />
						<div className="main-flex main-aic main-jcc ml-re-img">
							<img src={mlImg} className="streamflag_bg " /> 
						</div>
						
			
						
						<div className="main-w10 index_pl index_pr live_info">
							<p className="main-ellipsis live_name">{ item.uinfo.nick }</p>
							<div className="main-flex main-aic">
							{
								item.ctcode=='' ? <p className="main-f1 main-ellipsis live_address">{ t("address") }</p> : <p className="main-f1 main-ellipsis live_address">{ item.ctcode }</p>
							}
								 
							</div>
						</div>
					</div>
				</li>)
				})
			}
			</Scroll>	
			</ul>
		</div>
			
			
		</div>
	);
};

export default RecommendList; 