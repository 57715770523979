//编辑个人信息
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {infoedit } from "../../../../request/api.js";
import Toast from "../../../../components/toast/toast.js";
import icon_back from "../../../../img/icon_back.png";
import icon_right from "../../../../img/home/icon_right.png";
import icon_del from "../../../../img/mine/icon_del.png"
const WorkEdit: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const location = useLocation();
	const [career,setcareer] = useState("");
	const [min,setmin] = useState(0);
	useEffect(() => {
		//let userArr = JSON.parse(localStorage.getItem('userArr'));
		setcareer(location.state.con);
		
	},[]);
	const completeOpen=()=>{
		var darr = {
			work: career,
		}
		infoedit(darr).then((res)=>{
			
			if(res.errno == 0){
				Toast.fail(t("tianjiaok"))
				navigate(-1); 
			}else{
				Toast.fail(t("loadFail"))
				 
			}
		})
	}
	const inputOnChange=(v)=>{
		setcareer(v);
		setmin(v.length);
	}
	const tapEmpty=()=>{
		setcareer("");
		setmin(0)
	}
	return (
		<div className="edit-page">
			<header className="header">
				<div className="main-box main-flex main-aic head" >
					<div className="main-box head_lf">
						<div onClick={() => navigate(-1)}><img src={icon_back} className="head_back"/></div>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("editWork")}</div>
					<div onClick={completeOpen} className="main-box head_rt head-seen-right" >{t("complete")}</div>
				</div>
			</header>
			<div className="main-box index_mt">
				<div class="main-bg main-flex main-aic main-relative index_pl index_pr edit_nick">
					<input class="main-f1 main-hg edit_nickname" type="text" value={career} onChange={(e) => { inputOnChange(e.target.value); }} maxlength="12" autofocus="autofocus" placeholder={t("enterWork")} />
					<img v-show="min > 0" class="edit_1_del" onClick={tapEmpty} src={icon_del} />
				</div>
				<p class="main-tar index_pl index_pr edit_num">{ min }/12</p>
			</div>
		</div>
	);
};

export default WorkEdit; 