//编辑个人信息
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {infoedit } from "../../../../request/api.js";
import Toast from "../../../../components/toast/toast.js";
import icon_back from "../../../../img/icon_back.png";
import icon_right from "../../../../img/home/icon_right.png"
const SignEdit: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const [userInfo,setuserInfo] = useState({});
	const [min,setmin] = useState(0);
	const [sign,setsign] = useState("");
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr'));
		setuserInfo(userArr);
		
	},[]);
	
	const inputOnChange=(v)=>{
		setsign(v);
		setmin(v.length);
	}
	const completeOpen=()=>{
		var darr = {
			sign: sign,
		}
		infoedit(darr).then((res)=>{
			if(res.errno == 0){
				Toast.fail(t("tianjiaok"))
				navigate(-1); 
			}else{
				Toast.fail(t("loadFail"))
				 
			}
		})
	}
	return (
		<div className="edit-page">
			<header className="header">
				<div className="main-box main-flex main-aic head" >
					<div className="main-box head_lf">
						<div onClick={() => navigate(-1)}><img src={icon_back} className="head_back"/></div>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("sign")}</div>
					<div onClick={completeOpen} className="main-box head_rt head-seen-right" >{t("complete")}</div>
				</div>
			</header>
			<div className="index_mt">
				<div className="main-bg index_pl index_pr"> 
					<textarea id="signT" className="main-w10 main-vat edit_sign" rows="5" value={sign} onChange={(e) => { inputOnChange(e.target.value); }}  maxlength="100" placeholder={t("enterSign")}></textarea>
					<p className="main-tar index_pl index_pr edit_num">{ min }/100</p>
				</div>
			</div>
		</div>
	);
};

export default SignEdit; 