// 粉丝
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"; 
import {timestampToTime} from "../../../utils/utils.js";
import {followlist,followdel} from "../../../request/api.js";
import Toast from "../../../components/toast/toast.js";
import { Scroll } from "../../../components/scroll/scroll.tsx";
import TipsPop from "../../../components/pop/pop.js";
import icon_back from "../../../img/icon_back.png";
import f_02png from "../../../img/online/f_02.png";
import icon_boy from "../../../img/home/icon_boy.png";
import icon_girl from "../../../img/home/icon_girl.png";
import defaultImg from "../../../img/index/default.png";
import nullImg from "../../../img/giftd/guanzhu.png";
let dataArr = [];
let listShow = true;
const FollowPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	let userId = "";
	let page = 1;
	const [list,setlist]=useState([]);
	const [wHeight,setwHeight] = useState(0);
	const [popShow,setpopShow] = useState(false);
	const [fuid,setfuid] = useState("");
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr'));
		userId = userArr.uid; 
		let Height = document.documentElement.clientHeight - 46-46;
		setwHeight(Height);
		dataArr = [];
		listAjax();
	}, []);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  listAjax();
		  }
	    
	  }
	};
	const listAjax=()=>{
		let darr = {
			pageno: page,
			pagenum: 30,
		}
		followlist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					}
					for(var i=0;i<lists.length;i++){
						lists[i].addtime = timestampToTime(lists[i].addtime);
					}
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				} 
							
			}else{
				Toast.fail(res.errmsg);
			}
		})
		
	}
	const navBack=()=>{
		navigate(-1)
	}
	const tapHome=(uid)=>{
		navigate("/page/home/index", { state: { uid: uid,type: 2 } });
	}
	const tapCancelFollow=(uid)=>{
		setfuid(uid)
		setpopShow(true)
	}
	const Tagsend=(type)=>{
		if (type == 2) {
		  followdelAjax();
		  setfuid("");
		  setpopShow(false);
		} else {
		  setfuid("");
		  setpopShow(false);
		}
	}
	const followdelAjax=()=>{
		let fdarr = {
			hostid: fuid,
		}
		followdel(fdarr).then((res)=>{
			if(res.errno == 0){
				Toast.fail(t("followdel"));
				let dlist = [];
				for(var i=0;i<dataArr.length;i++){
				 	if(fuid != dataArr[i].uid){ 
						dlist = dlist.concat(dataArr[i]);
				 	}
				}
				dataArr = dlist;
				setlist(dlist);
					//that.isFollow = false;								
			}
		})
	}
	return (
		<div className="">
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("followPerson")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="fans">
			{
				list.length>0 ? <ul className="main-box">
					<Scroll
					  height="100%"
					  load={handleScroll}
					  isFirstLoad={false}
					>
					{
						list.map((item,index)=>{
							return (<li className="main-flex main-aic index_pl" key={index}>  
								<div class="main-box main-flex main-aic main-jcc news_online news_unline" onClick={tapHome.bind(this,item.uid)}>
									<img className="main-radius main-w10" src={item.uinfo.avatar} onError={(e: any) => { e.target.src = defaultImg }} />
								</div> 
								<div class="main-bb main-f1 main-flex main-aic main-over index_pr fans_rt">
									<div class="main-f1 main-over" onClick={tapHome.bind(this,item.uid)}>
										<div class="main-flex main-aic">
											<p class="fans_name">{ item.uinfo.nick }</p> 
											<div className={"main-flex main-aic fans_gender "+ (item.uinfo.gender == 1 ? "fans_boy" : "")}>
												<img src={item.uinfo.gender == 1 ? icon_boy : icon_girl} />
												<span className="fans_t1">{ item.uinfo.age }</span>
											</div>
											
										</div>
										<p class="main-ellipsis fans_sign">{ item.uinfo.sign }</p>
									</div> 
									<div class="main-tac fans_yes" onClick={tapCancelFollow.bind(this,item.uid)} >
										<img class="main-vat" src={f_02png} />
									</div>							
								</div>
							</li>)
						})
					}
					
					</Scroll>
				</ul> : <div className="main-flex main-aic main-jcc main-box-img">
					<img src={nullImg} />
					<p className="fans_sign">{t("nofocuson")}</p>
				</div>
			}
				
			</div>
			{
				popShow ? <TipsPop tips={t("followtxt")} tapBtn={Tagsend.bind(this)} delete={t("rechorge")}></TipsPop> : ""
			}
		</div>
	);
};

export default FollowPage; 