import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import f_bg_png from "../../img/live/f_bg.png";
import f_03_png from "../../img/live/f_03.png";
import defaultsimg from "../../img/guard/defaults.png";
import f_02_png from "../../img/live/f_02.png";
const FindCountry: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const countryList= props.list; 
	const tapLookArea=()=>{
		navigate("/page/live/find/area");
	}
	const detailOpen=(c)=>{
		navigate("/page/live/find/detail",{state:{code:c}});
	}
	return (
		<div className="find_pt">
			<div className="main-flex main-aic">
				<div className="main-f1">
					<img className="main-va" width="18" src={f_03_png} />
					<span className="main-inline main-va find_t2">{t("countrytxt")}</span>
				</div>
				<div className="">
					<span className="main-inline main-va login-vf-size" onClick={tapLookArea}>{t("gengduo")}</span>
					<img className="main-va" width="6" src={f_02_png} />
				</div>
			</div> 
			<ul className="find_pt">
			{
				countryList.map((item,index)=>{
					return (<li className="main-inline main-tac find_w" key={index}>
					<a className="main-block" onClick={detailOpen.bind(this,item.ctcode)}>
						<img className="main-w7 main-vat" src={item.img}/>
						<p className="find_t3">{item.country}</p>
					</a>
				</li>)
				})
			}
				
			</ul>
		</div>
	);
};

export default FindCountry; 