import React, { useEffect, useState }  from "react"; 
 
import img_room0 from "../../img/home/rooml0.png";
import img_room1 from "../../img/home/rooml1.png";
import img_room2 from "../../img/home/rooml2.png";
import img_room3 from "../../img/home/rooml3.png";
import img_room4 from "../../img/home/rooml4.png";
import img_room5 from "../../img/home/rooml5.png";
import img_room6 from "../../img/home/rooml6.png";
const RoomLevel: React.FunctionComponent = (props) => {  
	const roomlivel = props.grade; 
 
	return (
		<div className="info-level-con">
			{
				roomlivel==0 ? <img src={img_room0} className="img_leve" />: ""
			}
			{
				16 > roomlivel > 0 ? <img src={img_room1} className="img_leve" /> : ""
			}
			{
				31 > roomlivel > 15 ? <img src={img_room2} className="img_leve" /> : ""
			}
			{
				46 > roomlivel > 30 ? <img src={img_room3} className="img_leve" /> : ""
			}
			{
				61 > roomlivel > 45 ? <img src={img_room4} className="img_leve" /> : ""
			}
			{
				76 > roomlivel > 60 ? <img src={img_room5} className="img_leve" />  : ""
			}
			{
				roomlivel > 75 ? <img src={img_room6} className="img_leve" />  : ""
			}	
			<span className="main-inline info-level-font">{ roomlivel }</span>
		</div>
	);
};
export default RoomLevel; 