//交友列表 
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router"; 
import { Scroll } from "../../../../components/scroll/scroll.tsx";
import {friendlist} from "../../../../request/api.js";
import mlimg from "../../../../img/ml.png";
import icon_sex from "../../../../img/online/icon_sex.png";
import Stealth from "../../../../img/online/Stealth@2x.png";
import icon_boy from "../../../../img/home/icon_boy.png";
import icon_girl from "../../../../img/home/icon_girl.png";
import yonghu from "../../../../img/null/yonghu.png";
import defaultImg from "../../../../img/index/default.png";
let dataArr = [];
let listShow = true;
const FriendsList: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	let page = 1;
	const [list,setlist] = useState([]); 
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 46-46;
		setwHeight(Height);
		dataArr = [];
		friendlistAjax();
		
		
	},[]);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  friendlistAjax();
		  }
	    
	  }
	};
	const friendlistAjax=()=>{
		var darr = {
			pageno: page,
			pagenum: 20
		}
		friendlist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					listShow = true;
					let lists = res.data.items;
					if(lists.length<10){
						listShow = false;
					}
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				}
			}
		})
	}
	const homeOpen =(id,t)=>{
		 navigate("/page/home/index", { state: { uid: id,type: t } });
	}
	return (
		<div className="content-list friendPage" style={{ height: wHeight + "px" }}>
		
		{
			list.length>0 ? <div className="main-box slide_height online_chat_list friend-list"> 
			<Scroll
			  height="100%"
			  load={handleScroll}
			  isFirstLoad={false}
			>
			{
				list.map((item,index)=>{
					return (<div key={index} className="main-box online_chat_li" onClick={homeOpen.bind(this,item.uid,2)}>
						<div className="main-box main-relative">
						{
							item.ml==1 ? <img className="main-w10 main-vat online_ml" src={mlimg} /> : ""
						}
							
							<img  loading="lazy" className="main-w10 main-vat online_chat_img" src={item.avatar!='' ? item.avatar : defaultImg} onError={(e: any) => { e.target.src = defaultImg }}  />
						</div>
						<div className="main-box main-relative online_chat_content">
							<img  loading="lazy" className="online_chat_head" src={item.avatar!='' ? item.avatar : defaultImg}  onError={(e: any) => { e.target.src = defaultImg }} />
							<img className="online_chat_gender" src={item.online == 1 ? icon_sex : Stealth} /> 
							<p className="main-ellipsis online_chat_name">{ item.nick }</p>
							<div className="main-flex main-aic online_hg">
								<img width="12" src={item.gender == 1 ? icon_boy : icon_girl} />
								<span className="online_chat_age online_pd">{ item.age }</span>
								<p className="main-ellipsis online_chat_age">{ item.country }</p>
							</div>
						</div>
					</div>)
				})
			}
			 </Scroll>
			</div> : <div className="main-flex main-aic main-jcc main-box-img">
				<img src={yonghu} />
			</div>
		}
		
		</div>
	);
};

export default FriendsList; 