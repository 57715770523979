// 设置
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"; 
import {logout} from "../../../request/api.js";
import icon_back from "../../../img/icon_back.png";
import icon_03 from "../../../img/mine/icon_03.png"
const SettingPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr')); 
	}, []);
	const navBack=()=>{
		navigate(-1)
	}
	const navAbout=()=>{
		navigate("/page/setting/about");
	}
	const navLanguage=()=>{
		navigate("/page/setting/language");
	}
	const logoutTap=()=>{
		logout({}).then((res)=>{
			if(res.errno == 0){
				localStorage.clear(); 
				navigate("/")
			}
		})
	}
	return (
		<div className="setting">
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("setting")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="setting">
				<ul className="setting_list" >
					
					<li className=" index_pl setting_li">
						<div className="main-bb main-flex main-aic index_pr setting_content" onClick={navLanguage}>
							<p className="main-f1 setting_name">{t("langSetting")}</p>
							<img width="16" src={icon_03} />
						</div>
					</li>
					<li className=" index_pl setting_li">
						<div className="main-bb main-flex main-aic index_pr setting_content" onClick={navAbout}>
							<p className="main-f1 setting_name">{t("about")}</p>
							<img width="16" src={icon_03} />
						</div>
					</li>
				</ul> 
				<p onClick={logoutTap} className="main-bg main-tac setting_back">{t("logOut")}</p>
			</div>
		</div>
	);
};

export default SettingPage; 