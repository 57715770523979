// 设置密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router";
import {decode as base64_decode, encode as base64_encode} from 'base-64';  
import { regpost,tokenAjax,getaeskey } from "../request/api.js";
import Toast from "../components/toast/toast.js";

import icon_back from "../img/icon_back.png";
import no_see from "../img/no_see.png";
import see_img from "../img/see.png";

const Setpass: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();  
	const [countryCode, setcountryCode] = useState('86'); 
	const [mobile, setmobile] = useState(''); 
	const [password, setpassword] = useState('');  
	const [numshow, setnumshow] = useState(false); 
	const [aeskey, setaeskey] = useState('');  
  
	useEffect(() => {
		var loginCon = JSON.parse(window.localStorage.getItem('loginCon'));
		setmobile(loginCon.mobile);
		setcountryCode(loginCon.code); 
		aeskeyAjax();
	}, []);
	const navBack=()=>{
		navigate(-1)
	}
	const setshow=()=>{
		if(numshow){
			setnumshow(false);
		}else{
			setnumshow(true);
		} 
	}
	const checkPass=()=>{
		let reg = /^(?!\D+$)(?![^a-zA-Z]+$)\S{6,20}$/;
		if(reg.test(password)){
			let c = '';
			let code = JSON.parse(window.localStorage.getItem('code'));	
			for(let i=0;i<password.length;i++){
				let b = String.fromCharCode(password.charCodeAt(i)^aeskey.charCodeAt(i));
				c =c + b;
			}
			let pwd = base64_encode(c);
			let datarr = {
				pwd: pwd,
				pcode: code,
				aeskey: aeskey
			}
			regpost(datarr).then((res)=>{
				if(res.errno == 0){
					localStorage.setItem('userArr', JSON.stringify(res.data));
					if(res.data.gender == 0){
						navigate("/Editinfo")
						//location.replace('editinformation.html');
					}else{
						tokenLogin(res);
					}
				}else{
					Toast.fail(res.errmsg);
					tapEmpty();
					aeskeyAjax();
				}
			});
		}else{
			Toast.fail(t("dpAdd2"));
		}
	}
	const aeskeyAjax=()=>{
		getaeskey().then((res)=>{
			if(res.errno == 0){ 
				setaeskey(res.data)
			}else{ 
			}
		})
	}
	const tapEmpty=()=>{
		setpassword("");
	}
	const tokenLogin=(info)=>{
		tokenAjax({}).then((res)=>{
			if(res.errno == 0){
				var tokens = 'bl_time='+ res.data.bl_time +'&bl_token='+ res.data.bl_token+'&uid='+ res.data.uid; 
				localStorage.setItem('token', tokens);
				localStorage.setItem('rYToken', res.data.bl_token);
				let clickLogin = {
					countryCode: countryCode,
					mobile: mobile,
					password: password,
					nick: info.data.nick,
					avatar: info.data.avatar
				};
				localStorage.setItem('clickLogin', JSON.stringify(clickLogin));
				//location.replace('home.html');
				
			}else{
				 
				
			}
		})
	}
	return (
		<div className="main-box body-bg" >
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div">
					<div className="main-box head_lf"  onClick={navBack}>
						<img src={icon_back} className="head_back"/>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("dpAdd1")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="login-content">
				<div className="main-mob edit_li">
					<div className="login-title-text">{ t("index_setpass") }</div>
					<div className="login-text">+{ countryCode } { mobile } </div>
				</div>
				<div className="password-part">
				   
					<input className="input-background-color" type={numshow ? 'text' : 'password'} placeholder={t("loginpassword")} value={password} onChange={(e) => { setpassword(e.target.value); }} />
					{
						!numshow ? <img src={no_see} onClick={setshow}  alt=""/> :  <img src={see_img} onClick={setshow} alt=""/>
					}
				</div>
				<div className="login-text login-vf-size">{ t("dpAdd2") }</div>
				
				<div className="login-bottom">
				{
					password.length ? <div className="login-yes-font" onClick={checkPass}>{t("nextstep")}</div> : <div className="login-font">{t("nextstep")}</div>
				}
					
					
				
				</div>
			</div>
			<div className="footer-bei">Corpration © 2016-2023 Anycupid.All Rights Reserved</div>
		</div>
	)
};

export default Setpass; 