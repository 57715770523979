// 粉丝
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"; 
import {timestampToTime} from "../../../utils/utils.js";
import {fanslist,viewerlist} from "../../../request/api.js";
import Toast from "../../../components/toast/toast.js";
import { Scroll } from "../../../components/scroll/scroll.tsx";
import icon_back from "../../../img/icon_back.png";
import f_02png from "../../../img/online/f_02.png";
import icon_boy from "../../../img/home/icon_boy.png";
import icon_girl from "../../../img/home/icon_girl.png";
import defaultImg from "../../../img/index/default.png";
import bg3png from "../../../img/news/bg3.png";
import bg2png from "../../../img/news/bg2.png";
import b_01png from "../../../img/news/b_01.png"; 
import nullImg from "../../../img/giftd/visitor@2x.png";
let dataArr = [];
let listShow = true;
const VisitPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	let userId = "";
	let page = 1;
	const [visitorNum,setvisitorNum]=useState(0);
	const [vipCheck,setvipCheck]=useState(false);
	const [list,setlist]=useState([]);
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr'));
		userId = userArr.uid; 
		let Height = document.documentElement.clientHeight - 51;
		setwHeight(Height);
		dataArr = [];
		listAjax();
	}, []);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  listAjax();
		  }
	    
	  }
	};
	const listAjax=()=>{
		let darr = {
			pageno: page,
			pagenum: 30,
		}
		viewerlist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					}
					for(var i=0;i<lists.length;i++){
						lists[i].addtime = timestampToTime(lists[i].addtime);
					}
					setvisitorNum(res.data.total);
					setvipCheck(res.data.vipCheck);
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				} 
							
			}else{
				Toast.fail(res.errmsg);
			}
		})
		
	}
	const navBack=()=>{
		navigate(-1)
	}
	const tapHome=(uid)=>{
		navigate("/page/home/index", { state: { uid: uid,type: 2 } });
	}
	const jumpTo=()=>{
		//../../recharge/vipCenter.html
	}
	return (
		<div className="visit">
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("visit")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="page-height" style={{ height: wHeight + "px" }}>
				{
					vipCheck ? <div className="visit">
					{
						list.length>0 ? <ul className="main-box">
						<Scroll
						  height="100%"
						  load={handleScroll}
						  isFirstLoad={false}
						>
						{
							list.map((item,index)=>{
								return (<li className="main-relative" key={index}>
						 
								<div className="main-flex main-aic index_pl seen_li"onClick={tapHome.bind(this,item.uid)} > 
									<div className="main-relative fans_lf news_online news_unline">
										<img className="main-radius main-w10" src={item.uinfo.avatar} onError={(e: any) => { e.target.src = defaultImg }} />
									</div> 
									<div className="main-bb main-f1 main-flex main-aic index_pr fans_rt">
										<div className="main-f1">
											<div className="main-flex main-aic">
												<div className="main-f1 main-flex main-aic">
													<p className="fans_name">{ item.uinfo.nick }</p> 
													<div className={"main-flex main-aic fans_gender "+ (item.uinfo.gender == 1 ? "fans_boy" : "")}>
														<img src={item.uinfo.gender == 1 ? icon_boy : icon_girl} />
														<span className="fans_t1">{ item.uinfo.age }</span>
													</div>
													 
												</div>
												<p className="fans_sign">{ item.addtime }</p>
											</div>
											<p className="fans_sign">{ t("visitHome") }</p>
										</div>
									</div>
								</div>
								
							</li>)
							})
						}
							</Scroll>
						</ul> : <div className="main-flex main-aic main-jcc main-box-img">
					<img src={nullImg} />
					<p className="fans_sign">{t("notyetn")}</p>
				</div>
					}
						
					</div> : <div className="notice_not">
						<div className="main-tac notice_not_up">
							<div className="main-inline main-relative notice_not_content">
								<div className="main-radius notice_not_li">
									<div className="main-radius main-over main-bg">
										<img className="main-w10 main-radius" src={bg3png} />
									</div>
								</div>
								<div className="main-radius notice_not_li">
									<div className="main-radius main-over main-bg">
										<img className="main-w10 main-radius" src={bg2png} />
									</div>
								</div>
								<div className="main-radius notice_not_li">
									<div className="main-radius main-over main-bg">
										<img className="main-w10 main-radius notice_not_filter" src="http://alivod.xxshow.com/images/icon/100096935/2018087101902.png"/>
									</div>
								</div>
							</div>
							<p className="notice_not_t1">{ t("visitorFont1") } { visitorNum } { t("visitorFont2") }</p>
						</div>
						<div className="main-tac notice_not_down">
							<p className="notice_not_t2">{ t("lookAllVisitor") }</p>
							<div className="main-relative notice_not_btn" onClick={jumpTo}>
								<img className="main-w5 main-vat" src={b_01png} />
								<p className="main-w5 main-hg main-flex main-jcc main-aic main-pe">{ t("openMember") }</p>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	);
};

export default VisitPage; 