// 发现排行榜
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import f_bg_png from "../../img/live/f_bg.png";
import f_07_png from "../../img/live/f_07.png";
import defaultsimg from "../../img/guard/defaults.png";
import f_01_png from "../../img/live/f_01.png";
import Swiper from 'swiper'; 
import "swiper/css";
import "../../css/swiper.css";
let swiperBanner;
const FindBanner: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const rankList= props.list; 
	console.log("rankList",rankList)
	useEffect(() => {
		//setlist(props.list);
		
		setTimeout(function(){
			swiperBanner = new Swiper(".swiper-banner", {
						  slidesPerView: 1,
						  //speed : 4000, 
						  autoplay : {
							  delay : 4000, //自动切换的时间间隔，单位ms
							  disableOnInteraction : false //用户操作swiper之后，是否禁止autoplay
						  }, 
						  loop: true,
						  nested: true, 
						  direction: 'vertical',
						  spaceBetween: 10, //两个slide之间的距离 
						  autoHeight : true,
						  observer: true,
						  observeParents: true
			});
		},1000)
		  
	},[]);
	const rankOpen=(p)=>{
		navigate("/page/rank/list",{state:{rankid:p}});
	}
	return (
		<div className="swiper-container live_rotate swiper-banner">
			<div className="swiper-wrapper" style={{ height: '80px' }}>
			{
				rankList.map((item,index)=>{
					return (<div className="swiper-slide" key={index} onClick={rankOpen.bind(this,index)}>
						<div className="main-block main-relative">
							<img className="main-w10 main-vat live_img" src={f_bg_png} />
							<div className="main-w10 main-hg main-flex main-aic find_card">
								<div className="main-flex main-aic find_lf">
									<img width="20" src={f_07_png} />
									<span className="find_t1">{ item.name }</span>
								</div>
								<div className="main-f1 main-tar main-font">
								{
									item.items.map((items,indexs)=>{
										return (<div key={indexs} className={['main-inline','main-radius','main-over','main-relative',indexs==0 ? 'find_one': '',indexs==1 ? 'find_two': '' ,indexs==2 ? 'find_three': ''].join(' ')}>
												<img className="main-w10 main-vat" src={items.avatar}  onError={(e: any) => { e.target.src = defaultsimg }} />
											</div>
										)
									})
								}
									
								
								</div>
								<div className="find_rt">
									<img className="main-vat" width="30" src={f_01_png} />
								</div>
							</div>
						</div>
					</div>)
				})
			}
				
			</div>
		</div>
	);
};

export default FindBanner; 