// 守护我的
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {guardmylist} from "../../../request/api.js";
import {timestampToTime} from "../../../utils/utils.js";
import { Scroll } from "../../scroll/scroll.tsx";
import Toast from "../../toast/toast.js";
import GuardPop from "../../pop/guardpop.js";
import GradeLevel from "../../home/gradelevel.js";
import defaultImg from "../../../img/index/default.png";
import emptyImg from "../../../img/guard/empty.png";
import vipImg from "../../../img/guard/vip.png";
import svipImg from "../../../img/guard/svip.png";

let dataArr = [];
let listShow = true;
const GuardMy: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const userId = props.uid;
	const ucenterinfo = props.info
	let page = 1;
	const [list,setlist] = useState([]); 
	const [guardShow,setguardShow] = useState(false); 
	
	useEffect(() => {
		dataArr = [];
		listAjax();
	}, []);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  listAjax();
		  }
	    
	  }
	};
	const listAjax=()=>{
		let darr = {
			pageno: page,
			pagenum: 30,
		}
		guardmylist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					} 
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				} 
							
			}else{
				Toast.fail(res.errmsg);
			}
		})
		
	}
	
	const tapHome=(uid)=>{
		navigate("/page/home/index", { state: { uid: uid,type: 2 } });
	}
	const Tagsend=(type)=>{
		if (type == 2) {
		  
		} else {
		  setguardShow(false);
		}
	}
	const tapReopen=(uid)=>{
		setguardShow(true);
	}
	return (
		<div className="giard-index">
			{
				list.length>0 ? <ul className="index_me_list">
				{
					list.map((item,index)=>{
						return (<li className="main-relative index_me_li" key={index}>
                                    <div className="main-flex main-aic" onClick={tapHome.bind(this,item.uid)} >
                                        <img className="main-radius" width="48" height="48" src={item.uinfo.avatar} onError={(e: any) => { e.target.src = defaultImg }} alt="Head" />
                                        <div className="main-f1 index_me_rt">
                                            <div className="main-flex main-aic">
                                                <p className="index_me_name">{ item.uinfo.nick }</p>
												<GradeLevel grade={item.uinfo.level}></GradeLevel>
                                                
                                                <img width="15" src={item.gdtype == 'normal' ? vipImg : svipImg} alt="Icon" />
                                            </div> 
                                            <div className="main-flex main-aic index_me_types">
                                                <span>{ item.gdtype == 'normal' ? t("indexOrdinaryGuard") : t("indexSupremeGuard") }</span>
                                                
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="main-flex main-aic index_me_down"> 
                                       <p className="main-f1 index_me_day">{ item.expire == 0 ? item.leftday : '' }{ item.expire == 0 ? t("indexSurplus") : '' }</p>
                                        {
											item.expire == 0 ? <p className="index_btn1" onClick={tapReopen.bind(this,item.uid)}>{ t("indexRenew") }</p> : ""
										}
                                        {
											item.expire == 1 ? <p className="index_btn2" onClick={tapReopen.bind(this,item.uid)}>{ t("indexReopen") }</p> : ""
										}
                                       
                                        
                                    </div> 
                                    <div className="main-w10 index_me_border"><p></p></div>
                                </li>)
						})
				}
				</ul> : <div className="main-tac index_empty">
                                <img className="main-vat" width="128" src={emptyImg} alt="Empty" />
                                <p>{t("indexMeEmpty")}</p>
                            </div>
			}
			{
				guardShow ?  <GuardPop uid={userId} info={ucenterinfo} tapBtn={Tagsend.bind(this)}></GuardPop> : ""
			}
		</div>
	);
};

export default GuardMy; 