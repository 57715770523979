import i18n from "i18next";
import { initReactI18next } from "react-i18next"; 
import zh from "./lang/lang-zh.json";
import vi from "./lang/lang-vi.json";
import en from "./lang/lang-en.json";
import es from "./lang/lang-es.json";
import fr from "./lang/lang-fr.json";
import ja from "./lang/lang-ja.json";
import ko from "./lang/lang-ko.json";
import pt from "./lang/lang-pt.json";
import ru from "./lang/lang-ru.json";
import de from "./lang/lang-de.json";
import ar from "./lang/lang-ar.json";
import zhtw from "./lang/lang-zhtw.json"; 

// 获取本地存储预览、浏览器默认选中语言
const lang = 'zh';
localStorage.setItem("lang", lang);
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      vi: {
        translation: vi,
      },
      en: {
        translation: en,
      },
      zh: {
        translation: zh,
      },
	  es: {
	    translation: es,
	  },
	  fr: {
	    translation: fr,
	  },
	  ja: {
	    translation: ja,
	  },
	  ko: {
	    translation: ko,
	  },
	  pt: {
	    translation: pt,
	  },
	  ru: {
	    translation: ru,
	  },
	  de: {
	    translation: de,
	  },
	  ar: {
	    translation: ar,
	  },
	  zhtw: {
	    translation: zhtw,
	  },
    },
    lng: lang, // if you're using a language detector, do not define the lng option

    debug: true,
    // fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
