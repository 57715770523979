//首页
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router"; 
//引入页面
import VideoPage from "./video/index";
import LivePage from "./live/index";
import FriendsPage from "./friends/index";
import ChatPage from "./chat/index";
import CentrePage from "./centre/index";
//引入css
import "../../css/index.css"; 
let swiperIndex;
const IndexPage: React.FunctionComponent = () => { 
	const [selectedTab,setselectedTab] = useState(2);
	const [newsNum,setnewsNum] = useState(0);
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => {
		if(window.localStorage.getItem('appTab')){
			var tab = window.localStorage.getItem('appTab');
			setselectedTab(tab);
		}
		let Height = document.documentElement.clientHeight - 46;
		setwHeight(Height);
		  
	},[]);
	const menuOpen=(p)=>{ 
		setselectedTab(p);
		localStorage.setItem('appTab', p);
	}
	return (
		<div className="page">
			<div className="main-box Xmas-swiper-content" style={{ height: wHeight + "px" }}>
				{
					selectedTab==0 ? <VideoPage/> : ""
				}
				{
					selectedTab==1 ? <FriendsPage/> : ""
				}
				{
					selectedTab==2 ? <LivePage/> : ""
				}
				{
					selectedTab==3 ? <ChatPage/> : ""
				}
				{
					selectedTab==4 ? <CentrePage/> : ""
				}
			</div>
			
			<div className="footer">
				<div onClick={menuOpen.bind(this,0)} className="header-left header-wap-width">
				{
					selectedTab==0 ? <div className="menu-icon">
							<img src={require("../../img/menu-video.gif")} className="main-icon-img" />
						</div> : <div className="menu-icon">
							<img src={require("../../img/main_short_video.png")} className="main-icon-img" />
						</div>
				}
					
						
				</div>
				<div onClick={menuOpen.bind(this,1)} className="header-left header-wap-width">
				{
					selectedTab==1 ? <div className="menu-icon">
							<img src={require("../../img/menu-discovery.gif")} className="main-icon-img" />
						</div> : <div className="menu-icon">
							<img src={require("../../img/main_discovery.png")} className="main-icon-img" />
						</div>
				}
					
						
				</div>
				<div onClick={menuOpen.bind(this,2)} className="header-left header-wap-width">
				{
					selectedTab==2 ? <div className="menu-icon">
							<img src={require("../../img/menu-live.gif")}  className="main-icon-img" />
						</div> : <div className="menu-icon">
							<img src={require("../../img/main_live.png")} className="main-icon-img" />
						</div>
				}
						
						
				</div>
				<div onClick={menuOpen.bind(this,3)} className="header-left header-wap-width">
				{
					newsNum!==0 ? <div className="chat-num">{ newsNum }</div> : ""
				}
						
						{
							selectedTab==3 ? <div className="menu-icon">
							<img src={require("../../img/menu-chat.gif")}  className="main-icon-img" />
						</div> : <div className="menu-icon">
							<img src={require("../../img/main_chat.png")} className="main-icon-img" />
						</div>
						}
						
						
				</div>
				<div onClick={menuOpen.bind(this,4)} className="header-left header-wap-width" >
						{
							selectedTab==4 ? <div className="menu-icon">
							<img src={require("../../img/menu-personal.gif")}  className="main-icon-img" />
						</div> : <div className="menu-icon">
							<img src={require("../../img/main_personal.png")} className="main-icon-img" />
						</div>
						}
						
						
				</div>
			</div>
		</div>
	);
};

export default IndexPage; 