// 语言设置
import React, { useEffect, useState }  from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"; 
import icon_back from "../../../img/icon_back.png";
import icon_03 from "../../../img/mine/icon_03.png";
import h_10png from "../../../img/mine/h_10.png";
const LanguagePage: React.FunctionComponent = () => { 
	const { t, i18n } = useTranslation();
	const navigate = useNavigate(); 
	const [lang,setlang]= useState(i18n.language); 
 
	const langList = [
			{ id: '1', name: '简体中文', ab: 'zh' },
			{ id: '2', name: 'Русский', ab: 'ru' },
			{ id: '3', name: 'Tiếng Việt', ab: 'vi' },
			{ id: '4', name: 'English', ab: 'en' },
			{ id: '6', name: '한국어 ', ab: 'ko' },
			{ id: '7', name: '繁体中文', ab: 'zhtw' },
			{ id: '8', name: 'Español', ab: 'es' },
			{ id: '9', name: 'Português', ab: 'pt' },
			{ id: '10', name: 'Français', ab: 'fr' },
			{ id: '11', name: 'Deutsch', ab: 'de' },
			
		];
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr')); 
	}, []);
	const navBack=()=>{
		navigate(-1)
	}
	const tapItemLang=(ab)=>{
		i18n.changeLanguage(ab);
		setlang(ab);
	}
	return (
		<div className="Language">
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("langSetting")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<ul className="index_pt">
			{
				langList.map((item,index)=>{
					return (<li className=" main-flex main-aic main-bb index_pl index_pr setting_content" key={index} onClick={tapItemLang.bind(this,item.ab)}>
						<p className="main-f1 setting_name">{ item.name }</p>
						{
							lang == item.ab ? <img width="15" src={h_10png} /> : ""
						}
						
					</li>)
				})
			}
				
			</ul>
		</div>
	);
};

export default LanguagePage; 