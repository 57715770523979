//编辑个人信息
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {infoedit } from "../../../../request/api.js";
import Toast from "../../../../components/toast/toast.js";
import icon_back from "../../../../img/icon_back.png";
import icon_right from "../../../../img/home/icon_right.png";
import icon_del from "../../../../img/mine/icon_del.png"
const LanguageEdit: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();  
	const location = useLocation();
	const [lg,setlg] = useState(""); 
	useEffect(() => {
		//let userArr = JSON.parse(localStorage.getItem('userArr'));
		setlg(location.state.con);
		console.log("location.state.con:",location.state.con)
		
	},[]);
	const completeOpen=(code)=>{
		var darr = {
			language: code,
		}
		infoedit(darr).then((res)=>{
			
			if(res.errno == 0){
				Toast.fail(t("tianjiaok"))
				navigate(-1); 
			}else{
				Toast.fail(t("loadFail"))
				 
			}
		})
	}
	
	 
	return (
		<div className="edit-page">
			<header className="header">
				<div className="main-box main-flex main-aic head" >
					<div className="main-box head_lf">
						<div onClick={() => navigate(-1)}><img src={icon_back} className="head_back"/></div>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("editLanguage")}</div>
					<div className="main-box head_rt" ></div>
				</div>
			</header>
			<div className="main-bg index_pl index_mt">
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'zh')}>中文简体</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'zhtw')}>中文繁体</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'en')}>English</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'vi')}>Tiếng Việt</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'ja')}>日本語</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'ko')}>한국의</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'de')}>Deutsch</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'es')}>Español</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'fr')}>Français</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'it')}>Italiano</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'nl')}>Nederlands</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'pt')}>Português</p> 
				<p className="main-bb index_pr edit_feel" onClick={completeOpen.bind(this,'ru')}>Русский</p> 
			</div>
			
			<div className="main-bg main-flex main-aic index_mt index_pl index_pr edit_nick">
				<p className="main-f1 edit_t1">{t("other")}</p>
				<img className="edit_icon" src={icon_right} />
			</div>
		</div>
	);
};

export default LanguageEdit; 