//交友列表 
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router"; 
import {dynamiclist} from "../../../../request/api.js";
import {timestampToTime} from "../../../../utils/utils.js";
import { Scroll } from "../../../../components/scroll/scroll.tsx";
import mlimg from "../../../../img/ml.png";
import Noticelike1 from "../../../../img/online/Noticelike1.png";
import icon_zan from "../../../../img/online/icon_zan.png";
import icon_ping from "../../../../img/online/icon_ping.png"; 
import defaultImg from "../../../../img/index/default.png";
import imgNull from "../../../../img/imgNull.png";
import dynamic from "../../../../img/null/dynamic@2x.png";
let dataArr = [];
let listShow = true;
const DynamicList: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	let page = 1;
	const [list,setlist] = useState([]); 
	const [wHeight,setwHeight] = useState(0);
	const [dimgw,setdimgw] = useState(0);
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 46-46;
		setwHeight(Height);
		let width = document.documentElement.clientWidth;
		let w= (width-20)*0.31;
		setdimgw(w);
		dataArr = [];
		dynamiclistAjax();
		
	},[]);
	const handleScroll  = () => {
	  console.log("resourcePage");
	  if (dataArr.length > 9) {
		  if(listShow){
			  page = page + 1;
			  dynamiclistAjax();
		  }
	    
	  }
	};
	const dynamiclistAjax=()=>{
		console.log("dynamiclistAjax")
		var darr = {
			pageno: page,
			pagenum: 20
		}
		dynamiclist(darr).then((res)=>{ 
			if(res.errno == 0) {
				var dylist = res.data.items;
				console.log("dynamiclistAjax",dylist)
				if(dylist.length > 0){
					listShow = true;
					if(dylist.length<10){
						listShow = false;
					}
					for(var a = 0; a < dylist.length; a++) {
						dylist[a].addtime = timestampToTime(dylist[a].addtime); 
					}
					dataArr = dataArr.concat(dylist);
					console.log("dynamiclist",dataArr);
					setlist(dataArr);
				}else{
					listShow = false;
				}
				
				
				
			}
		})
	}
	const homeOpen=(id,t)=>{
		navigate("/page/home/index", { state: { uid: id,type: t } });
	}
	const tapobject=(id,k)=>{
		
	}
	const tapLookTranslate=(key)=>{
		
	}
	const tapLangSetting=()=>{
		
	}
	const tapSnap=(id,k)=>{
		
	}
	return (
		<div className="content-list friendPage" style={{ height: wHeight + "px" }}>
		{
			list.length>0 ? <ul id="increaseHeight" className="main-box slide_height online_dynam_list" >
			<Scroll
			  height="100%"
			  load={handleScroll}
			  isFirstLoad={false}
			>
			{
				list.map((item,index)=>{
					return (<li className="main-box online_dynam_li dynam-bg" key={index}>
					<div className="main-box online_dynam_content"> 
						<div onClick={homeOpen.bind(this,item.uid,2)} className="main-block">
							<div className="main-box main-flex main-aic main-relative">
								
								<img className="online_dynam_head" src={item.uinfo.avatar=='' ? defaultImg : item.uinfo.avatar }  onError={(e: any) => { e.target.src = defaultImg }} />
								<div className="main-box main-f1 online_dynam_rt" >
									<p className="main-box online_dynam_name">{ item.uinfo.nick }</p>
									<p className="main-box online_dynam_time">{ item.addtime }</p>
								</div>
								
							</div>
						</div> 
						<div className="main-box" onClick={tapobject.bind(this,item.id,index)}>
							<p className="main-box online_dynam_t1" >{ item.text }</p>
							{
								item.text ? <p className="main-box online_dynam_t2" >{ item.translate }</p> : ""
							}
							{
								item.text ? <div v-show="item.text" className="main-box"> 
								{
									item.isTranslate == false ? <div className="main-box online_dynam_look">
									<span onClick={tapLookTranslate.bind(this,index)}>{ t("lookTranslation") }</span>
								</div>  : ""
								}
								{
									item.isTranslate ? <div className="main-box online_dynam_look">
									<span onClick={tapLangSetting}>{t("langSetting")}</span>
								</div> : ""
								}
								
								
							</div>  : ""
							}
							
							{
								item.img.length == 1 ? <div className="main-box online_dynam_img" >
								{
									item.img.map((itemimg,index1)=>{
										return (<img key={index1} className="main-vat dy-img" src={itemimg.simg=='' ? imgNull : itemimg.simg}  onError={(e: any) => { e.target.src = imgNull }} />)
									})
								}
								
							</div> : ""
							}
							{
								item.img.length == 2 ? <div className="main-box online_dynam_img">
								{
									item.img.map((itemimg,index2)=>{
										return (<div key={index2} className="img-left dy-img"  style={{ height: dimgw + "px" }}>
									<img className="main-vat" src={itemimg.simg=='' ? imgNull : itemimg.simg}  onError={(e: any) => { e.target.src = imgNull }} />
								</div>)
									})
								}
								
							</div> : ""
							}
							{
								item.img.length !=1 && item.img.length != 2 ? <div className="main-box online_dynam_img">
								{
									item.img.map((itemimg,index3)=>{
										return (<div key={index3} className="img-left dy-img" style={{ height: dimgw + "px" }}>
									<img className="main-vat" src={itemimg.simg=='' ? imgNull : itemimg.simg}  onError={(e: any) => { e.target.src = imgNull }} />
								</div>)
									})
								}
								
							</div> : ""
							}
							
							
						</div>
						 
						<div className="main-box main-flex main-aic online_dynam_btns">
							<div className="main-box main-f1 main-flex">
							{
								item.like == 1 ? <div className="main-box online_dynam_zan" >
									<img  className="main-va" src={Noticelike1} />
									<span className="main-va">{ item.likenum }</span>
								</div> : <div className="main-box online_dynam_zan" onClick={tapSnap.bind(this,item.id,index)}>
									<img className="main-va" src={icon_zan} />
									{
										item.likenum!=0 ? <span className="main-va">{ item.likenum }</span> : ""
									}
									
								</div>
							}
								
								
								<div className="main-box online_dynam_ping" onClick={tapobject.bind(this,item.id,index)}>
									<img className="main-va" src={icon_ping} />
									{
										item.comnum!=0 ? <span  className="main-va">{ item.comnum }</span> : ""
									}
									
								</div>
							</div>
						</div>
					</div>
				</li>)
				})
			}
				</Scroll>
			</ul> : <div className="main-flex main-aic main-jcc main-box-img">
				<img src={dynamic} />
			</div>
		}
			
		</div>
	);
};

export default DynamicList; 