import { PubSub } from 'pubsub-js';
import i18n from "i18next";

let websocket,
  lockReconnect = false;
let createWebSocket = (url,wsuuid,guid,wsSign,wsTs,channel) => {
  websocket = new WebSocket(url);
	  var authPack = {
		"type": "up",
		"action": "auth",
		"body": "{\"userid\":"+wsuuid+",\"deviceid\":\""+ guid +"\",\"sign\":\""+wsSign+"\",\"ts\":"+wsTs+"}"
	  };
	  
	  var heartPack = {
		  "type": "up",
		  "action": "ping",
		  "body": "{\"userid\":"+wsuuid+"}"
	  };
	  var STR_ENTER_ROOM ='{"type":"up","action":"intoroom","body":"'+ channel +'"}';//进入房间
	  
    console.log('ws connect')
  websocket.onopen = function() { 
    websocket.send(JSON.stringify(authPack));
    websocket.send(STR_ENTER_ROOM);
    heartCheck.reset().start();
  };
  websocket.onerror = function() {
    reconnect(url,wsuuid,guid,wsSign,wsTs,channel);
  };
  websocket.onclose = function(e) {
    heartCheck.reset()
    console.log('ws closed: ' + e.code + ' ' + e.reason + ' ' + e.wasClean);
  };
  websocket.onmessage = function(event) {
    lockReconnect = true;
    //event 为服务端传输的消息，在这里可以处理
    let data=event.data;//把获取到的消息处理成字典，方便后期使用
    PubSub.publish('message',data); //发布接收到的消息 'message' 为发布消息的名称，data 为发布的消息
  
  }; 
};
let reconnect = (url,wsuuid,guid,wsSign,wsTs,channel) => {
  if (lockReconnect) return;
  // 没连接上会一直重连，设置延迟避免请求过多
  setTimeout(function() {
    createWebSocket(url,wsuuid,guid,wsSign,wsTs,channel);
    lockReconnect = false;
  }, 4000);
};
let heartCheck = {
  timeout: 6000, // 6秒
  timeoutObj: null,
  reset: function() {
    clearInterval(this.timeoutObj);
    return this;
  },
  start: function() {
    this.timeoutObj = setInterval(function() {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      // onmessage拿到返回的心跳就说明连接正常
      websocket.send(JSON.stringify({type:"up",action:"ping",body:"1"}));
    }, this.timeout);
  }
};
// 关闭连接
let closeWebSocket = (channel,visitorId) => {
	let lang = i18n.language;
	var STR_LEAVE_ROOM = "{type:'LLEAVE',channel:'"+ channel +"',language:'"+ lang +"',userid:'"+ visitorId +"'}";//离开房间
	websocket.send(STR_LEAVE_ROOM);
	websocket && websocket.close();
};
export { websocket, createWebSocket, closeWebSocket };