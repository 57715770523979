//短视频
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router"; 
import { videolist,likecheck,likeadd,likedel } from "../../../request/api.js";
import Swiper from 'swiper/bundle'; 
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "swiper/css/bundle";
import "./video.css";
import liveonline from "../../../img/liveonline.png";
import like_yes from "../../../img/like-yes.png";
import like_no from "../../../img/like-no.png";
import like_hi from "../../../img/hi.png";
import defaultImg from "../../../img/index/default.png";

let dataArr = [];
let listShow = true;
var swiperVideo;
const VideoPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	let page = 1;
	let current = 0;
	const [wHeight,setwHeight] = useState(0);
	const [wWidth,setwWidth] = useState(0);
	const [videoArr,setvideoArr] = useState([]); 
	
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 46;
		let width = document.documentElement.clientWidth;
		setwHeight(Height);
		setwWidth(width);
		videolistAjax();
		setTimeout(function(){
			var vid = dataArr[current].id;
			let idi="#video_"+vid;
			document.querySelector(idi).play();
			swiperVideo = new Swiper('.swiper-video',{
			  direction: 'vertical',
			  slidesPerView :1,
			  mousewheel:true,
			  
			  observer: true,
			  observeParents:true,
			  on:{
				//上滑
				slideNextTransitionStart:function(){
				  current++;
				  
				  var vid = dataArr[current].id; 
				  var nu=current+1; 
				  var yvid = dataArr[current-1].id; 
				  let idy = "#video_"+yvid;
				  document.querySelector(idy).pause();
				  document.querySelector(idy).currentTime=0;
				  let idi="#video_"+vid; 
				  document.querySelector(idi).play();
				
				  if(nu+1==dataArr.length){//下一条就是最后一条
				    page++;
				    videolistAjax();
				  }
				},
				//下滑
				slidePrevTransitionStart:function(){
				  current--;
				  var vid = dataArr[current]['id'];
				  let nu=current+1
				  var yvid = dataArr[current+1].id;
				  let idy = "#video_"+yvid;
				  document.querySelector(idy).pause();
				  document.querySelector(idy).currentTime=0;
				 let idi="#video_"+vid;
				 document.querySelector(idi).play();
				  
				}
			  }
			})
		},1000);
		
	},[]);
	const likecheckAjax=(id)=>{
		let darrinfo = {
			vid: id
		}
		likecheck(darrinfo).then((res)=>{
			return res.data;
		})
	}
	const videolistAjax=()=>{
		let darrinfo = {
			ctcode: 'PH',
			pageno: page,
			pagenum: 10
		}
		videolist(darrinfo).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					}
					for(var i=0;i>lists.length;i++){
						let likeYes = likecheckAjax(lists[i].id);
						lists[i].likeYes = likeYes;
					}
					dataArr = dataArr.concat(lists); 
					setvideoArr(dataArr);
				}else{
					listShow = false;
				}
			}
		})
	}
	 
	const likedelAjax=(id)=>{
		let darrinfo = {
			vid: id
		}
		likedel(darrinfo).then((res)=>{
			if(res.errno == 0) {
				for(var i=0;i>dataArr.length;i++){
					if(id == dataArr[i].id){
						dataArr[i].likeYes = res.data;
					}
				} 
				setvideoArr(dataArr);
			}
		})
	}
	const likeOpenAjax=(id)=>{
		let darrinfo = {
			vid: id
		}
		likeadd(darrinfo).then((res)=>{
			if(res.errno == 0) {
				for(var i=0;i>dataArr.length;i++){
					if(id == dataArr[i].id){
						dataArr[i].likeYes = false;
					}
				} 
				setvideoArr(dataArr);
			}
		})
	}
	const homeOpen =(id,t,k)=>{
		if(k==1){
			
		}else{
			navigate("/page/home/index", { state: { uid: id,type: t } });
		}
		 
	}
	return (
	<div className="video-bg" style={{ height: wHeight + "px" }}>
		<div className="main-box main-flex main-aic online_tab">
			<div className="main-box main-f1 main-flex main-hg">
				<div className="main-box main-relative online_chat">
					<span className="online_titles online_currents" >{ t("recommend") }</span>
					<div className="main-w10 main-tac online_xians">
						<b className="main-inline"></b>
					</div>
				</div>
			</div>
		</div>
		<div className="main-box video-content" style={{ height: wHeight + "px" }}>
			<div className="swiper-container swiper-video" style={{ height: wHeight + "px" }}>
				<div className="swiper-wrapper" style={{ height: wHeight + "px" }}>
				{
					videoArr.map((item,index)=>{
						return (<div key={index} className="swiper-slide">
						<div className="video_show">
							<video id={"video_"+item.id} className="video_box"
								src={item.video} 
								loop
								muted
								webkit-playsinline="true"
								x5-playsinline
								x5-video-player-type="h5"
								x5-video-player-fullscreen="true"
								playsinline="true"
								x-webkit-airplay="allow"
								x5-video-orientation="portrait" 
								preload="auto"
								poster={item.minfo.mlcover}
								style={{ height: wHeight + "px" }}
								>
							</video>
						</div>
						<div className="pabs video_msg">
							<div className="video-avatar">
							{
								item.uinfo.online==1 ? <img src={liveonline} className="live-online" /> : ""
							}
								
								<img onClick={homeOpen.bind(this,item.uinfo.uid,2,item.uinfo.online)} src={item.uinfo.avatar!="" ? item.uinfo.avatar : defaultImg}  onError={(e: any) => { e.target.src = defaultImg }} className="video-avatar-img" />
							</div>
							<div className="video-zan">
								{
									item.likeYes ? <img onClick={likedelAjax.bind(this,item.id)} src={like_yes} /> : <img onClick={likeOpenAjax.bind(this,item.id)} src={like_no} />
								}
								
								
								<div className="like-num">{ item.likenum }</div>
							</div>
							<div className="video-zan"  >
								<img src={like_hi} />
							</div>
						</div>
					</div>)
					})
				}
					
				</div>
			</div>
		</div>
		
	</div>
		
	);
};

export default VideoPage; 