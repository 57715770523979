//我的账单
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {balance} from "../../../request/api.js";
import icon_back from "../../../img/icon_back.png";
import r_bg from "../../../img/mine/r_bg.png";
import r_03 from "../../../img/mine/r_03.png";
import r_05 from "../../../img/mine/r_05.png";
import paylogo from "../../../img/mine/paylogo.png";

const AccountPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const location = useLocation(); 
	//let userId = location.state.uid;
	const [money,setmoney] = useState(0);
	useEffect(() => {
		balanceAjax(); 
	 
	},[]);
	const balanceAjax=()=>{
		balance({}).then((res)=>{
			if(res.errno == 0) {
				setmoney(res.data); 
			}
		})
	}
	const rechargeOpen=()=>{
		
	}
	return (
		<div>
			<header id="header">
				<div className="main-box main-flex main-aic head head-transparent"  >
					<div className="main-box head_lf" >
						<a  onClick={() => navigate(-1)}><img src={icon_back} className="head_back" /></a>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{ t("myAccount") }</div>
					<div className="main-box head_rt"  ></div>
				</div>
			</header>
			<div className="recharge">
				<div className="main-relative">
					<img className="main-w10 main-vat" src={r_bg} />
					<div className="main-w10 main-hg recharge_top">
						<p className="main-tac recharge_t1">{t("iHave")}</p>
						<div className="main-tac recharge_pt">
							<span className="main-va recharge_num">{ money }</span>
							<img className="main-va" width="28" src={r_03}/>
						</div>
					</div>
				</div>
				<div className="recharge_down">
					<p className="recharge_t2">{t("selectRecharge")}</p>
					<ul className="recharge_list">
						<li className="main-flex main-aic recharge_li" onClick={rechargeOpen}>
							<img className="recharge_icon" width="40" src={paylogo} />
							<div className="main-hg main-f1 main-flex main-aic recharge_item">
								<p className="main-f1">{t("goldrecharge")}</p>
								<img width="7" src={r_05} />
							</div>
						</li>
					</ul> 
				</div>
			</div>
		</div>
	);
};

export default AccountPage; 