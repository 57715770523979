// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"; 
import {timestampToTime,timeToMinute} from "../../../utils/utils.js";
import {rankprofit,anchorlivelist} from "../../../request/api.js";
import Toast from "../../../components/toast/toast.js";
import { Scroll } from "../../../components/scroll/scroll.tsx";

import icon_back from "../../../img/icon_back.png";
import r_07_png from "../../../img/mine/r_07.png";
import r_06_png from "../../../img/mine/r_06.png";
import r_01_png from "../../../img/mine/r_01.png";
import r_04_png from "../../../img/mine/r_04.png";
import videono from "../../../img/videono.png"
let dataArr = [];
let listShow = true;
const MyLivePage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	let page = 1;
	const [profitList,setprofitList]=useState({});
	const [list,setlist]=useState([]);
	const [wHeight,setwHeight]=useState(0);
	useEffect(() => { 
		let Height = document.documentElement.clientHeight - 51;
		setwHeight(Height);
		rankprofitAjax();
		dataArr = [];
		listAjax();
	}, []);
	const rankprofitAjax=()=>{
		rankprofit({}).then((res)=>{
			if(res.errno == 0) {
				setprofitList(res.data); 
			}
		})
	}
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  listAjax();
		  }
	    
	  }
	};
	const listAjax=()=>{
		let darr = {
			pageno: page,
			pagenum: 30,
		}
		anchorlivelist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					}
					for(var i=0;i<lists.length;i++){ 
						lists[i].lengthtime = timeToMinute(lists[i].endtime - lists[i].starttime);
						lists[i].starttime = timestampToTime(lists[i].starttime);
					}
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				} 
							
			}else{
				Toast.fail(res.errmsg);
			}
		})
	}
	const navBack=()=>{
		navigate(-1)
	}
	return (
		<div>
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("myLive")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="tv"> 
				<div className="main-flex index_pl index_pr">
					<div className="main-f1 main-relative">
						<img className="main-w10" src={r_07_png}/>
						<div className="main-w10 main-hg tv_content">
							<div className="main-flex main-aic main-jcc tv_title">
								<p className="main-ellipsis">{t("liveDuration")}</p>
							</div>
							<p className="main-flex main-aic main-jcc tv_t1">{ profitList.playtime }</p>
						</div>
					</div>
					<div className="main-f1 main-relative">
						<img className="main-w10" src={r_06_png}/>
						<div className="main-w10 main-hg tv_content">
							<div className="main-flex main-aic main-jcc tv_title">
								<p className="main-ellipsis">{t("liveRevenue")}</p>
							</div>
							<div className="main-flex main-aic main-jcc tv_hg">
								<p className="tv_t2">{ profitList.roomgift }</p>
								<img width="20" src={r_01_png}/>
							</div>
						</div>
					</div>
				</div>
				 {
					 list.length>0 ? <ul className="index_pl">
					 {
						 list.map((item,index)=>{
							 return (<li className="main-bb index_pr tv_li" key={index}>
						<div className="main-flex main-aic tv_up">
							<p className="main-f1 tv_t3">{ item.title }</p>
							<span className="tv_t4">{ item.lengthtime }</span>
						</div>
						<div className="main-flex main-aic tv_down">
							<div className="main-f1">
								<img className="main-va" width="13" src={r_04_png} />
								<span className="main-va tv_t5">{ item.starttime }</span>
							</div>
							<span className="tv_t5">{t("liveDurations")}</span>
						</div>
					</li>)
						 })
					 }
					
				</ul> : <div className="main-box-img">
					<img src={videono} />
					<p>{t("videoList")}</p>
				</div>
				 }
				
				
			</div>
		</div>
	);
};

export default MyLivePage; 