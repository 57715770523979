// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";

import HomeInfo from "./tab/tabinfo.js";
import HomeDynamic from "./tab/dynamic.js";
import HomeGuard from "./tab/guard.js";

import Swiper from 'swiper'; 
import "swiper/css";
import "../../css/swiper.css";

var swiperHome;
const HomeTab: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userId = props.uid;
	const type= props.type;
	const ucenterinfo = props.info;
	const userMinfo = props.Minfo;
	const roominfo = props.Rinfo;
	const giftlist = props.gift;
	const followList = props.follow;
	const [currentTab,setcurrentTab] = useState(0);
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => { 
		let Height = document.documentElement.clientHeight - 46 - 50;
		setwHeight(Height);
		swiperHome = new Swiper(".swiper-home", { 
		  slidesPerView: 'auto', //同时显示的个数
		  //width: 80,
		  spaceBetween: 15, //两个slide之间的距离
		  freeMode: true,
		  autoHeight: true,
		  observer: true,
		  observeParents: true,
		  onTransitionEnd: function(swiper) { 
			
			setcurrentTab(swiper.activeIndex + 1)
		  } 
		});
	},[]);
	const tapTabItem=(t)=>{
		swiperHome.slideTo(t);
		setcurrentTab(t)
	}
	return (
		<div className="main-box home_pb">
			<div className="main-box main-flex main-aic home_tab_top">
				<div className="main-box main-relative home_tab_li" onClick={tapTabItem.bind(this,0)}>
					<span className={"home_tab_item "+ (currentTab == 0 ? 'home_tab_active' : '')}>{t("tabHomeList1")}</span>
					{
						currentTab == 0 ? <div className="main-box main-tac home_tab_xian"><b></b></div> : ""
					}
					
				</div>
				<div className="main-box main-relative home_tab_li" onClick={tapTabItem.bind(this,1)}>
					<span className={"home_tab_item "+ (currentTab == 1 ? 'home_tab_active' : '')}>{t("tabHomeList2")}</span>
					{
						currentTab == 1 ? <div className="main-box main-tac home_tab_xian"><b></b></div> : ""
					}
				</div>
				<div className="main-box main-relative home_tab_li" onClick={tapTabItem.bind(this,2)}>
					<span className={"home_tab_item "+ (currentTab == 2 ? 'home_tab_active' : '')}>{t("indexTitle")}</span>
					{
						currentTab == 2 ? <div className="main-box main-tac home_tab_xian"><b></b></div> : ""
					}
				</div>
			</div>
			<div className="main-box Xmas-swiper-content">
				
				<div className="swiper-container swiper-home swiper-width" style={{ height: wHeight + "px" }}>
					<div className="swiper-wrapper">
						<div className='swiper-slide'>
							<HomeInfo type={type} info={ucenterinfo} Minfo={userMinfo} Rinfo={roominfo} gift={giftlist} follow={followList}></HomeInfo>
						</div>
						<div className='swiper-slide'>
							<HomeDynamic uid={userId}  info={ucenterinfo} type={type}></HomeDynamic>
						</div>
						<div className='swiper-slide'>
							<HomeGuard  uid={userId}  info={ucenterinfo} type={type}></HomeGuard>
						</div>
					</div>
				</div> 
			</div>
			
		</div>
	);
};

export default HomeTab; 