//个人主页
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";

import AvatarList from "../../../components/home/avatarlist.js";
import GradeLevel from "../../../components/home/gradelevel.js";
import RoomLevel from "../../../components/home/roomlevel.js";
import HomeTab from "../../../components/home/hometab.js";
import GuardPop from "../../../components/pop/guardpop.js";

import {hoemInfo,guardInfo} from "../../../request/api.js";
import icon_back from "../../../img/icon_back.png";
import defaultImg from "../../../img/index/default.png";
import icon_edit from "../../../img/home/icon_edit.png";
import vipsmall from "../../../img/vip/vipsmall@2x.png";
import svipsmall from "../../../img/vip/svipsmall@2x.png";
import Guardian1 from "../../../img/home/Guardian1.png";
import Guardian2 from "../../../img/home/Guardian2.png";
import icon_boy from "../../../img/home/icon_boy.png";
import icon_girl from "../../../img/home/icon_girl.png";
import icon_location from "../../../img/home/icon_location.png";
import Homepagechat from "../../../img/Homepagechat.png";
import Homepagegift from "../../../img/Homepagegift.png";
import Homepagevoice from "../../../img/Homepagevoice.png";
import Homepagevideo from "../../../img/Homepagevideo.png";
import icon_b1 from "../../../img/home/icon_b1.png";
import icon_b2 from "../../../img/home/icon_b2.png";

import "./home.css";
const HomePage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const location = useLocation(); 
	let userId = location.state.uid;
	let type = location.state.type; 
	const [ucenterinfo,setucenterinfo] = useState({}); 
	const [username,setusername] = useState('');
	const [avatarlist,setavatarlist] = useState([]);
	const [roominfo,setroominfo] = useState([]);
	const [userMinfo,setuserMinfo] = useState({});
	const [guardNum,setguardNum] = useState(0);
	const [guardinfo,setguardinfo] = useState({});
	const [Status,setStatus] = useState("");
	const [giftlist,setgiftlist] = useState([]);
	const [followList,setfollowList] = useState([]);
	const [minfoML,setminfoML] = useState(0);
	const [guardShow,setguardShow] = useState(false);
	
	useEffect(() => {
		   
		infoAjax();
		guardInfoAjax();
	},[]);
	const infoAjax=()=>{
		var arr = {
			uid: userId,
		}
		hoemInfo(arr).then((res)=>{
			setusername(res.data.uinfo.nick);
			setavatarlist(res.data.coverlist); 
			setucenterinfo(res.data.uinfo);
			setroominfo(res.data.roominfo);
			setuserMinfo(res.data.minfo); 
			setgiftlist(res.data.giftlist);
			setfollowList(res.data.followlist);
		})
	}
	const guardInfoAjax=()=>{
		var dat={
			hostid: userId
		}
		guardInfo(dat).then((res)=>{
			if(res.errno == 0) {
				setguardNum(res.data.number);
				setguardinfo(res.data.star.uinfo);
			}	
		})
	}
	const jumpedit=()=>{
		navigate("/page/home/edit/index")
	}
	const guardOpenBuy=()=>{
		setguardShow(true)
	}
	const jumpChat=(uid)=>{
		
	}
	const jumpTo=()=>{
		navigate("/page/home/subpage/gift", { state: { uid: userId,name: username,imgurl:ucenterinfo.avatar  } })
	}
	const jumpMtVoice=(uid)=>{
		navigate("/page/ml/index",{ state: { uid: uid,ctype: 1 } });
	}
	const jumpMtVideo=(uid)=>{
		navigate("/page/ml/index",{ state: { uid: uid,ctype: 2 } });
	}
	const Tagsend=(type)=>{
		if (type == 2) {
		  
		} else {
		  setguardShow(false);
		}
	}
	return (
		<div>
			<header id="header">
				<div className="main-box main-flex main-aic head head-transparent"  >
					<div className="main-box head_lf" >
						<a  onClick={() => navigate(-1)}><img src={icon_back} className="head_back" /></a>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{ username }</div>
					<div className="main-box head_rt"  >
					{
						type == 0 ? <img onClick={jumpedit} src={icon_edit} className="head_back" /> : ""
					}
						
					</div>
				</div>
			</header>
			<div className="main-box">
				<AvatarList list={avatarlist} type={type}></AvatarList>
				<div className="main-box home_info">
					<div className="main-box main-flex main-aic home_info_one">
						<div className="main-box main-flex main-f1">
							<p className="main-box home_info_name">{ ucenterinfo.nick }</p>
							{
								ucenterinfo.vip!=0 && ucenterinfo.svip==0 ? <div className="main-box home_vip_svip">
								<img src={vipsmall} />
							</div> : ""
							}
							{
								ucenterinfo.svip != 0 ? <div className="main-box home_vip_svip">
								<img src={svipsmall} />
							</div> : ""
							}
							
						</div>
						<div className="main-box">
						{
							guardNum==0 ? <div className="Guar-dian-div">
								<img src={Guardian1} onClick={guardOpenBuy} className="Guar-dian-img" />
							</div> : <div className="Guar-dian-div"  onClick={guardOpenBuy}>
								<img src={guardinfo.avatar}  onError={(e: any) => { e.target.src = defaultImg }}  className="Guar-dian-avatar" />
								<img src={Guardian2} className="Guar-dian-img" />
								<div className="guard-number">{guardNum}</div>
							</div>
						}
							
							
						</div>
					</div>
					<div className="main-flex home_info_two">
					{
						ucenterinfo.gender==1 ? <div className="main-box home_info_boy">
							<img src={icon_boy} />
							<span className="main-inline home_info_gender">{ ucenterinfo.age }</span>
						</div> : <div className="main-box home_info_boy">
							<img src={icon_girl} />
							<span className="main-inline home_info_gender home_info_active">{ ucenterinfo.age }</span>
						</div>
					}
						<div className="main-box home_info_girl">
							<GradeLevel grade={ucenterinfo.level}></GradeLevel>
						</div> 
						<div className="main-box home_info_girl">
							<RoomLevel grade={ucenterinfo.roomlevel}></RoomLevel>
						</div>
					</div>
					<div className="main-box main-flex main-aic main-bb home_info_three">
						<div className="main-box main-f1 home_info_lf">
							<span>{ Status } </span>
							<span className="home_info_dian">·</span>
							<span>{ t("fans") }</span>
							<a className="home_info_fans" href="javascript:;">{ ucenterinfo.fans }</a>
							<span className="home_info_dian">·</span>
							<span>{t("follow")}</span>
							{
								type == 2 ? <a className="home_info_follows" href="javascript:;">{ ucenterinfo.follow }</a> : <a className="home_info_follows" href="javascript:;">{ ucenterinfo.follow }</a>
							}
							
							
						</div>
						<div className="main-box main-flex main-aic home_info_rt">
							<img src={icon_location} />
							<span>{ ucenterinfo.country }</span>
						</div>
					</div>
				</div>
				<div className="">
					<HomeTab  uid={userId} type={type} info={ucenterinfo} Minfo={userMinfo} Rinfo={roominfo} gift={giftlist} follow={followList}></HomeTab>
				</div>
				
			</div>
			{
				type == 2 ? <div className="main-box">
				{
					minfoML==0 ? <div className="">
					{
						userMinfo.ml==1 ? <div className="main-box main-flex home_btn">
							<div className="main-box main-f1 main-hg home_btn_pd">
								<img className="home-bottom-img-width" src={Homepagechat} onClick={jumpChat.bind(this,ucenterinfo.uid)} />
							</div>
							<div className="main-box main-f1 main-hg home_btn_pd">
								<img  className="home-bottom-img-width" src={Homepagegift} onClick={jumpTo.bind(this,ucenterinfo.uid)} />
							</div>
							<div className="main-box main-f1 main-hg home_btn_pd">
								<div className="main-box main-hg main-flex main-aic main-jcc home_btn_lf" onClick={jumpMtVoice.bind(this,ucenterinfo.uid)}>
									<img src={Homepagevoice} />
									<p>{ userMinfo.voiceprice }G/min</p>
								</div>
							</div>
							<div className="main-box main-f1 main-hg home_btn_pd">
								<div className="main-box main-hg main-flex main-aic main-jcc home_btn_rt"  onClick={jumpMtVideo.bind(this,ucenterinfo.uid)} >
									<img src={Homepagevideo} />
									<p>{ userMinfo.videoprice }G/min</p>
								</div>
							</div>
						</div> : <div className="main-box main-flex home_btn">
							<div className="main-box main-f1 main-hg home_btn_pd">
								<div className="main-box main-hg main-flex main-aic main-jcc home_btn_lf home-bottom-chat-bg" onClick={jumpChat.bind(this,ucenterinfo.uid)}>
									<img src={icon_b1}/>
									<p>{ t("chat") }</p>
								</div>
							</div>
							<div className="main-box main-f1 main-hg home_btn_pd">
								<div className="main-box main-hg main-flex main-aic main-jcc home_btn_lf home-bottom-gift-bg"  onClick={jumpTo.bind(this,ucenterinfo.uid)}>
									<img src={icon_b2}/>
									<p>{ t("gift") }</p>
								</div>
							</div>
						</div>
					}
					</div> : <div className="main-box main-flex home_btn">
							<div className="main-box main-f1 main-hg home_btn_pd">
								<img className="home-bottom-img-width" src={Homepagechat} onClick={jumpChat.bind(this,ucenterinfo.uid)} />
							</div>
							<div className="main-box main-f1 main-hg home_btn_pd">
								<img  className="home-bottom-img-width" src={Homepagegift} onClick={jumpTo.bind(this,ucenterinfo.uid)} />
							</div>
							<div className="main-box main-f1 main-hg home_btn_pd">
								<div className="main-box main-hg main-flex main-aic main-jcc home_btn_lf" onClick={jumpMtVoice.bind(this,ucenterinfo.uid)}>
									<img src={Homepagevoice} />
									<p>{ userMinfo.voiceprice }G/min</p>
								</div>
							</div>
							<div className="main-box main-f1 main-hg home_btn_pd">
								<div className="main-box main-hg main-flex main-aic main-jcc home_btn_rt"  onClick={jumpMtVideo.bind(this,ucenterinfo.uid)} >
									<img src={Homepagevideo} />
									<p>{ userMinfo.videoprice }G/min</p>
								</div>
							</div>
						</div>
				}
					
				</div> : ""
			}
			{
				guardShow ?  <GuardPop uid={userId} type={type} info={ucenterinfo} tapBtn={Tagsend.bind(this)}></GuardPop> : ""
			}
		</div>
	);
};

export default HomePage; 