//个人中心
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router"; 
import {hoemInfo,viewerlist} from "../../../request/api.js";
import Toast from "../../../components/toast/toast.js";
import icon_01 from "../../../img/mine/icon_01.png"; 
import icon_02 from "../../../img/mine/icon_02.png";
import icon_03 from "../../../img/mine/icon_03.png";
import m_01 from "../../../img/mine/m_01.png";
import m_02 from "../../../img/mine/m_02.png";
import m_03 from "../../../img/mine/m_03.png";
import m_04 from "../../../img/mine/m_04.png"; 
import m_06 from "../../../img/mine/m_06.png";
import center_guard from "../../../img/mine/center_guard.png";
import imggold from "../../../img/gold.png";
import heimingdan from "../../../img/heimingdan.png";
import center_income from "../../../img/center_income.png";
import center_invite from "../../../img/center_invite.png";
import defaultImg from "../../../img/index/default.png";
const CentrePage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	let userId = ""; 
	const [userInfo,setuserInfo]=useState({});
	const [visitorNum,setvisitorNum]=useState(0);
	const [money,setmoney]=useState(0);
	
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr'));
		userId = userArr.uid; 
		infoAjax();
		viewerlistAjax();
	},[]);
	const infoAjax=()=>{
		var darr = {
			uid: userId,
		}
		hoemInfo(darr).then((res)=>{
			if(res.errno == 0) {
				setuserInfo(res.data.uinfo); 
			}else{
				Toast.fail(res.errmsg);
			}
		})
	}
	const viewerlistAjax=()=>{
		var darr = {
			pageno: 1,
			pagenum: 100
		}
		viewerlist(darr).then((res)=>{
			if(res.errno == 0) {
				setvisitorNum(res.data.total);
			}else{
				Toast.fail(res.errmsg);
			}
		})
	}
	const settingOpen=()=>{
		navigate("/page/setting/index");
	}
	const tapEditHome=(id,t)=>{
		navigate("/page/home/index", { state: { uid: id,type: t } });
	}
	const jumpTo=(url)=>{
		navigate("/page/centre/"+url);
	}
	const jumpTobill=()=>{
		navigate("/page/home/subpage/bill", { state: { uid: userId } });
	}
	const jumpTorech=()=>{
		navigate("/page/recharge/account");
	}
	const jumpToVip=()=>{
		navigate("/page/recharge/vip");
	}
	return (
		<div className="cont-discovery">
			<div onClick={settingOpen} className="main-box head_rt head-setting">
				<img src={icon_01} className="head_back" />
			</div>
			<div className="main-box main-relative mine"> 
				<div className="main-box main-relative main-over mine_up">
					<div className="main-box main-flex main-aic mine_bg">
						<div className="main-box mine_head">
							<img src={ userInfo.avatar!="" ? userInfo.avatar : defaultImg } />
						</div>
						<div className="main-box main-f1 mine_md">
							<p className="main-box main-ellipsis mine_name">{ userInfo.nick }</p>
							<p className="main-box mine_id">ID：{ userInfo.uid ? userInfo.uid : t("youke") }</p>
						</div>
						<div onClick={tapEditHome.bind(this,userInfo.uid, 0)} className="main-box main-flex main-aic mine_edit">
							<span>{ t("editHome") }</span>
							<img src={icon_02} />
						</div>
					</div>
					<div className="main-box mine_one"> 
						<div className="main-box main-flex main-aic main-relative main-index-bg mine_pos">
							<div onClick={jumpTo.bind(this,'visit')} className="main-box main-f1 main-tac">
								<p className="mine_visit">{ visitorNum }</p>
								<p className="mine_t1">{ t("visitor") }</p>
							</div>
							<div onClick={jumpTo.bind(this,'follow')} className="main-box main-f1 main-tac">
								<p className="mine_follow">{ userInfo.follow ? userInfo.follow : 0 }</p>
								<p className="mine_t1">{t("follow")}</p>
							</div>
							<div onClick={jumpTo.bind(this,'fans')} className="main-box main-f1 main-tac">
								<p className="mine_fans">{ userInfo.fans ? userInfo.fans : 0 }</p>
								<p className="mine_t1">{t("fans")}</p>
							</div> 
							<div className="main-box mine_two">
								<p className="main-hg"></p>
							</div>
							 
							<div className="main-box mine_three">
								<p className="main-hg"></p>
							</div>
						</div>
					</div>
					
				</div>
				<div className="main-box mine_down">
					
					<ul className="main-box main-index-bg mine_d2">
						<li className="main-box mine_li">
							<a className="main-box main-flex main-aic" href="javascript:;" onClick={jumpTo.bind(this,'seen')}>
								<img className="mine_icon" src={m_01}/>
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{t("lookLive")}</span>
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</a>
						</li>
						<li className="main-box mine_li">
							<a className="main-box main-flex main-aic" href="javascript:;" onClick={jumpTo.bind(this,'mylive')}>
								<img className="mine_icon" src={m_03} />
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{ t("myLive")}</span>
										<b className="mine_t4 min-es">{ t("lookProfit")}</b>
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</a>
						</li>
						<li className="main-box mine_li">
							<a className="main-box main-flex main-aic" onClick={jumpTo.bind(this,'guard')}>
								<img className="mine_icon" src={center_guard} />
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{t("indexTitle")}</span> 
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</a>
						</li>
						<li className="main-box mine_li">
							{
								userInfo.is_anchor==1 ? <a className="main-box main-flex main-aic" onClick={jumpTo.bind(this,'promote/index.html')}>
								<img className="mine_icon" src={center_invite} />
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{t("Invite_friend")}</span>
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</a> : <a className="main-box main-flex main-aic" onClick={jumpTo.bind(this,'unionanchor/index.html')}>
								<img className="mine_icon" src={center_invite} />
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{t("Invite_friend")}</span>
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</a>
							}
							
							
						</li>
					</ul>
					<ul className="main-box main-index-bg mine_d2">
						
						<li className="main-box mine_li">
							<a className="main-box main-flex main-aic" onClick={jumpTobill} >
								<img className="mine_icon" src={m_04}/>
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{t("myBill")}</span>
										<span className="mine_t3">({t("gift")})</span>
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</a>
						</li>
						
					</ul>
					
					<ul className="main-box main-index-bg mine_d2">
						
						<li className="main-box mine_li">
							<a className="main-box main-flex main-aic"   href="javascript:;" onClick={jumpTorech} >
								<img className="mine_icon" src={m_02} />
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{t("myAccount")}</span>
										{
											money!==0 ? <b className="mine_t4">{ money }</b> : <b className="mine_t4">{t("rechorge")}</b>
										}
										{
											money!==0 ? <img src={imggold}  className="main-gold-img" /> : ""
										}
										
										
										
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</a>
						</li>
						<li className="main-box mine_li">
							<a className="main-box main-flex main-aic"  href="javascript:;" onClick={jumpToVip} >
								<img className="mine_icon" src={m_06} />
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{t("vipCenter")}</span>
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</a>
						</li>
						
						
						<li className="main-box mine_li" >
							<div className="main-box main-flex main-aic" onClick={jumpTo.bind(this,'blacklist')}>
								<img className="mine_icon" src={heimingdan} />
								<div className="main-box main-f1 main-flex main-aic main-hg mine_x2">
									<div className="main-box main-f1">
										<span className="mine_t2">{ t("blacklist") }</span>
									</div>
									<img className="mine_rt" src={icon_03} />
								</div>
							</div>
						</li>
					</ul>
					
				</div>
			</div>
		</div>
	);
};

export default CentrePage; 