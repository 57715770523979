// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {receivedList } from "../../../request/api.js";
import {timestampToTime} from "../../../utils/utils.js";
import { Scroll } from "../../scroll/scroll.tsx";
import giftlist from "../../../json/giftlist.json";
import giftImg from "../../../img/giftd/gift@2x.png";

let dataArr = [];
let listShow = true;
const ReceiveGift: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const [getList,setgetList] = useState([]);
	const [wHeight,setwHeight] = useState(0);
	let page = 1;
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 100;
		setwHeight(Height);
		receivedListAjax();
	},[]);
	const handleScroll  = () => {
	  console.log("resourcePage");
	  if (dataArr.length > 9) {
		  if(listShow){
			  page = page + 1;
			  receivedListAjax();
		  }
	    
	  }
	};
	const receivedListAjax=()=>{
		var darrr = {
			page: page,
			page_num: 20,
			type: 'all',
			is_all: 0
		}
		receivedList(darrr).then((res)=>{
			if(res.errno == 0) {
				let gList = giftlist.data;
				for(var i=0;i<res.data.length;i++){
					for(var j=0;j<gList.length;j++){
						if(gList[j].id == res.data[i].gift_id){
							res.data[i].gift = gList[j];
						}
					}
					res.data[i].time = timestampToTime(res.data[i].time);
				} 
				dataArr = dataArr.concat(res.data); 
				setgetList(dataArr);
			}
		})
	}
	return (
		<div>
		{
			getList.length==0 ? <div className="main-flex main-aic main-jcc bill_fd main-box-img">
				<img src={giftImg} />
				<p>{t("noshowGift")}</p>
			</div> : <div>
				<div className="main-flex main-aic index_pl index_pr index_bg bill_top">
					<p className="main-f1 bill_t2">{t("lastmonthgift")}</p>
				</div>
				<ul  className="bill_get">
					<Scroll
					  height="100%"
					  load={handleScroll}
					  isFirstLoad={false}
					>
					{
						getList.map((item,index)=>{
							return (<li className="main-bg main-flex main-aic index_pl bill_li">
								<img className="main-vat bill_img1" src={item.gift.icon} />
								<div className="main-f1 main-bb main-hg index_pr">
									<div className="main-over bill_pt">
										<p className="main-lf bill_t4">{ item.name }×{ item.quantity }</p>
										<p className="main-rt bill_t6">{ item.amount }</p>
									</div>
									<div className="main-over bill_pt2">
										<p className="main-lf bill_t5">{t("from")} { item.from_user.nickname }</p>
										<p className="main-rt bill_t5">{ item.time }</p>
									</div>
								</div>
							</li>)
						})
					}
					</Scroll>
				</ul>
			</div>
		}
		</div>
	);
};

export default ReceiveGift; 