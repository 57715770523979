//编辑个人信息
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {usermyinfo } from "../../../../request/api.js";
import Toast from "../../../../components/toast/toast.js";
import icon_back from "../../../../img/icon_back.png";
import icon_right from "../../../../img/home/icon_right.png";
import defaultImg from "../../../../img/index/default.png";
const EditPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const [userInfo,setuserInfo] = useState({});
	const [detail,setdetail] = useState({});
	const [birth,setbirth] = useState("");
	const [marry,setmarry] = useState("");
	
	const [isSign,setisSign] = useState(false);
	const langlist = [{name:"中文简体",code:"zh"},
			{name:"中文繁体",code:"zhtw"},
			{name:"English",code:"en"},
			{name:"Tiếng Việt",code:"vi"},
			{name:"日本語",code:"ja"},
			{name:"한국의",code:"ko"},
			{name:"Deutsch",code:"de"},
			{name:"Español",code:"es"},
			{name:"Français",code:"fr"},
			{name:"Italiano",code:"it"},
			{name:"Nederlands",code:"nl"},
			{name:"Português",code:"pt"},
			{name:"Русский",code:"ru"}];
	
	useEffect(() => { 
		usermyinfoAjax();
	},[]);
	const usermyinfoAjax=()=>{
		let darrf = {
			extra: 'detail',
		}
		usermyinfo(darrf).then((res)=>{
			if(res.errno == 0) {
				setuserInfo(res.data.uinfo);
				for(var i=0; i < langlist.length;i++){
					if(res.data.detail.language == langlist[i].code ){
						res.data.detail.language = langlist[i].name;
					}
				}
				setdetail(res.data.detail); 
				setbirth(res.data.detail.birthday);
				 
				if(res.data.detail.marry == 1){
					setmarry(t("single")) 
				}else if(res.data.detail.marry == 2){
					setmarry(t("married")) 
				}else if(res.data.detail.marry == 3){
					setmarry(t("love")) 
				}else{
					setmarry(t("secrecy"))  
				}
			
				
			}
		})
	}
	return (
		<div className="edit-page">
			<header className="header">
				<div className="main-box main-flex main-aic head" >
					<div className="main-box head_lf">
						<div onClick={() => navigate(-1)}><img src={icon_back} className="head_back"/></div>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("editProfile")}</div>
					<div className="main-box head_rt" ></div>
				</div>
			</header>
			<div className="main-box">
				<div className="main-bg index_pl index_mt">
					<div className="main-flex main-aic edit_li">
						<p className="edit_t1">ID</p>
						<div className="main-f1 main-tar">
							<span className="edit_t2">{ userInfo.uid }</span>
						</div>
					</div>
				</div>
				<div className="main-bg index_pl index_mt">
					<div className="main-flex main-aic main-bb edit_lis">
						<p className="edit_t1">{ t("head") }</p>
						<div className="main-f1 main-tar" > 
							<img id="edit_avatar" className="main-va main-radius edit_head" src={userInfo.avatar} onError={(e: any) => { e.target.src = defaultImg }} />
							<img className="main-va edit_icon" src={icon_right} />
						</div>
					</div>
					
					<div className="main-flex main-aic main-bb edit_li">
						<p className="edit_t1">{t("nick")}</p>
						<div className="main-f1 main-tar" onClick={() => navigate("/page/home/edit/nick", { state: { con: userInfo.nick  } } )}>
							<span className="main-va edit_t2">{ userInfo.nick }</span>
							<img className="main-va edit_icon" src={icon_right} />
						</div>
					</div>
					
					<div className="main-flex main-aic main-bb edit_li">
						<p className="edit_t1">{t("gender")}</p>
						<div className="main-f1 main-tar">
						{
							userInfo.gender == 1 ? <span className="main-va edit_t2">{t("boy")}</span> : <span className="main-va edit_t2">{ t("girl")}</span>
						}
							
							
						</div>
					</div>
					
					<div className="main-flex main-aic edit_li">
						<p className="edit_t1">{t("birth")}</p>
						<div id="birth" className="main-f1 main-tar">
							<span className="main-va edit_t2">{ birth }</span>
							<img className="main-va edit_icon" src={icon_right} />
						</div>
					</div>
				</div>
				<div className="main-bg index_pl index_mt">
					<div className="main-flex main-aic edit_li">
						<p className="edit_t1">{ t("sign") }</p>
						<div className="main-f1 main-over main-flex main-aic" onClick={() => navigate("/page/home/edit/sign")}>
							<span className="main-ellipsis main-f1 main-tar edit_pr edit_t2">{ detail.sign }</span>
							<img className="edit_icon" src={icon_right}/>
						</div>
					</div>
				</div>
				<div className="main-bg index_pl index_mt">
					<div className="main-flex main-aic main-bb edit_li">
						<p className="edit_t1">{ t("location") }</p>
						<div className="main-f1 main-tar" >
							<span className="main-va edit_t2">{ detail.country }</span>
						</div>
					</div>
					
					<div className="main-flex main-aic main-bb edit_li">
						<p className="edit_t1">{ t("language") }</p>
						<div className="main-f1 main-tar"  onClick={() => navigate("/page/home/edit/language", { state: { con: detail.language  } } )}>
							<span className="main-va edit_t2" >{ detail.language }</span>
							<img className="main-va edit_icon" src={icon_right}/>
						</div>
					</div>
					
					<div className="main-flex main-aic main-bb edit_li">
						<p className="edit_t1">{ t("feelState") }</p>
						<div className="main-f1 main-tar" onClick={() => navigate("/page/home/edit/feel", { state: { con: detail.marry  } } )}>
							<span className="main-va edit_t2" >{ marry }</span>
							<img className="main-va edit_icon" src={icon_right}/>
						</div>
					</div>
					
					<div className="main-flex main-aic edit_li">
						<p className="edit_t1">{ t("work") }</p>
						<div className="main-f1 main-tar" onClick={() => navigate("/page/home/edit/work", { state: { con: detail.career  } } )}>
							<span className="main-va edit_t2" >{ detail.work }</span>
							<img className="main-va edit_icon" src={icon_right}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditPage; 