// vip
import React, { useEffect, useState }  from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate,Link } from "react-router-dom";
import {priceinfo} from "../../request/api.js";
import icon_ok from "../../img/member/icon_ok.png";
import icon_error from "../../img/member/icon_error.png";

const VipCon: React.FunctionComponent = (props) => { 
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [vipList,setvipList] = useState([]);
	useEffect(() => {
		indexAjax(); 
	},[]);
	const indexAjax=()=>{
		priceinfo({}).then((res)=>{
			if(res.errno == 0) {
				//res.data.vip.splice(1, 1); 
				for(var i= 0;i<res.data.vip.length;i++) {
					res.data.vip[i].CNY = res.data.vip[i].CNY/100;
					res.data.vip[i].USD = res.data.vip[i].USD/100;
					res.data.vip[i].cny_price = res.data.vip[i].cny_price/100;
					res.data.vip[i].usd_price = res.data.vip[i].usd_price/100;
					if(i18n.language == 'zh') {
						res.data.vip[i].pay = "¥"+res.data.vip[i].CNY;
						res.data.vip[i].payOne = "¥"+res.data.vip[i].cny_price;
						//节省百分比
						res.data.vip[0].saveProportion = 0;
						res.data.vip[1].saveProportion = Math.round(((res.data.vip[0].cny_price - res.data.vip[1].cny_price) / res.data.vip[0].cny_price) * 100);
						res.data.vip[2].saveProportion = Math.round(((res.data.vip[0].cny_price - res.data.vip[2].cny_price) / res.data.vip[0].cny_price) * 100);
					} else {
						res.data.vip[i].pay = "$"+res.data.vip[i].USD;
						res.data.vip[i].payOne = "$"+res.data.vip[i].usd_price;
						//节省百分比
						res.data.vip[0].saveProportion = 0;
						res.data.vip[1].saveProportion = Math.round(((res.data.vip[0].usd_price - res.data.vip[1].usd_price) / res.data.vip[0].usd_price) * 100);
						res.data.vip[2].saveProportion = Math.round(((res.data.vip[0].usd_price - res.data.vip[2].usd_price) / res.data.vip[0].usd_price) * 100);
					}
				} 
				setvipList(res.data.vip)
			}
		})
	}
	return (
		<div>
			<div className="vip_privilege vip-p-height">
				<ul className="main-hg main-bg vip_privilege_list">
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip4") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedsvip13") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedSvip5") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip9") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("专属礼物和弹幕") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedsvip9") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("免费聊天和翻译") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedsvip10") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip1") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip3") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip2") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip13") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_ok} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip11") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_error} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip12") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_error} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedsvip14") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_error} alt="Icon" />
						</div>
					</li>
					<li className="main-lf main-w5 main-flex main-aic vip_privilege_li" >
						<p className="main-f1 main-over main-flex main-aic vip_privilege_txt">{ t("freedvip10") }</p>
						<div className="main-tac vip_privilege_ok">
							<img width="12" src={icon_error} alt="Icon" />
						</div>
					</li>
					
				</ul>
			</div>
			<div className="vip_time">
				{
					vipList.map((item,index)=>{
						return (<div className="vip_time_li" key={index}>
					<div className="main-relative main-bg main-flex main-aic main-hg vip_time_item">
						<p className="vip_time_month">{ item.quantity }{ t("months") }</p>
						<p className="main-f1 vip_time_price">{ item.pay }</p>
						<p className="vip_time_one">{ item.payOne }/{t("vipfont")}</p> 
						
					</div>
				</div>)
					})
				}
				
			</div>
			
		</div>
	);
};

export default VipCon; 