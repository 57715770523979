 function change(t) {
     if (t < 10) {
         return "0" + t;
     } else {
         return t;
     }
 }
 export const timestampToTime = (timestamp) => {
   var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
   var  Y = date.getFullYear() + '-';
   var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
   var D = change(date.getDate()) + ' ';
   var h = change(date.getHours()) + ':';
   var m = change(date.getMinutes()) + ':';
   var s = change(date.getSeconds());
   return Y + M + D + h + m + s;
 };
 
 export const timeToMinute=(times)=>{
 	//var times = times*1000;
     var result = '00:00:00';
     var hour,minute,second
     if (times > 0) {
       hour = Math.floor(times / 3600);
       if (hour < 10) {
         hour = "0"+hour;
       }
       minute = Math.floor((times - 3600 * hour) / 60);
       if (minute < 10) {
         minute = "0"+minute;
       }
  
       second = Math.floor((times - 3600 * hour - 60 * minute) % 60);
       if (second < 10) {
         second = "0"+second;
       }
       result = hour+':'+minute+':'+second;
     }
     //console.log("result",result);
     return result;  
 };
 export const floorTime=(str)=> {
 	var timestamp = 0;
 	var pass = 0;
 	timestamp = Math.round(new Date().getTime()/1000).toString();
 	pass = (timestamp - str);
 	if(pass < 60) {
 		return pass + "s";
 	} else {
 		if(pass < (60 * 60)) {
 			pass = Math.floor(pass / 60);
 			return pass + "m";
 		} else {
 			if(pass < (60 * 60 * 72)) {
 				pass = Math.floor(pass / 60 / 60);
 				return pass + "h";
 			} else {
 				if(pass >= (60 * 60 * 72)) {
 					pass = Math.floor(pass / 60 / 60 / 24);
 					return pass + "d";
 				}
 			}
 		}
 	}
 };