//导入我们封装好的axios
import service from "./index"; 
import i18n from "i18next";
console.log("i18n.language: ",i18n.language) 
 
//首页分类
//export const getIndexCate = (params) =>
//  service.get("/api/cate/getIndexCate", {params});
//查询系统配置
//export const getSystemConfig = (params) =>
//  service.post("/api/index/getSystemConfig", params);
  
//export const getToken = (params) =>
//  service.post("/api/login/getTokenZalo", params, { urlType: 1 });
let urlc = "?__plat=h5&__version=1.0&__channel=anycupid";
let getToken = localStorage.getItem('token') ? localStorage.getItem('token') : '';
//手机号验证是否登陆 /common/checkphone/
export const getCheckphone = (params) =>
  service.post("common/checkphone/"+urlc+"&__lg="+i18n.language, params, { urlType: 3 } );
  
//获取密码加密盐
export const getaeskey = (params) =>
  service.get("common/aeskey/"+urlc+"&__lg="+i18n.language, { urlType: 3 });  

//登陆 /user/login/
export const loginAjax = (params) =>
  service.post("/user/login/"+urlc+"&__lg="+i18n.language, params, { urlType: 3 } );
  
//获取登陆token  /common/token/
export const tokenAjax = (params) =>
  service.post("common/token/"+urlc+"&__lg="+i18n.language, params, { urlType: 3 } );
  
//获取图片验证码/common/getcaptcha/
export const getcaptcha = (params) =>
  service.post("common/getcaptcha/"+urlc+"&__lg="+i18n.language, params, { urlType: 3 } );
  
//发短信验证码  /common/sendsms/
export const sendsms = (params) =>
  service.post("common/sendsms/"+urlc+"&__lg="+i18n.language, params, { urlType: 3 } );

//验证注册验证码是否正确 /user/checkregsms
export const checkregsms = (params) =>
  service.post("user/checkregsms/"+urlc+"&__lg="+i18n.language, params, { urlType: 3 } );

///user/reg/
export const regpost = (params) =>
  service.post("user/reg/"+urlc+"&__lg="+i18n.language, params, { urlType: 3 } );
  
//直播推荐
export const recommendlist = (params) =>
  service.post("/anchor/recommendlist/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );

//蜜聊列表
export const michatList = (params) =>
  service.get("michat/list/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 

//交友列表
export const friendlist = (params) =>
  service.post("friend/list/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );

//动态列表
export const dynamiclist = (params) =>
  service.post("dynamic/list/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );

//短视频列表
export const videolist = (params) =>
  service.post("video/recommend/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );
  
///点赞检查
export const likecheck = (params) =>
  service.post("video/likecheck/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );

//短视频取消点赞
export const likedel = (params) =>
  service.post("video/likedel/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );

//短视频点赞
export const likeadd = (params) =>
  service.post("video/likeadd/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );

//获取用户信息
export const usermyinfo = (params) =>
  service.get("user/myinfo/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} );

//获取用户信息
export const hoemInfo = (params) =>
  service.get("ucenter/info/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} );

//守护信息
export const guardInfo = (params) =>
  service.get("guard/info/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} );

//用户动态
export const ucenterDynamic = (params) =>
  service.get("ucenter/dynamic/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} );

//用户守护列表
export const guardList = (params) =>
  service.post("guard/list/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );

//用户守护信息
export const guardbuyitems = (params) =>
  service.post("guard/buyitems/"+urlc+"&__lg="+i18n.language+"&"+getToken, params ); 

///guard/info
export const guardinfo = (params) =>
  service.get("guard/info/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 

//guard/buy
export const guardbuy = (params) =>
  service.post("guard/buy/"+urlc+"&__lg="+i18n.language+"&"+getToken, params ); 
  
//余额
export const balance = (params) =>
  service.post("api/account/balance/"+urlc+"&"+getToken, params ); 

///gift/list
export const giftList = (params) =>
  service.get("gift/list/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 

///gift/send 送礼物
export const giftsend = (params) =>
  service.post("gift/send/"+urlc+"&__lg="+i18n.language+"&"+getToken, params ); 

//收到的礼物
export const receivedList = (params) =>
  service.get("api/bill/receivedList/"+urlc+"&"+getToken, {params} ); 
  
//送出礼物
export const sentList = (params) =>
  service.get("api/bill/sentList/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//充值记录
export const rechargeList = (params) =>
  service.get("api/bill/rechargeList/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
///room/baseinfo
export const baseinfo = (params) =>
  service.get("room/baseinfo/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//粉丝列表
export const fanslist = (params) =>
  service.get("user/fanslist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//取消关注
export const followdel = (params) =>
  service.post("user/followdel/"+urlc+"&__lg="+i18n.language+"&"+getToken, params ); 

//关注
export const followadd = (params) =>
  service.post("user/followadd/"+urlc+"&__lg="+i18n.language+"&"+getToken, params ); 
  
//关注列表
export const followlist = (params) =>
  service.get("user/followlist/"+urlc+"&"+getToken, {params} ); 
  
//观看列表
export const historylist = (params) =>
  service.get("user/historylist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//来访数据
export const viewerlist = (params) =>
  service.get("friend/viewerlist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//直播收益
export const rankprofit = (params) =>
  service.get("rank/profit/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//本月直播记录
export const anchorlivelist = (params) =>
  service.get("anchor/livelist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//我守护的列表
export const guardmylist = (params) =>
  service.get("guard/mylist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//黑名单
export const blacklist = (params) =>
  service.get("black/list/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//获取价格信息
export const priceinfo = (params) =>
  service.post("index/"+urlc+"&__lg="+i18n.language+"&"+getToken, params, { urlType: 2 } );

//profile/infoedit
export const infoedit = (params) =>
  service.post("profile/infoedit/"+urlc+"&__lg="+i18n.language+"&"+getToken, params, { urlType: 3 } );

//关注的主播
export const ucenterfollowlist = (params) =>
  service.get("ucenter/followlist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//发现-排行榜-国家
export const discover = (params) =>
  service.get("discover/index/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//发现最新
export const newlivelist = (params) =>
  service.get("discover/newlivelist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 

//排行榜
export const ranklist = (params) =>
  service.get("rank/list/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//discover/countrygroups
export const countrygroups = (params) =>
  service.get("discover/countrygroups/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//discover/countrylist
export const discovercountrylist = (params) =>
  service.get("discover/countrylist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//会话信息
export const dotlist = (params) =>
  service.get("dot/list/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 

//清除所有未读
export const dotcleanall = (params) =>
  service.get("dot/cleanall/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//会话列表
export const chatlist = (params) =>
  service.get("pmsg/chatlist/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 

//room/tempact
export const roomtempact = (params) =>
  service.get("room/tempact/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 

//直播间观看列表
export const roomusers = (params) =>
  service.get("room/users/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
//直播间关注
export const baseuserinfo = (params) =>
  service.get("room/baseuserinfo/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 

//系统公告
export const commonsystemmsg = (params) =>
  service.get("common/systemmsg/"+urlc+"&"+getToken, {params} ); 

//message/sendfloat
export const messagesendfloat = (params) =>
  service.post("message/sendfloat/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );

//message/send
export const messagesend = (params) =>
  service.post("message/send/"+urlc+"&__lg="+i18n.language+"&"+getToken, params );
  
  
//user/token
//profile/infoedit
export const usertoken = (params) =>
  service.get("user/token"+urlc+"&__lg="+i18n.language+"&"+getToken+"&__guid=h5wapchat", { urlType: 1 } );
  
//退出登录
export const logout = (params) =>
  service.get("user/logout/"+urlc+"&__lg="+i18n.language+"&"+getToken, {params} ); 
  
  