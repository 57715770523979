// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {rechargeList } from "../../../request/api.js";
import {timestampToTime} from "../../../utils/utils.js";
import { Scroll } from "../../scroll/scroll.tsx";
import giftlist from "../../../json/giftlist.json";
import rekong from "../../../img/rekong.png";

let dataArr = [];
let listShow = true;
const RechargeList: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const [getList,setgetList] = useState([]);
	const [wHeight,setwHeight] = useState(0);
	let page = 1;
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 100;
		setwHeight(Height);
		rechargeListAjax();
	},[]);
	const handleScroll  = () => {
	  console.log("resourcePage");
	  if (dataArr.length > 9) {
		  if(listShow){
			  page = page + 1;
			  rechargeListAjax();
		  }
	    
	  }
	};
	const rechargeListAjax=()=>{
		var darrr = {
			page: page,
			page_num: 20
		}
		rechargeList(darrr).then((res)=>{
			if(res.errno == 0) { 
				for(var i=0; i<res.data.length; i++){
					if(res.data[i].goods_type == 'gold'){
						res.data[i].quantity = t("rechorge")+" "+ res.data[i].quantity +"" + t("goldfont");
					}else if(res.data[i].goods_type == 'vip'){
						res.data[i].quantity = t("rechorge")+" "+ res.data[i].quantity +""+ t("vipfont");
					}else if(res.data[i].goods_type == 'svip'){
						res.data[i].quantity = t("rechorge")+" "+ res.data[i].quantity +"" + t("svipfont");
					}
					
					
					if(res.data[i].currency_type = "cny"){
						res.data[i].amount = "-" + res.data[i].amount*0.01 + "¥";
					}else if(res.data[i].currency_type = "usd"){
						res.data[i].amount = "-" + res.data[i].amount*0.01 + "$";
					}else{
						res.data[i].amount = "-" + res.data[i].amount*0.01;
					}
					res.data[i].time = timestampToTime(res.data[i].time);
					if(res.data[i].status == 1){
						res.data[i].status = t("nopay");
					}else if(res.data[i].status == 2){
						res.data[i].status = t("yespay");
					}else if(res.data[i].status == 3){
						res.data[i].status = t("cancel");
					}else if(res.data[i].status == 4){
						res.data[i].status = t("fail");
					}else if(res.data[i].status == 5){
						res.data[i].status = t("refund");
					}
				}
				dataArr = dataArr.concat(res.data); 
				setgetList(dataArr);
			}
		})
	}
	return (
		<div>
		{
			getList.length==0 ? <div className="main-flex main-aic main-jcc bill_fd main-box-img">
				<img src={rekong} />
				<p>{t("noRecharge")}</p>
			</div> : <div>
				<ul className="main-bg index_pl bill_record">
					<Scroll
					  height="100%"
					  load={handleScroll}
					  isFirstLoad={false}
					>
					{
						getList.map((item,index)=>{
							return (<li className="main-flex main-bb main-aic record_li">
								<div className="main-f1">
									<p className="record_t1">{ item.quantity }</p>
									<p className="record_t3">{ item.time }</p>
								</div>
								<div className="main-tac">
									<p className="record_t2">{ item.amount }</p>
									<p className="record_t3">{ item.status }</p>
								</div>
							</li>)
						})
					}
					</Scroll>
				</ul>
			</div>
		}
		</div>
	);
};

export default RechargeList; 