// 收到礼物
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {receivedList } from "../../../../request/api.js";
import Toast from "../../../../components/toast/toast.js";
import ReceiveGift from "../../../../components/home/bill/receivegift.js";
import GiveGift from "../../../../components/home/bill/givegift.js";
import RechargeList from "../../../../components/home/bill/rechargeList.js";
import icon_back from "../../../../img/icon_back.png";
import Swiper from 'swiper'; 
import "swiper/css";
import "../../../../css/swiper.css";


let swiperbill;
const BillPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const location = useLocation(); 
	const [wHeight,setwHeight] = useState(0);
	const [tabId,settabId] = useState(0);
	
	
	useEffect(() => {
		let Height = document.documentElement.clientHeight - 100;
		setwHeight(Height);
		setTimeout(function(){
			swiperbill = new Swiper(".swiper-bill", {
						  slidesPerView: 1, //同时显示的个数
						  spaceBetween: 10, //两个slide之间的距离
						  loop: false, //是否循环
			    onTransitionEnd: function(swiper) {  
					settabId(swiper.activeIndex + 1)
			    } 
			});
		},1000)
	},[]);
	const tapTabs=(k)=>{
		swiperbill.slideTo(k);
	}
	return (
		<div className="main-box">
			<header className="header">
				<div className="main-box main-flex main-aic head" >
					<div className="main-box head_lf">
						<div onClick={() => navigate(-1)}><img src={icon_back} className="head_back"/></div>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("myBill")}</div>
					<div className="main-box head_rt" ></div>
				</div>
			</header>
			<div className="bill">
				<div className="main-w10 bill_tab">
					<div className="main-flex main-bg main-bb main-aic main-tac">
						<div className="main-f1">
							<div className="main-inline main-relative bill_item" onClick={tapTabs.bind(this,0)}>
								<span className={tabId == 0 ? "bill_t1 bill_c1" : "bill_t1"}>{t("receivedGifts")}</span>
								{
									tabId == 0 ? <p className="main-w10 main-hg bill_xian"></p> : ""
								}
								
							</div>
						</div>
						<div className="main-f1">
							<div className="main-inline main-relative bill_item"  onClick={tapTabs.bind(this,1)}>
								<span className={tabId == 1 ? "bill_t1 bill_c1" : "bill_t1"}>{t("sendGift")}</span>
								{
									tabId == 1 ? <p className="main-w10 main-hg bill_xian"></p> : ""
								}
							</div>
						</div>
						<div className="main-f1">
							<div className="main-inline main-relative bill_item"  onClick={tapTabs.bind(this,2)}>
								<span className={tabId == 2 ? "bill_t1 bill_c1" : "bill_t1"}>{t("rechargeHistory")}</span>
								{
									tabId == 2 ? <p className="main-w10 main-hg bill_xian"></p> : ""
								}
							</div>
						</div>
					</div>
				</div>
				<div className="bill_slide">
					<div className="swiper-container swiper-bill">
						<div className="swiper-wrapper" style={{ height: wHeight + "px" }}>
							<div className="swiper-slide index_bg">
								<ReceiveGift></ReceiveGift> 
							</div>
							<div className="swiper-slide index_bg">
								<GiveGift></GiveGift>
							</div>
							<div className="swiper-slide index_bg">
								<RechargeList></RechargeList>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
		</div>
	);
};

export default BillPage; 