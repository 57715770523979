// 守护详情
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";

import icon_back from "../../../img/icon_back.png";
import banner from "../../../img/guard/banner.png";
import decoration from "../../../img/guard/decoration.png";
import Checkmark from "../../../img/guard/Checkmark.png";
const GuardRule: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<div className="rule">
			<header>
				<div className="main-box main-flex main-aic head head-top-div">
					<div className="main-box head_lf">
						<div><img src={icon_back} className="head_back" /></div>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{ t("ruleTitle") }</div>
					<div className="main-box head_rt"> 
					</div>
				</div>
			</header>
			<div>
			    <img className="main-w10" src={banner} alt="BG" />
			    <div className="rule_content">
			        <div className="rule_item">
			            <div className="main-flex main-aic">
			                <img width="13" src={ decoration } alt="Icon" />
			                <p className="rule_title">{ t("ruleTitle1")}</p>
			            </div>
			            <p className="rule_txt">{t("ruleContent1")}</p>
			            <p className="rule_txt">{t("ruleContent2")}</p>
			            <p className="rule_txt">{t("ruleContent3")}</p>
			            <p className="rule_txt">{t("ruleContent4")}</p>
			        </div>
			
			        <div className="rule_item">
			            <div className="main-flex main-aic">
			                <img width="13" src={ decoration } alt="Icon"/>
			                <p className="rule_title">{t("ruleTitle2")}</p>
			            </div>
			            <p className="rule_txt">{t("ruleContent5")}</p>
			            <p className="rule_txt">{t("ruleContent6")}</p>
			            <p className="rule_txt">{t("ruleContent7")}</p>
			            <p className="rule_txt">{t("ruleContent8")}</p>
			        </div>
			
			        <div className="rule_item">
			            <div className="main-flex main-aic">
			                <img width="13" src={ decoration } alt="Icon"/>
			                <p className="rule_title">{t("ruleTitle3")}</p>
			            </div>
			            <p className="rule_txt">{t("ruleContent9")}</p>
			            <p className="rule_txt">{t("ruleContent10")}</p>
			            <p className="rule_txt">{t("ruleContent11")}</p>
			        </div>
			 
			        <div className="rule_down">
			            <div className="main-flex main-aic">
			                <img width="13" src={ decoration } alt="Icon"/>
			                <p className="rule_title">{t("ruleTitle4")}</p>
			            </div>
			            <table className="main-w10 rule_table">
			                <thead>
			                    <th>{t("ruleTable1")}</th>
			                    <th>{t("ruleTable2")}</th>
			                    <th>{t("ruleTable3")}</th>
			                </thead>
			                <tbody>
			                    <tr>
			                        <td>{t("ruleTable4")}</td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                        <td>{t("ruleTable5")}</td>
			                    </tr>
			                    <tr>
			                        <td>{t("ruleTable6")}</td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                        <td>{t("ruleTable5")}</td>
			                    </tr>
			                    <tr>
			                        <td>{t("ruleTable7")}</td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                    </tr>
			                    <tr>
			                        <td>{t("ruleTable8")}</td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                        <td>{t("ruleTable5")}</td>
			                    </tr>
			                    <tr>
			                        <td>{t("ruleTable9")}</td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                    </tr>
			                    <tr>
			                        <td>{t("ruleTable10")}</td>
			                        <td>{t("ruleTable11")}</td>
			                        <td>{t("ruleTable12")}</td>
			                    </tr>
			                    <tr>
			                        <td>{t("ruleTable13")}</td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                        <td><img width="15" src={ Checkmark } alt="Checkmark"/></td>
			                    </tr>
			                </tbody>
			            </table>
			        </div> 
			        <div className="rule_item">
			            <div className="main-flex main-aic">
			                <img width="13" src={ decoration } alt="Icon" />
			                <p className="rule_title">{t("ruleTitle5")}</p>
			            </div>
			            <p className="rule_txt">{t("ruleContent12")}</p>
			            <p className="rule_txt">{t("ruleContent13")}</p>
			        </div>
			    </div>
			</div>
		</div>
	)
};

export default GuardRule; 