// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom"; 

import charm from "../../../img/home/charm.png";
import charm1 from "../../../img/home/charm1.png";
import charm2 from "../../../img/home/charm2.png";
import charm3 from "../../../img/home/charm3.png";
import charm4 from "../../../img/home/charm4.png";
import charm5 from "../../../img/home/charm5.png";
import charm6 from "../../../img/home/charm6.png";
import icon_live from "../../../img/home/icon_live.png";
import livegif from "../../../img/home/live.gif";
import giftsImg from "../../../img/giftd/gifts.png";
import icon_right from "../../../img/home/icon_right.png";
import defaultImg from '../../../img/public/default.png';

const HomeInfo: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const type= props.type;
	const ucenterinfo = props.info;
	const userMinfo = props.Minfo;
	const roominfo = props.Rinfo;
	const giftlist = props.gift;
	const followList = props.follow;
	const langlist = [{name:"中文简体",code:"zh"},
		{name:"中文繁体",code:"zhtw"},
		{name:"English",code:"en"},
		{name:"Tiếng Việt",code:"vi"},
		{name:"日本語",code:"ja"},
		{name:"한국의",code:"ko"},
		{name:"Deutsch",code:"de"},
		{name:"Español",code:"es"},
		{name:"Français",code:"fr"},
		{name:"Italiano",code:"it"},
		{name:"Nederlands",code:"nl"},
		{name:"Português",code:"pt"},
		{name:"Русский",code:"ru"}]
	const [infoLg,setinfoLg]=useState(0);
	const [giftList,setgiftList]=useState([]);
	
	useEffect(() => {
		
		if(ucenterinfo.language!=""){
			for(var i=0;i < langlist.length;i++){
				if(ucenterinfo.language == langlist[i].code){
					setinfoLg(langlist[i].name);
				}
			}
		}
	},[]);
	const tapChatLive=()=>{
		
	}
	const billOpen=(id)=>{
		navigate("/page/home/subpage/bill", { state: { uid: id } })
		//bill.html
	}
	const giveGift=(id)=>{
		//giveGift.html
	}
	const jumpTo=(url)=>{
		
	}
	const tapFollowAnchor=()=>{
		navigate("/page/home/subpage/follow",{ state:{ uid:ucenterinfo.uid } })
	}
	return (
		<div className=" home_tab">
		{
			userMinfo.ml==1 ? <div className="main-box home_data_pt" onClick={tapChatLive.bind(this,ucenterinfo.uid)} >
				<p className="main-box home_tab_title">{t("liveRoom")}</p>
				<div className="main-box main-relative home_data_live" style="background-image: url(../../img/home/del_01.jpg);">
					<p className="home_data_mask"></p>
					<div className="main-box main-flex main-aic main-relative home_data_bg"> 
					{
						ucenterinfo.roomlevel ==0 ? <img className="home_data_icon" src={charm} /> : ""
					}
					{
						16 > ucenterinfo.roomlevel > 0 ? <img className="home_data_icon" src={charm1}/> : ""
					}
					{
						31 > ucenterinfo.roomlevel > 15 ? <img className="home_data_icon" src={charm2}/> : ""
					}	
					{
						46 > ucenterinfo.roomlevel > 30 ? <img className="home_data_icon" src={charm3}/> : ""
					}
					{
						61 > ucenterinfo.roomlevel > 45 ? <img className="home_data_icon" src={charm4}/> : ""
					}
					{
						76 > ucenterinfo.roomlevel > 60 ? <img className="home_data_icon" src={charm5}/> : ""
					}
					{
						ucenterinfo.roomlevel > 75 ? <img className="home_data_icon" src={charm6}/> : ""
					}	 
						<div className="main-box main-f1 home_data_md">
							<p className="main-box main-ellipsis home_data_name">{ ucenterinfo.nick }{t('ofliveroom')}</p>
							<div className="main-box home_data_grade">
								<span>{t("auchorGrade")}</span>
								<span className="home_data_pl">{ ucenterinfo.roomlevel }</span>
							</div>
						</div>
						{
							roominfo.playstatus == 1 ? <div className="main-box main-flex main-aic home_data_islive">
							<div className="main-box main-relative">
								<img className="home_data_bg2" src={icon_live}/>
								<img className="home_data_gif" src={livegif}/>
							</div>
							<p className="home_data_play">Live</p>
						</div> : <div className="main-box main-flex main-aic home_data_islive">
							<p className="home_data_noplay">{t("unbroadcast")}</p>
						</div>
						}
						
						
					</div>
				</div>
			</div> : ""
		}
			<div className="main-box home_data_pt" >
				<p className="main-box home_tab_title">{t("accountInfor") }</p>
				<p className="main-box home_data_t1">ID：{ ucenterinfo.uid }</p>
				<p className="main-box home_data_t2">{ t("registerTime") }：{ ucenterinfo.regtime }</p>
			</div>
			<div className="main-box home_data_pt">
				<p className="main-box home_tab_title">{ t("personalInfo") }</p>
				
				{
					ucenterinfo.marry == 1 ? <p className="main-box home_data_t1">{ t("emotionalState") }：{ t("single") }</p> : ""
				}
				{
					ucenterinfo.marry == 2 ? <p className="main-box home_data_t1">{ t("emotionalState") }：{ t("married") }</p> : ""
				}
				{
					ucenterinfo.marry == 3 ? <p className="main-box home_data_t1">{ t("emotionalState") }：{ t("love") }</p> : ""
				}
				{
					ucenterinfo.marry != 1 && ucenterinfo.marry != 2 && ucenterinfo.marry != 3 ? <p className="main-box home_data_t1">{ t("emotionalState") }：{ t("secrecy") }</p> : ""
				}
				
				<p className="main-box home_data_t2">{ t("location") }：{ ucenterinfo.country }</p>
				<p className="main-box home_data_t2">{ t("work") }：{ ucenterinfo.work }</p>
				<p className="main-box home_data_t2">{ t("language") }：{ infoLg }</p>
				<p className="main-box home_data_t2">{ t("sign") }: { ucenterinfo.sign }</p>
			</div>
			<div className="main-box home_data_pt">
				<p className="main-box home_tab_title">{ t("getGift") }</p>
				{
					type == 1 ? <div className="main-box main-flex main-aic home_gift" onClick={billOpen.bind(this,ucenterinfo.uid)}  >
					{
						giftList.length > 0 ? <div className="main-box main-f1">
						{
							giftList.map((item,index)=>{
								return (<div key={index} className="main-box main-inline main-relative home_mr">
							<div className="main-box main-hg home_gift_li">
								<img src={item.icon!='' ? item.icon : giftsImg} />
							</div>
						</div>)
							})
						}
						
					</div> : <div className="main-f1"  onClick={billOpen.bind(this,'')}>
						<p className="home_one">{t("sendfirst")}</p>
						<p className="home_no">{t("noshowGift")}</p>
					</div>
					}
					<img className="home_right"  src={icon_right}/>
				</div> : <div className="main-box main-flex main-aic home_gift" onClick={giveGift.bind(this,ucenterinfo.uid)}  >
				{
					giftList.length > 0 ? <div className="main-box main-f1">
					{
						giftList.map((item,index)=>{
							return (<div key={index} className="main-box main-inline main-relative home_mr"  >
							<div className="main-box main-hg home_gift_li">
								<img src={item.icon!='' ? item.icon : giftsImg} />
							</div>
						</div>)
						})
					}
						
					</div> : <div className="main-f1"  onClick={giveGift.bind(this,'')}>
						<p className="home_one">{t("sendfirst")}</p>
						<p className="home_no">{t("noshowGift")}</p>
					</div>
				}
					<img className="home_right"  src={icon_right}/>
				</div>
				}
				
				
				
			</div>
			<div className="main-box home_data_pt">
				<p className="main-box home_tab_title">{ t("followAnchor") }</p>
				{
					type == 1 ? <div className="main-box main-flex main-aic home_gift" onClick={jumpTo.bind(this,'followOther.html')}>
					{
						followList.length > 0 ? <div className="main-box main-f1">
						{
							followList.map((item,index)=>{
								return (<div className="main-box main-inline main-relative home_mr" key={index}>
							<img className="main-box main-w10 main-hg main-vat main-radius home_slide_img" src={item.uinfo.avatar!="" ? item.uinfo.avatar : defaultImg} />
							{
								item.playstatus == true ? <div className="main-box home_follow_gif">
								<div className="main-box main-inline home_follow_icon">
									<img src={livegif} />
								</div>
							</div> : ""
							}
							
						</div>)
							})
						}
						
					</div> : <p className="main-f1 home_no">{ t("nofocuson") }</p>
					} 
					<img className="home_right" src={icon_right} />
				</div> : <div className="main-box main-flex main-aic home_gift" onClick={tapFollowAnchor}>
				{
					followList.length > 0 ? <div className="main-box main-f1">
					{
						followList.map((item,index)=>{
							return (<div className="main-box main-inline main-relative home_mr" key={index}>
							<img className="main-box main-w10 main-hg main-vat main-radius home_slide_img" src={item.uinfo.avatar!="" ? item.uinfo.avatar : defaultImg} />
							{
								item.playstatus == true ? <div className="main-box home_follow_gif">
								<div className="main-box main-inline home_follow_icon">
									<img src={livegif} />
								</div>
							</div> : ""
							}
							
						</div>)
						})
					}
						
					</div> : <p className="main-f1 home_no">{ t("nofocuson")}</p>
				} 
					<img className="home_right" src={icon_right} />
				</div>
				}
				
				
			</div>
			
			
		</div>
	);
};

export default HomeInfo; 