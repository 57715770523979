//编辑个人信息
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import {infoedit } from "../../../../request/api.js";
import Toast from "../../../../components/toast/toast.js";
import icon_back from "../../../../img/icon_back.png";
import icon_right from "../../../../img/home/icon_right.png"
const FeelEdit: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const [userInfo,setuserInfo] = useState({});
	 
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr'));
		setuserInfo(userArr);
		
	},[]);
	const tapChoose=(id)=>{
		var darr = {
			marry: id,
		}
		infoedit(darr).then((res)=>{
			if(res.errno == 0){
				Toast.fail(t("tianjiaok"))
				navigate(-1); 
				//window.location.href = 'edit.html';
			}else{
				Toast.fail(t("loadFail"));
			 
			}
		})
	}
	return (
		<div className="edit-page">
			<header className="header">
				<div className="main-box main-flex main-aic head" >
					<div className="main-box head_lf">
						<div onClick={() => navigate(-1)}><img src={icon_back} className="head_back"/></div>
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("emotionalState")}</div>
					<div className="main-box head_rt" ></div>
				</div>
			</header>
			<div class="main-bg index_pl index_mt">
				<p class="main-bb index_pr edit_feel" onClick={tapChoose.bind(this,0)}>{t("secrecy")}</p>
				<p class="main-bb index_pr edit_feel" onClick={tapChoose.bind(this,1)}>{t("single")}</p>
				<p class="main-bb index_pr edit_feel" onClick={tapChoose.bind(this,2)}>{t("married")}</p>
				<p class="main-bb index_pr edit_feel" onClick={tapChoose.bind(this,3)}>{t("love")}</p>
			</div>
		</div>
	);
};

export default FeelEdit; 