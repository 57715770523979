// 黑名单
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"; 
import {timestampToTime} from "../../../utils/utils.js";
import {blacklist} from "../../../request/api.js";
import Toast from "../../../components/toast/toast.js";
import { Scroll } from "../../../components/scroll/scroll.tsx";
import icon_back from "../../../img/icon_back.png";
import f_02png from "../../../img/online/f_02.png"
import f_01png from "../../../img/online/f_01.png";
import icon_boy from "../../../img/home/icon_boy.png";
import icon_girl from "../../../img/home/icon_girl.png";
import defaultImg from "../../../img/index/default.png";
import nullImg from "../../../img/giftd/heimingdan.png";
let dataArr = [];
let listShow = true;
const BlackList: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	let page = 1;
	const [list,setlist]=useState([]);
	
	useEffect(() => {
		 
		 
		dataArr = [];
		listAjax();
	}, []);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  listAjax();
		  }
	    
	  }
	};
	const listAjax=()=>{
		let darr = {
			pageno: page,
			pagenum: 30,
		}
		blacklist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					}
					for(var i=0;i<lists.length;i++){
						lists[i].addtime = timestampToTime(lists[i].addtime);
					}
					 
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				} 
							
			}else{
				Toast.fail(res.errmsg);
			}
		})
		
	}
	const tapHome=(uid)=>{
		navigate("/page/home/index", { state: { uid: uid,type: 2 } });
	}
	const navBack=()=>{
		navigate(-1)
	}
	return (
		<div>
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("blacklist")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="fans">
			{
				list.length>0 ? <ul className="main-box">
					<Scroll
					  height="100%"
					  load={handleScroll}
					  isFirstLoad={false}
					>
					{
						list.map((item,index)=>{
							return (<li className="main-flex main-aic index_pl" key={index} onClick={tapHome.bind(this,item.uinfo.uid)}> 
								<div className="main-relative fans_lf  news_online news_unline" >
									<img className="main-radius main-w10" src={item.uinfo.avatar} onError={(e: any) => { e.target.src = defaultImg }} />
								</div> 
								<div className="main-bb main-f1 main-flex main-aic main-over index_pr fans_rt">
									
									<div class="main-f1" >
										<div class="main-flex main-aic">
											<div class="main-f1 main-flex main-aic">
												<p class="fans_name">{ item.uinfo.nick }</p>
											</div>
											<p class="fans_sign"></p>
										</div>
										<p class="fans_sign">{ item.addtime }</p>
									</div>
									
								</div>
							</li>)
						})
					}
					
					</Scroll>
				</ul> : <div className="main-box-img">
					<img  src={nullImg} />
					<p >{t("noData")}</p>
				</div>
			} 
			</div>
		</div>
	);
};

export default BlackList; 