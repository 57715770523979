// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import {roomusers} from "../../request/api.js"; 
import defaultImg from "../../img/index/default.png";
import svip_01 from "../../img/rank/vipsvip/s_01.png";
import vip_01 from "../../img/rank/vipsvip/v_01.png";
import Swiper from 'swiper'; 
import "swiper/css";
import "../../css/swiper.css"; 
import img_leve1 from "../../img/rank/3/r_11.png";
import img_leve2 from "../../img/rank/3/r_12.png";
import img_leve3 from "../../img/rank/3/r_13.png";
import img_leve4 from "../../img/rank/3/r_14.png";
import img_leve5 from "../../img/rank/3/r_15.png";
import img_leve6 from "../../img/rank/3/r_16.png";
var swiperUser;
const UserList: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate();
	const channel = props.channel;
	const [list,setlist] = useState([]); 
	useEffect(() => { 
		roomusersAjax();
		setTimeout(function(){
			swiperUser = new Swiper(".swiper-user", {
			  autoHeight: true,
			  freeMode: true,
			  slidesPerView: 4,
			  spaceBetween: 5
			});
		},1000);
		
	},[]);
	const roomusersAjax=()=>{
		var darr = {
			hostid: channel,
		}
		roomusers(darr).then((res)=>{
			if(res.errno == 0) {
				setlist(res.data.items);
			 
			}
		})
	}
	return (
		<div className="swiper-container main-f1 main-hg live_watch swiper-user">
			<div className="swiper-wrapper">
			{
				list.map((item,index)=>{
					return (<div className="swiper-slide main-relative main-tac" key={index}>
					<img className="main-radius main-vat" width="34" height="34" src={item.avatar} onError={(e: any) => { e.target.src = defaultImg }} />
					{
						item.svip == 1 ? <div className="main-w10 live-top-user">
						<img className="main-vat" width="30" src={svip_01} />
					</div> : ""
					} 
					{
						item.vip == 1 && item.svip == 0 ? <div className="main-w10 live-top-user">
						<img className="main-vat" width="30" src={vip_01} />
					</div> : ""
					}
					{
						item.svip == 0 && item.vip == 0 ? <div className="live-top-svip">
					
						{
							20 > item.level > 0 ? <img src={img_leve1} width="11" /> : ""
						}
						{
							30 > item.level > 19 ? <img src={img_leve2} width="11" /> : ""
						}
						{
							40 > item.level > 29 ? <img src={img_leve3} width="11" /> : ""
						}
						{
							50 > item.level > 39 ? <img src={img_leve4} width="11" /> : ""
						}
						{
							61 > item.level > 49 ? <img src={img_leve5} width="11" />  : ""
						}
						{
							item.level > 60 ? <img src={img_leve6} width="11"  />  : ""
						} 
					</div> : ""
					} 
				</div>)
				})
			}
				
			</div>
		</div>
	);
};

export default UserList; 