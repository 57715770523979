// 密码
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {usermyinfo} from "../../../request/api.js";
import MyGuard from "../../../components/centre/guard/myguard.js";
import GuardMy from "../../../components/centre/guard/guardmy.js";
import icon_back from "../../../img/icon_back.png";
import nullImg from "../../../img/giftd/visitor@2x.png";
import ruleimg from "../../../img/guard/ruleimg.png"

import Swiper from 'swiper'; 
import "swiper/css";
import "../../../css/swiper.css";

var swiperGuard;
const GuardPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const [userId,setuserId] = useState(0);
	const [tabId,settabId] = useState(0);
	const [wHeight,setwHeight] = useState(0);
	const [ucenterinfo,setucenterinfo] = useState({}); 
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr'));
		setuserId(userArr.uid);
		let Height = document.documentElement.clientHeight - 46 - 46;
		infoAjax();
		setwHeight(Height);
		  swiperGuard = new Swiper(".swiper-guard", { 
			  slidesPerView: 'auto', //同时显示的个数
			  //width: 80,
			  spaceBetween: 15, //两个slide之间的距离
			  freeMode: true,
			  autoHeight: true,
			  observer: true,
			  observeParents: true,
		      onTransitionEnd: function(swiper) { 
		  		settabId(swiper.activeIndex + 1)
		      } 
		  });
	},[]);
	const infoAjax=()=>{
		 
		usermyinfo({}).then((res)=>{ 
			setucenterinfo(res.data.uinfo); 
		})
	}
	const tapTabs=(t)=>{
		swiperGuard.slideTo(t);
		settabId(t)
	}
	const navBack=()=>{
		navigate(-1)
	}
	return (
		<div>
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("indexTitle")}</div>
					<div className="main-box head_rt "> <img src={ruleimg} className="ruleimg-png" /></div>
				</div>
			</header>
			<div className="index_content">
				<div className="index_up">
				    <div className="main-flex main-aic main-w10 main-bg index_tab">
				        <div className="main-f1 main-ellipsis main-over main-tac main-relative index_tabs" onClick={tapTabs.bind(this,0)}>
				            <span className={tabId == 0 ? "index_title index_active" : "index_title"}>{t("indexMe")}</span>
							{
								tabId==0 ? <div className="main-w10 index_xian">
				                <b className="main-inline main-vat"></b>
				            </div> : ""
							}
				            
				        </div>
				        <div className="main-f1  main-ellipsis main-over main-tac main-relative index_tabs" onClick={tapTabs.bind(this,1)}>
				            <span className={tabId == 1 ? "index_title index_active" : "index_title"}>{t("indexMyGuard")}</span>
				            {
				            	tabId==1 ? <div className="main-w10 index_xian">
				                <b className="main-inline main-vat"></b>
				            </div> : ""
				            }
				        </div>
				    </div>
				</div>
				<div class="index_slide">
				    <div class="swiper-container index_swiper swiper-guard" style={{ height: wHeight + "px" }}>
				        <div class="swiper-wrapper" style={{ height: wHeight + "px" }}> 
				            <div class="swiper-slide">
								<GuardMy uid={userId} info={ucenterinfo}></GuardMy>
							</div>
							<div class="swiper-slide">
								<MyGuard uid={userId} info={ucenterinfo}></MyGuard>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GuardPage; 