import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./pop.css";
function TipsPop(props) {
  const { t } = useTranslation();

  const tapBtn = (type) => {
    props.tapBtn(type);
  };
  return (
    <div>
      <div className="tips-modal-mask"></div>
      <div className="tips-modal-content">
        <div className="tips-modal-top">{props.tips}</div>
        <div className="tips-modal-bottom">
          <div className="tips-bottom-btn1" onClick={() => tapBtn(1)}>
            {t("cancel")}
          </div>
          <div className="tips-bottom-btn2" onClick={() => tapBtn(2)}>
            {props.delete}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TipsPop;