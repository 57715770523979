// 帮助
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router"; 
import icon_back from "../../../img/icon_back.png";
import icon_03 from "../../../img/mine/icon_03.png";
import logoImg from "../../../img/public/logo.png"
const AboutPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	useEffect(() => {
		let userArr = JSON.parse(localStorage.getItem('userArr')); 
	}, []);
	const navBack=()=>{
		navigate(-1)
	}
	const navPact=(m)=>{
		navigate("/pact/"+m);
	}
	return (
		<div className="about">
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf" onClick={navBack}>
						<img src={icon_back} className="head_back" />
					</div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("about")}</div>
					<div className="main-box head_rt"></div>
				</div>
			</header>
			<div className="main-box"> 
				<div className="main-tac setting_up">
					<img width="80" src={logoImg} />
					<p className="setting_title">Anycupid</p>
					<p className="setting_version">{t("version")} 1.0.1</p>
				</div>
				 
				<ul className="setting_list">
					<li className="  index_pl setting_li">
						<div className="main-bb main-flex main-aic index_pr setting_content" onClick={navPact.bind(this,"service")}>
							<p className="main-f1 setting_name">{t("Agreement")}</p>
							<img width="16" src={icon_03} />
						</div>
					</li>
					<li className=" index_pl setting_li">
						<div className="main-bb main-flex main-aic index_pr setting_content" onClick={navPact.bind(this,"privacy")}>
							<p className="main-f1 setting_name">{t("Policy")}</p>
							<img width="16" src={icon_03} />
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default AboutPage; 