//信息通知
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import { useLocation } from "react-router";
import { Scroll } from "../../../components/scroll/scroll.tsx";
import {dotcleanall,dotlist,chatlist} from "../../../request/api.js";
import {floorTime} from "../../../utils/utils.js";
import defaultImg from "../../../img/index/default.png";
import icon_rt from "../../../img/news/icon_rt.png";
import icon_new from "../../../img/news/icon_new.png";
let dataArr = [];
let listShow = true;
const ChatPage: React.FunctionComponent = () => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	let page = 1;
	const [newsNum,setnewsNum] = useState(0);
	const [noticeNum,setnoticeNum] = useState(0);
	const [List,setlist] = useState([]);
	useEffect(() => { 
		dataArr = [];
		listAjax();
	}, []);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  listAjax();
		  }
	    
	  }
	};
	const listAjax=()=>{
		var darrf = {
			pageno: page,
			pagenum: 20,
		}
		chatlist(darrf).then((res)=>{
			if(res.errno == 0) { 
				let num = newsNum;
				var newlist=[];
				let lists = res.data.items;
				listShow = true;
				if(lists.length<10){
					listShow = false;
				}
				for(var i=0;i<lists.length;i++){
					lists[i].lasttime = floorTime(lists[i].lasttime);
					lists[i].list = [];
					num = num + lists[i].unread; 
					newlist.push(lists[i]);
				}
				setnewsNum(num)
				dataArr = dataArr.concat(newlist);
				setlist(dataArr); 
				
			}
		})
	}
	const dotCleanall=()=>{
		dotcleanall({}).then((res)=>{
			if(res.errno == 0) { 
				setnewsNum(newsNum-noticeNum);
				setnoticeNum(0);  
			}
		})
	}
	const jumpNotice=()=>{
		
	}
	const tapHome=(uid)=>{
		navigate("/page/home/index", { state: { uid: uid,type: 2 } });
	}
	const tapchatHome=(tid,key)=>{
		
	}
	return (
		<div className="cont-discovery">
			<header className="header">
				<div className="main-box main-flex main-aic head head-top-div" >
					<div className="main-box head_lf"></div>
					<div className="main-box main-f1 main-tac main-ellipsis head_md header-top-pass">{t("news")}</div>
					<div className="main-box head_rt"><img src={icon_rt} onClick={dotCleanall} className="head_back" /></div>
				</div>
			</header>
			<div className="chat-content chat_list">
				<div className="main-box news_li">
					<a className="main-flex main-aic" href="javascript:;" click="jumpNotice()">
						<img className="news_icon" src={icon_new} />
						<div className="main-box main-f1 main-over news_content">
							<div className="main-box main-flex main-aic news_up">
								<p className="main-box main-f1">{ t("notice") }</p>
								<span></span>
							</div>
							<div className="main-box main-flex main-aic news_down">
								<p className="main-box main-f1 main-ellipsis">{ t("newNotice") }</p>
								{
									noticeNum > 0 ? <span className="main-inline main-tac">{ noticeNum }</span> : ""
								}
								
							</div>
						</div>
					</a>
				</div>
				<ul className="main-box">
				<Scroll
				  height="100%"
				  load={handleScroll}
				  isFirstLoad={false}
				>
				{
					List.map((item,index)=>{
						return (<li className="main-flex main-aic main-box news_li" key={index}>
						
							<div onClick={tapHome.bind(this,item.uinfo.uid)}  className={ item.uinfo.online == 1 ?  "main-box main-flex main-aic main-jcc news_online news_unline" : "main-box main-flex main-aic main-jcc news_online" }>
								<img src={item.uinfo.avatar}  onError={(e: any) => { e.target.src = defaultImg }} />
							</div>
							<div className="main-box main-f1 main-over news_content" onClick={tapchatHome.bind(this,item.tid,index)}>
								<div className="main-box main-flex main-aic news_up">
									<p className="main-box main-f1">{ item.uinfo.nick }</p>
									<span>{ item.lasttime }</span>
								</div>
								<div className="main-box main-flex main-aic news_down">
									<p className="main-box main-f1 main-ellipsis">{ item.lastmsg }</p>
									{
										item.unread>0 ? <span className="main-inline main-tac newsNum-user">{ item.unread }</span> : ""
									}
									
								</div>
							</div>
						
							
					</li>)
					})
				}
				</Scroll>	
				</ul>
			</div>
		</div>
	);
};

export default ChatPage; 