import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import {ranklist} from "../../../request/api.js";
import { Scroll } from "../../../components/scroll/scroll.tsx"; 
import t_01_png from "../../../img/live/rank/t_01.png";
import t_02_png from "../../../img/live/rank/t_02.png";
import t_03_png from "../../../img/live/rank/t_03.png";
import f_04_png from "../../../img/online/f_02.png";
import icon_live from "../../../img/home/icon_live.png";
import live_gif from "../../../img/home/live.gif";
import defaultsimg from "../../../img/public/defaults.png";
import f_01_png from "../../../img/online/f_01.png";
import f_02_png from "../../../img/online/f_02.png";
import yonghu from "../../../img/null/yonghu.png"

let dataArr = [];
let listShow = true;
const RankList: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const cateType = props.cat;
	const rankCate = props.rankid;
	const [list,setlist] = useState([]);
	let page = 1;
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => {
		let width = document.documentElement.clientWidth;
		let w = (width - 20 - (((width - 20) * 0.014) * 2)) / 3
		setwHeight(w);
		dataArr = [];
		listAjax();
	}, [cateType]);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  listAjax();
		  }
	    
	  }
	};
	const listAjax=()=>{
		let outcome = Math.round(new Date().getTime()/1000).toString();
		let darr = { 
			ranktype: cateType+"_"+rankCate,
			date: outcome,
			pageno: page,
			pagenum: 30,
		}
		ranklist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					}
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				} 
							
			} 
		})
		
	}
	const liveOpen=(uid)=>{
		
	}
	const tapCancelFollow=()=>{
		
	}
	return (
		<div className="rank-list">
			 
			{
				list.length>0 ? <ul className="swiper-ul main-box">
				<Scroll
				  height="100%"
				  load={handleScroll}
				  isFirstLoad={false}
				>
				{
					list.map((item,index)=>{
						return (<li className="main-flex main-aic rank_li" key={index}>
						<div className="main-tac rank_lf">
							{
								index == 0 ? <img className="main-w10" src={t_01_png} /> : ""
							}
							{
								index == 1 ? <img className="main-w10" src={t_02_png} /> : ""
							}
							{
								index == 2 ? <img className="main-w10" src={t_03_png} /> : ""
							}
							{
								index>2 ? <span className="rank_num">{ index + 1 }</span> : ""
							}
						</div>
						<div className="main-bb main-f1 main-flex main-aic main-over index_pr fans_rt">
							<div className="main-relative fans_lf">
								<img className="main-radius main-w10" src={item.uinfo.avatar} onError={(e: any) => { e.target.src = defaultsimg }} />
								{
									item.playstatus==1 ? <div className="follow_live">
									<img className="main-vat main-w10" src={icon_live} />
									<img className="follow_gif" src={live_gif} />
								</div> : ""
								}
								
							</div>
							<div className="main-f1 main-over">
								<div className="main-flex main-aic">
									<p className="fans_name">{ item.uinfo.nick }</p> 
								</div>
								<p className="main-ellipsis fans_sign">{ item.score }</p>
							</div>
							{
								item.isfollow==1 ? <div className="main-tac fans_yes" onClick={tapCancelFollow}>
								<img className="main-vat" src={f_02_png} />
							</div> : <div className="main-tac fans_no">
								<img className="main-vat" src={f_01_png} />
							</div>
							} 
						</div> 
				</li>)
					})
				}
				
				</Scroll>
			</ul> : <div className="main-box main-tac list_none">
				<img style={{paddingTop:"30px"}} src={yonghu} />
				<p style={{paddingTop:"10px"}}>{t('noData')}</p>
			</div>
			}
			
			
		</div>
	);
};

export default RankList; 