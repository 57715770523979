//
import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import Hls from 'hls.js';
import { PubSub } from 'pubsub-js';
import {createWebSocket,closeWebSocket,websocket} from '../../../components/websocket.js';
import GradeLevel from "../../../components/home/gradelevel.js";
import {usermyinfo,baseinfo,roomtempact,balance,baseuserinfo,followdel,followadd,usertoken,giftList,giftsend,messagesendfloat,messagesend,commonsystemmsg} from "../../../request/api.js";
import UserList from "../../../components/video/userlist.js"; 
import Socket from "../../../components/video/socket.js";
import Toast from "../../../components/toast/toast.js";
import glist from "../../../json/giftlist.json";
import defaultImg from "../../../img/index/default.png";
import svip_01 from "../../../img/rank/vipsvip/s_01.png";
import vip_01 from "../../../img/rank/vipsvip/v_01.png";
import chat_c_12 from "../../../img/chat/c_12.png";
import chat_baack from "../../../img/chat/c_21.png";
import chat_c_14 from "../../../img/chat/c_14.png";
import chat_c_13 from "../../../img/chat/c_13.png";
import pkbgImg from "../../../img/pkbg.png";
import partybgImg from "../../../img/partybg.png";
import chatImg from "../../../img/icon_liveroom_say_select.png";
import gift2xImg from "../../../img/chat/xin/gift2x.png";
import livec_29 from "../../../img/chat/c_29.png";
import livec_28 from "../../../img/chat/c_28.png";
import livec_58 from "../../../img/chat/c_58.png";
import gifttopvip from "../../../img/chat/gift/gifttopvip.png";
import gift_m1 from "../../../img/chat/gift/m1.png";
import gift_right from "../../../img/chat/gift/right.png";
import gift_pop_title01 from "../../../img/icon_gift_select.png";
import gift_pop_title00 from "../../../img/icon_gift_normal.png";
import gift_pop_title11 from "../../../img/icon_liveroom_privileges_select.png";
import gift_pop_title10 from "../../../img/icon_liveroom_privileges_normal.png";
import gift_giftvip from "../../../img/chat/gift/giftvip.png";
import gift_giftsvip from "../../../img/chat/gift/giftsvip.png";
import gift_m2 from "../../../img/chat/gift/m2.png";
import img_leve1 from "../../../img/rank/3/r_11.png";
import followimg from "../../../img/chat/followimg.png";
import floright from "../../../img/chat/floright.png"
//import 'videojs-flash';
import Swiper from 'swiper'; 
import "swiper/css";
import "../../../css/swiper.css";
import "./live.css";
let pullurl = "";
let player;
const LiveRoom: React.FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation(); 
	
	let channel = location.state.channel;
	const [userid,setuserid] = useState("");
	const [wHeight,setwHeight] = useState(0);
	const [wWidth,setwWidth] = useState(0);
	const [playstatus,setplaystatus]= useState(1);
	const [hostinfo,sethostinfo] = useState({});
	const [roominfo,setroominfo] = useState({});
	const [videoShow,setvideoShow] = useState(false);
	const [posterUrl,setposterUrl] = useState('');
	const [personnum,setpersonnum] = useState(0);
	const [isFocus,setisFocus] = useState(0);
	const [isFollow,setisFollow] = useState(0);
	const [userM,setuserM] = useState(0);
	const [clv,setclv] = useState(0);
	const [pkStatus,setpkStatus] = useState(0);
	const [partyStatus,setpartyStatus] = useState(0);
	const [doIrcarr,setdoIrcarr] = useState({});
	const [roomChatList,setroomChatList]= useState([]);
	const [inputContent,setinputContent] = useState("");
	const [chatLiveFly,setchatLiveFly] = useState(false);
	const [chatInput,setchatInput] = useState(false);
	const [giftShow,setgiftShow] = useState(false);
	const [gifttab,setgifttab] = useState(0);
	const [giftList0,setgiftList0]= useState([]);
	const [giftList1,setgiftList1]= useState([]);
	const [selectId,setselectId] = useState(0);
	const [atName,setatName] = useState('');
	const [systemmsg,setsystemmsg] = useState([]); 
	const [svipImgRoom,setsvipImgRoom] = useState('');
	const [svipRoom,setsvipRoom] = useState(false);
	let messageSocket=null;
	useEffect(() => { 
		let Height = document.documentElement.clientHeight;
		setwHeight(Height);
		let width = document.documentElement.clientWidth;
		setwWidth(width);
		myinfoAjax();
		usertokenAjax();
		roomtempactAjax();
		commonsystemmsgAjax()
		baseinfoAjax();
		balanceAjax();
		baseuserinfoAjax();
		giftList0Ajax();
		giftList1Ajax();
		toScrollTo()
		return () => {
			beforeDestroy(); 
			closeWebSocket(channel,userid);
		}
		 
	},[]);
	const commonsystemmsgAjax=()=>{
		commonsystemmsg({}).then((res)=>{
			if(res.errno == 0) {
				setsystemmsg(res.data.sysmsg);
			}
		})
	}
	const myinfoAjax=()=>{
		usermyinfo({}).then((res)=>{
			if(res.errno == 0) { 
				setuserid(res.data.uinfo.uid);
			}
		})
	}
	const usertokenAjax=()=>{
		usertoken({}).then((res)=>{
			if(res.errno == 0){ 
				var domain = res.data.ip;
				var port = res.data.port;
				var wsSign = res.data.sign;
				var wsTs = res.data.ts;
				var token = res.data.token;
				var wsuuid = res.data.uid
				var wsUrl="wss://ws.api.anycupid.com:443";
				console.log("wsUrl:",wsUrl)
				createWebSocket(wsUrl,wsuuid,'h5wapchat',wsSign,wsTs,channel);
				messageSocket = PubSub.subscribe('message',getMsg)
			}
		})
	} 
	const giftList0Ajax=()=>{
		var garr = {
			hostid: channel,
			position: "live",
			sp: 0,
		}
		giftList(garr).then((res)=>{
			if(res.errno == 0) {
				let listg=[];
				let gss=[];
				let giftarr = glist.data;
				var gifts = res.data.items;
				for(var i = 0; i < gifts.length; i++){
					for(var j = 0; j < giftarr.length; j++){
						if(gifts[i].id == giftarr[j].id){
							listg.push(giftarr[j]);
						}
					}
				}
				for(var i = 0; i < listg.length; i+=8){
					gss.push(listg.slice(i, i+8));
				}
				setgiftList0(gss);
			}
		}) 
	}
	const giftList1Ajax=()=>{
		var garr = {
			hostid: channel,
			position: "live",
			sp: 1,
		}
		giftList(garr).then((res)=>{
			if(res.errno == 0) {
				let listg=[];
				let gss=[];
				let giftarr = glist.data;
				var gifts = res.data.items;
				for(var i = 0; i < gifts.length; i++){
					for(var j = 0; j < giftarr.length; j++){
						if(gifts[i].id == giftarr[j].id){
							listg.push(giftarr[j]);
						}
					}
				}
				for(var i = 0; i < listg.length; i+=8){
					gss.push(listg.slice(i, i+8));
				}
				setgiftList1(gss);
			}
		}) 
	}
	//入场函数
	const goRoom=(msg)=>{
		
	}
	const doIrcMsg=(msg)=>{
		if(msg.vip == 1){
			goRoom(msg);
		}
	}
	 
	const intoroom=(msg)=>{
		let msgbody = JSON.parse(msg.body);
		
		if(msgbody.from.vip==1){
			doIrcMsg(msgbody.from);
		}else if(msgbody.from.svip==1){
			setdoIrcarr(msgbody.from);
			setsvipRoom(true)
			var g= 0;
			var gn =1;
			var dg = setInterval(function() {
			//var giftNumber = room_img.length;
			g++;
			if (g >= 80) {
				g=0;
				if(gn == 1){
					clearInterval(dg);
					setsvipRoom(false);
					return false;
				}
				gn++;
				return false;
			}
			
			 let svipImg = "https://img.anycupid.com/sviproom/"+g+".png";
			 setsvipImgRoom(svipImg)
			}, 100);
		}else{
			msg.body = msgbody;
			let list = roomChatList;
			console.log("roomChatList2:",list)
			list.push(msg);
			setroomChatList(list); 
			}
	}
	const roomgift=(msg)=>{
		
	}
	const getMsg=(topic,message)=>{
	    
		message = JSON.parse(message);
		console.log('ws获取：',message)
		toScrollTo();
		if(message.action == "personnum"){ 
			setpersonnum(message.body.number);
		}else if(message.action == "leaveroom"){
		
		}else if(message.action=='intoroom'){
			intoroom(message);
			
		}else if(message.action=='roomgift'){
			//that.roomgift(message);
					
		}else if(message.action=='bannergit'){
			let msgbody = JSON.parse(message.body);
			message.body = msgbody;
			let list = roomChatList;
			list.push(message);
			setroomChatList(list);  
		}else if(message.action=='roomstatus'){
			//that.roomsTatus(message.body);
			closeWebSocket(channel,userid);
			
			//that.roomChatList.push(message);
		}else if(message.action=='pkstart'){
			setpkStatus(1) 
			var video = document.getElementById('liveVideo');
			var pkWidth = wWidth/3*2;
			video.style.height = pkWidth+"px";
			video.style.marginTop = "115px";
			
		}else if(message.action=='pkfinish'){
			setpkStatus(0)  
			var video = document.getElementById('liveVideo');
			video.style.height = wHeight+"px";
			video.style.marginTop = "0px";
		}else if(message.action=='chmsg'){
			let msgbody = JSON.parse(message.body);
			message.body = msgbody;
			let list = roomChatList;
			console.log("roomChatList1:",list)
			list.push(message);
			setroomChatList(list);
		}
	}
	const roomtempactAjax=()=>{
		var darr = {
			hostid: channel,
		}
		roomtempact(darr).then((res)=>{
			setpkStatus(res.data.pk.status);
			setpartyStatus(res.data.party.status);
		})
	}
	const beforeDestroy=()=> {
	    if (player) {
	      player.dispose(); // Removing Players,该方法会重置videojs的内部状态并移除dom
	    }
	}
	const baseinfoAjax=()=>{
		let darr = {
			hostid: channel,
		}
		baseinfo(darr).then((res)=>{
			if(res.errno == 0) {
				console.log('进直播间获取房间信息', res);
				sethostinfo(res.data.hostinfo);
				setroominfo(res.data.roominfo);
				setposterUrl(res.data.roominfo.photo);
				setplaystatus(res.data.roominfo.playstatus);
				let url = res.data.roominfo.hls_addr;
				pullurl = url.replace(/http:/, "https:");
				console.log('进直播间m3U8流：', pullurl);
				intoVideo();
			}
		})
		
	}
	
	const intoVideo=()=>{
		const video = document.getElementById("liveVideo"); 
		if (!video) { 
			setTimeout(() => {
			  intoVideo()//延迟500ms继续执行这个初始化函数，继续判断是否能获取到
			}, 500)
		    return
		}
		if (Hls.isSupported()) {
		  var hls = new Hls();
		  hls.loadSource(pullurl);
		  hls.attachMedia(video);
		  hls.on(Hls.Events.MANIFEST_PARSED, function() {
		  	//console.log('加载成功'); 
		      var playPromise = video.play();
		      if (playPromise !== undefined) {
		      	playPromise.then(_ => {
					setvideoShow(false); 
		  		}).catch(error => {
		  			setvideoShow(true);
		  		});
		  	}
		  });
		  hls.on(Hls.Events.ERROR, (event, data) => {
		  	 console.log(event, data);
		  	if (data.fatal) {
		  		//console.log('加载1',data.fatal);
		  		 switch(data.type) {
		  		   // 网络错误导致的错误，据观察这种类型的错，占比最高
		  		   case Hls.ErrorTypes.NETWORK_ERROR:
		  		    // console.log("需要加载");
		  			 // 根据自己业务（心跳/错误次数 需要加载还是重新请求，这里只给出简单的，加载情况作为示例）
		  			 hls.startLoad();
		  			 video.play();
		  			 break;
		  		   case Hls.ErrorTypes.MEDIA_ERROR:
		  		   //console.log("多媒体错误");
		  			 // 多媒体错误
		  			 hls.recoverMediaError();
		  			 
		  			 break;
		  		   default:
		  		  // console.log("重新请求");
		  			hls.destroy();
		  			video.play();
		  			break;
		  		 }
		  	}else{
		  		//console.log('加载2',data.fatal);
		  		switch(data.details) {
		  		  // 网络错误导致的错误，据观察这种类型的错，占比最高
		  			case 'bufferStalledError':
		  			//console.log("HLS视频断电");
		  			video.play();
		  			break;
		  			case 'levelLoadTimeOut':
		  			video.pause();
		  			hls.destroy();
		  			hls = null;
		  			setvideoShow(false); 
		  			break;
		  			default:
		  		 
		  			//console.log("重新请求");
					video.play();
		  			break;
		  		}
		  	}
		  	// 监听出错事件
		  });
		}else if (video.canPlayType('application/vnd.apple.mpegurl')) { 
			video.src = pullurl;
			video.addEventListener('loadedmetadata', function() {
				video.play();
			});
		}
	}
	const tapCardMsg=(uid)=>{
		
	}
	const balanceAjax=()=>{
		balance({}).then((res)=>{
			if(res.errno == 0) {
				setuserM(res.data);
			} 
		})
	}
	const baseuserinfoAjax=()=>{
		var darr = {
			hostid: channel,
		}
		baseuserinfo(darr).then((res)=>{
			if(res.errno == 0) {
				setisFocus(res.data.is_follow);
				setclv(res.data.role.lv);
			}
		})
	}
	const chatback=()=>{
		beforeDestroy();
		navigate(-1)
	}
	const followAdd=(id,t)=>{
		let darr = {
			hostid: id,
			position: 'live'
		}
		followadd(darr).then((res)=>{
			if(res.errno == 0){
				setisFollow(1) 
				if(id == channel ){
					setisFocus(1);	 	
				}
			}else if(res.errno == 801){
				followAdd();
			}else{
				//that.opendivLogin();
			}
		})
	}
	const followxiao=(id)=>{
		let darr = {
			hostid: id,
		}
		followdel(darr).then((res)=>{
			if(res.errno == 0){
				setisFollow(0)
				if(id == channel ){
					setisFocus(0);	
				}
				
			}
		})
	}
	const tapBtns=(t)=>{
		if(t==1){
			setchatInput(true)
		}else{
			
			setgiftShow(true)
			var giftSon = new Swiper('.gift-tab-0', {
				pagination: {
				   el: '.swiper-pagination-0',
				   clickable: true,
				  },
				slidesPerView: 1, //同时显示的个数
				spaceBetween: 10, //两个slide之间的距离
				loop: false, //是否循环 
			});
		}
	}
	const chatInputClose=()=>{
		setchatInput(false);
	}
	const giftPopClose=()=>{
		setgiftShow(false);
		
	}
	const sendLiveFly=()=>{
		if(chatLiveFly){
			setchatLiveFly(false)
			setinputContent(t("chattxt")); 
		}else{
			setchatLiveFly(true)
			setinputContent(t("chatgols")); 
		}
	}
	const sendfloat=()=>{
		var darr = {
			msg: inputContent,
			hostid: channel,
		}
		messagesendfloat(darr).then((res)=>{
			if(res.errno == 0) {
				setchatInput(false);
				setchatLiveFly(false)
				setinputContent('');
				//tapFocus();
			}else{
				Toast.fail(res.errmsg) 
			}
		})
	}
	const tapSendMsg=()=>{
		var darr={};
		if(atName==''){
			darr = {
				msg: inputContent,
				hostid: channel,
			}
		}else{
			darr = {
				msg: "@ " + atName + "  "+inputContent,
				hostid: channel,
			}
		}
		messagesend(darr).then((res)=>{
			if(res.errno == 0) { 
				setchatInput(false); 
				setinputContent('');
			}else{
				Toast.fail(res.errmsg) 
			}
		})
		
	}
	const sendMsg=()=>{
		if(inputContent!==''){
			if(chatLiveFly){
				sendfloat();
			}else{
				tapSendMsg();
			}
		}else{
			Toast.fail(t("chattxt")) 
		}
	}  
	const payvip=()=>{
		navigate("/page/recharge/vip");
	}
	const sendGift=()=>{
		var darr = {
			position: "live",
			giftid: selectId,
			giftnum: 1,
			tid: channel,
		}
		giftsend(darr).then((res)=>{
			if(res.errno == 0) {
				setuserM(res.data.giftRet.balance);
				tapHideGift();
			}else if(res.errno == 106002){
				Toast.fail(res.errmsg) 
			}else{
				Toast.fail(t('selectGift'));
			}
		})
	}
	const tapHideGift=()=>{
		setgiftShow(false);
		setselectId(0)
	}
	const giftTab=(t)=>{
		setgifttab(t)
		if(t==0){
			var giftSon = new Swiper('.gift-tab-0', { 
				pagination: {
				   el: '.swiper-pagination-0',
				   clickable: true,
				  },
				slidesPerView: 1, //同时显示的个数
				spaceBetween: 10, //两个slide之间的距离
				loop: false, //是否循环 
			});
		}else{
			var giftSon = new Swiper('.gift-tab-1', {
				pagination: {
				   el: '.swiper-pagination-1',
				   clickable: true,
				  },
				slidesPerView: 1, //同时显示的个数
				spaceBetween: 10, //两个slide之间的距离
				loop: false, //是否循环 
			});
		}
	}
	const tapSelectGift=(gid)=>{
		setselectId(gid)
	}
	const toScrollTo = () => {
	  setTimeout(function () {
	    var div = document.getElementById("live_Chat");
	    div.scrollTop = 99999999;
	  }, 500);
	};
	return (
		<div className="chatLive">
			{
				playstatus!=0 ? <div className="main-w10 main-hg main-relative main-over" style={{ height: wHeight + "px" }}>
					<div className="main-hg">
						<div className="main-absolute main-over chat-div-video" style={{ height: wHeight  + "px", width: wWidth  + "px" }}>
							{
								pkStatus==1 ? <img className="main-w10 main-hg live-video-bg-img" style={{ height: wHeight  + "px", width: wWidth  + "px" }} src={pkbgImg} /> : ""
							}
							{
								partyStatus==1 ? <img className="main-w10 main-hg live-video-bg-img" src={partybgImg} /> : ""
							} 
							<video id="liveVideo" className={[ pkStatus==0 && partyStatus==0 ? 'video-js video-div' : 'video-pk'].join(' ')} poster={posterUrl} 
							webkit-playsinline="true" playsinline="true" x5-video-player-type="h5" x5-video-orientation="h5" x5-video-player-fullscreen="true" x-webkit-airplay="true" autoplay preload="auto" loop="loop" rendersize="1"
							rendersize="1" width={wWidth } height={wHeight } style={{ height: wHeight  + "px", width: wWidth + "px" }} ></video>
						</div>
					</div>
					<div className="main-box">
						<div className="main-w10 live-top-div">
							<div className="main-flex main-aic bill_top">
								<div className="main-flex main-aic main-hg live-top-bg">
									<img onClick={tapCardMsg.bind(this,hostinfo.uid)} className="main-radius main-vat" width="34" src={hostinfo.avatar}  onError={(e: any) => { e.target.src = defaultImg }} />
									{
										hostinfo.svip == 1 ? <div className="main-w10 live-top-svip">
											<img className="main-vat" width="30" src={svip_01} />
										</div> : ""
									}
									{
										hostinfo.vip == 1 && hostinfo.svip == 0 ? <div className="main-w10 live-top-svip">
											<img className="main-vat" width="30" src={vip_01} />
										</div> : ""
									}
									<div className="main-f1 live-top-nick">
										<p className="main-ellipsis live-top-font">{ hostinfo.nick }</p>
										<span className="live-top-num" >{ personnum }</span>
									</div>
									{
										isFocus == 0 ? <div onClick={followAdd.bind(this,hostinfo.uid,1)} className="notice_gift_lf">
										<img className="main-va" width="30" src={chat_c_12} />
									</div> : ""
									}
									
								</div>
								<div className="main-f1"></div>
								<div className="main-w5 main-flex main-aic main-hg margin-top">
									<UserList channel={channel}></UserList>
									<img onClick={chatback} src={chat_baack} className="main-va ios_icon" width="15" />
								</div>
							</div>
							<div className="main-flex main-aic tourist_duration"> 
								<div className="main-flex main-aic live-banner-receive">
									<img className="main-vat" width="14" src={chat_c_14} />
									<p className="live-banner-receive-font" >{ roominfo.receive }</p>
									<img className="main-vat" width="12" src={chat_c_13} />
								</div>
								<div className="main-f1"></div>
								<p className="live-id-font">ID:{ hostinfo.uid }</p>
							</div>
						</div>
						<div id="live_Chat" className="chat_socket">
							<div>
							{
								systemmsg.map((item,index)=>{
									return (<div className="live-chat-border" key={index}>
									<span className="font-size-13" style={{color: item.title_color}}>{item.title}</span>
									<span className="font-size-13" style={{color: item.msg_color}}> {item.msg} </span>
								</div>)
								})
							}
								<div className="main-over">
								{
									isFocus == 0 ? <div className="video-chat-fllow" >
										<img className="margin-top" width="17" src={followimg} />
										<span className="video-chat-font15">{t("chatfllow")}</span>
										<img className="margin-top" width="7" src={floright} />
									</div> : ""
								}
									
								</div>
							{
								roomChatList.map((item,index)=>{
									return (<div className="main-over" key={index}>
									{
										item.action=='intoroom' ? <div className="action-chat-room"  >
										{
											item.body.from.vip==0 && item.body.from.svip==0 ? <div className="main-flex main-aic">	
											{
												item.body.from.level ? <GradeLevel grade={item.body.from.level}></GradeLevel> : ""
											}
												
												<span className="font-size-13 video-chat-color video-chat-nick" >{ item.body.from.nick  } </span>
												<span className="font-size-13 video-chat-colorFFF"> {t("chatroom")}</span>
											</div> : ""
										}
											
										</div> : ""
									}
									{
										item.action=='roomgift' ? <div className="main-flex main-aic chmsg_b chmsg_bg" >
												<GradeLevel grade={item.body.from.level}></GradeLevel>
												 
												<span className="font-size-13 video-chat-color">{ item.body.from.nick  }:</span>
												<span className="font-size-13 video-chat-cff3e87"> { t("sendLive") }{ item.body.count } { item.body.giftName } </span>
											</div> : ""
									}
									{
										item.action=='chmsg' ? <div  className={['main-flex','main-aic','chmsg_b',item.body.from.vip==1 ? 'chmsg_vip_bg': '',item.body.from.svip==1 ? 'chmsg_svip_bg': '' ,item.body.from.vip==0 && item.body.from.svip==0 ? 'chmsg_bg': ''].join(' ')}>
												<GradeLevel grade={item.body.from.level}></GradeLevel>
												<span className="font-size-13 video-chat-colorFFF video-chat-nick">{ item.body.from.nick  }:</span>
												<span className="font-size-13 video-chat-colorFFF"> { item.body.msg } </span>
											</div> : ""
									}
										
									</div>)
								})
							}
							</div>
						</div>
						<div className="main-w10 chatLive_botton video-bottom-div" >
							<div className="main-flex main-aic">
								<div className="main-flex main-aic live-chat-op" onClick={tapBtns.bind(this,1)}>
									<img className="chat-live-img"  width="33" src={chatImg} />
									<p className="chat-live-font">{t("chattxt")}</p>
								</div>
								
								
								<div className="main-f1"></div> 
								<div  className="main-chat-down" >
									<img className="main-vat" onClick={tapBtns.bind(this,2)} width="33" src={gift2xImg} />
								</div>
							</div>
							
							{
								chatInput ? <div onClick={chatInputClose} className="liveinput-bg">  </div> : ""
							}
							{
								chatInput ? <div className="main-w10 live_input">
									<div className="index_pl index_pr index_bg live-index-bg">
										<div className="main-flex main-chat-bg main-aic chat_enter">
										{
											chatLiveFly == false ? <img onClick={sendLiveFly} src={livec_29} className="chat_fly_img"/> : <img onClick={sendLiveFly} src={livec_28} className="chat_fly_img"/>
										} 
											<input className="main-f1 main-hg chat_input_css" type="text" value={inputContent} onChange={(e) => { setinputContent(e.target.value); }} />
											<img onClick={sendMsg} src={livec_58}  className="chat_send_img head_rt"/>
										</div>
									</div>
								</div> : ""
							}
							{
								giftShow ? <div onClick={giftPopClose} className="liveinput-bg">  </div> : ""
							}
							{
								giftShow ? <div className="main-w10 live_mask">
									<div className="main-flex main-tac gift_top_vip">
										<div onClick={giftTab.bind(this,0)} className="main-flex main-aic ">
										{
											gifttab==0 ? <img src={gift_pop_title01} className="main-vat gift-top-img" /> : <img src={gift_pop_title00} className="main-vat gift-top-img" />
										}
											
											<p className={gifttab==0 ? "gift-pop-top-font gift-pop-a" : "gift-pop-top-font"}>{t("gift")}</p>
										</div> 
										<div className="gift-top-border">|</div>
										<div onClick={giftTab.bind(this,1)} className="main-flex main-aic">
											{
												gifttab==1 ? <img src={gift_pop_title11} className="main-vat gift-top-img" /> : <img src={gift_pop_title10} className="main-vat gift-top-img" />
											}
											<p className={gifttab==1 ? "gift-pop-top-font gift-pop-a" : "gift-pop-top-font"}>VIP</p>
										</div> 
									</div> 
									{
										gifttab==0 ? <div className="swiper-container live_content gift-tab-0">
										<div className="swiper-wrapper">
										{
											giftList0.map((item,index)=>{
												return (<div className="swiper-slide" key={index}>
												<ul className="main-box">
												{
													item.map((items,indexs)=>{
														return (<li className="main-inline main-tac main-relative live_li live_gift_li" key={indexs} onClick={tapSelectGift.bind(this,items.id)}>
														<div className="gift_con">
														{
															selectId != items.id ? <div className="main-over">
															{
																items.vip=='vip' ? <img className="gift_vip" src={gift_giftvip} /> : ""
															}
															{
																items.vip=='svip' ? <img className="gift_vip"  src={gift_giftsvip} /> : ""
															}
																
															</div> : ""
														}
															
															<img width="45" src={items.icon} />
															<div className="main-flex main-aic main-jcc">
																<img className="margin-right-2" width="13" src={gift_m2} />
																<p className="live_price">{ items.price }</p>
															</div>
														</div>
														{
															selectId == items.id ? <div className="main-w10 main-hg main-tar live_select">
														</div> : ""
														}
														
													</li>)
													})
												}
													
												</ul>
											</div>)
											})
										}
											
										</div>
										<div className="swiper-pagination-0"></div>
										</div> : <div className="swiper-container live_content gift-tab-1">
											<div className="swiper-wrapper">
											{
												giftList1.map((item,index)=>{
													return (<div className="swiper-slide" key={index}>
													<ul className="main-box">
													{
														item.map((items,indexs)=>{
															return (<li className="main-inline main-tac main-relative live_li live_gift_li" key={indexs} onClick={tapSelectGift.bind(this,items.id)}>
															<div className="gift_con">
															{
																selectId != items.id ? <div className="main-over">
																{
																	items.vip=='vip' ? <img className="gift_vip" src={gift_giftvip} /> : ""
																}
																{
																	items.vip=='svip' ? <img className="gift_vip"  src={gift_giftsvip} /> : ""
																}
																	
																</div> : ""
															}
																
																<img width="45" src={items.icon} />
																<div className="main-flex main-aic main-jcc">
																	<img className="margin-right-2" width="13" src={gift_m2} />
																	<p className="live_price">{ items.price }</p>
																</div>
															</div>
															{
																selectId == items.id ? <div className="main-w10 main-hg main-tar live_select">
															</div> : ""
															}
															
														</li>)
														})
													}
														
													</ul>
												</div>)
												})
											}
												
											</div>
											<div className="swiper-pagination-1"></div>
										</div>
									} 
									<div className="main-flex main-aic live_btn">
										<div className="main-flex01 main-flex">
											<div onClick={payvip} className="main-flex main-aic live_recharge">
												
												<img width="15" src={gift_m1} />
												<p className="live_num">{ userM }</p>
												<span  className="live_t1">{t("chatcon")}</span>
												<img width="6" src={gift_right}/>
											</div>
										</div> 
										<div className="main-f1"></div>
										<img className="main-flex main-aic chatGiftSend" height="25" onClick={sendGift} src={livec_58}/>
									</div>
								</div> : ""
							}
							
						</div>
						{
							svipRoom ? <div className="svipRoom">
							<div className="svipcontent">
								<span className="svip-room-margin"></span>
								<span className="svip-room-nick" >{ doIrcarr.nick }</span>
								<span className="font-size-13">{t("chatroom")}</span>
							</div>
							<div className="svipimg">
								<img src={svipImgRoom}  className="chatLive_svip_img" />
							</div>
						</div> : ""
						}
						
						<div  className="room">
							<div className="main-flex main-aic main-hg main-relative room_content">
							  <p className={doIrcarr.uid == userid ? "room_name room_names" : "room_name"}>{ doIrcarr.nick }</p>
							  <span className="room_in">{ t("chatroom") }</span>
							</div>
						</div>
						
					</div>
				</div> : <div className="main-w10 main-hg main-relative main-over"></div>
			}
		</div>
	);
	
};

export default LiveRoom; 