import Router from './router/index'
import "./i18n/index";
import './App.css';

function App() {
  return (
    <div>
      <Router></Router>
    </div>
  );
}
export default App; 
