//在index.js中引入axios
import axios from "axios";

let env = process.env.NODE_ENV;
let im_api,
	m_api,
	pay_api,
	u_api = "";

if (env == "development") {
  im_api = "https://im.api.anycupid.com"; //测试域名
  m_api = "https://m.api.anycupid.com";
  pay_api = "https://pay.api.anycupid.com";
  u_api = "https://u.api.anycupid.com";

  // basicUrl = "https://reso.api.bosskol.com/"; //正式域名
} else {
  im_api = "https://im.api.anycupid.com"; //正式域名
  m_api = "https://m.api.anycupid.com";
  pay_api = "https://pay.api.anycupid.com";
  u_api = "https://u.api.anycupid.com";
}

// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列
let requests = [];
//设置axios基础路径
const service = axios.create({
  baseURL: m_api,  
  withCredentials: true,
}); 
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    console.log(config);
    if (config.urlType) {
      //1-图片接口 2-支付接口 3-
      if (config.urlType == 1) config.baseURL = im_api;
      if (config.urlType == 2) config.baseURL = pay_api;
	  if (config.urlType == 3) config.baseURL = u_api;
    }
    // 每次发送请求之前本地存储中是否存在token，也可以通过Redux这里只演示通过本地拿到token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = localStorage.getItem("token") || "";

    //设置请求头
    config.headers = {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      //from: 4,
    };
    //序列化请求参数，不然post请求参数后台接收不正常
    // config.data = QS.stringify(config.data)
    return config;
  },
  (error) => {
    return error;
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.request.status === 401) {
      // 记录实例
      const config = error.config;
      // 判断是否正在更新 token
      if (!isRefreshing) {
        // 改变标记状态
        isRefreshing = true;
        // 请求 token 的函数前一定要 return 否则重试成功的数据，
        // 不会返回到请求数据函数的 response 中
       
      } else {
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push(() => {
            // // 给url 增加时间戳 避免请求缓存影响
            if (config.url.indexOf("?") > -1) {
              config.url = config.url + "&n=" + new Date().getTime();
            } else {
              config.url = config.url + "?n=" + new Date().getTime();
            }
            config.headers["Authorization"] = localStorage.getItem("token");
            resolve(service(config));
          });
        });
      }
    } else {
      return Promise.reject(error);
    }
  }
);

//最后把封装好的axios导出
export default service;
