import React, { useEffect, useState }  from "react";
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import {newlivelist} from "../../request/api.js";
import { Scroll } from "../scroll/scroll.tsx"; 
import f_04_png from "../../img/live/f_04.png";
import defaultsimg from "../../img/guard/defaults.png";
import zhubo from "../../img/giftd/zhubo.png";
let dataArr = [];
let listShow = true;
const FindNewList: React.FunctionComponent = (props) => { 
	const { t } = useTranslation();
	const navigate = useNavigate(); 
	const [list,setlist] = useState([]);
	let page = 1;
	const [wHeight,setwHeight] = useState(0);
	useEffect(() => {
		let width = document.documentElement.clientWidth;
		let w = (width - 20 - (((width - 20) * 0.014) * 2)) / 3
		setwHeight(w);
		dataArr = [];
		listAjax();
	}, []);
	const handleScroll  = () => {
		console.log("handleScroll")
	  if (dataArr.length != 0) {
		  if(listShow){
			  page = page + 1;
			  listAjax();
		  }
	    
	  }
	};
	const listAjax=()=>{
		let darr = { 
			pageno: page,
			pagenum: 30,
		}
		newlivelist(darr).then((res)=>{
			if(res.errno == 0) {
				if(res.data.items.length > 0) {
					let lists = res.data.items;
					listShow = true;
					if(lists.length<10){
						listShow = false;
					}
					dataArr = dataArr.concat(lists); 
					setlist(dataArr);
				}else{
					listShow = false;
				} 
							
			} 
		})
		
	}
	const liveOpen=(uid)=>{
		
	}
	return (
		<div className="find_pt">
			<div className="main-box">
				<img className="main-va" width="18" src={f_04_png} />
				<span className="main-inline main-va find_t2">{ t("zuixin") }</span>
			</div>
			{
				list.length>0 ? <ul className="main-font find_list">
				<Scroll
				  height="100%"
				  load={handleScroll}
				  isFirstLoad={false}
				>
				{
					list.map((item,index)=>{
						return (<li className="main-inline main-vat find_li" key={index}>
					<a className="main-block main-over main-relative find_a" style={{ height: wHeight + "px" }} onClick={liveOpen.bind(this,item.uid)}>
						<img className="main-w10 main-va" src={item.photo} onError={(e: any) => { e.target.src = defaultsimg }}  />
						<p className="main-w10 main-hg find_mask"></p>
						<div className="main-w10 main-flex main-aic find_down">
							<p className="main-f1 main-ellipsis find_name">{ item.nick }</p>
							<p className="find_num">{ item.personnum }</p>
						</div>
					</a>
				</li>)
					})
				}
				
				</Scroll>
			</ul> : <div className="main-over main-font main-box-img">
				<img src={zhubo} />
				<p>{ t("noshowLive") }</p>
			</div>
			}
			
			
		</div>
	);
};

export default FindNewList; 